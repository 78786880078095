import { useLayoutEffect } from 'react';
import GitInfo from 'react-git-info/macro';
import Setting from "./Setting";

export default function GitInformation() {

    const info = GitInfo();

    useLayoutEffect(() => {
        if (Setting.gitinfos) {
            window.linkstar = info.commit
        }
    }, [])

}
