import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './footer.css'
import { connect, useSelector } from "react-redux";
import {
    fetchFooterSection,
    getUserSocialAccounts
} from "../../../Redux/Actions";
import { Link } from "react-router-dom";
import { logoPurple } from ".././../../assets/images/index"
import { mapStateToProps } from "../../../Constants/utils";
import mixpanel from 'mixpanel-browser';
import {
    faInstagram,
    faFacebook,
    faTwitch,
    faTwitter,
    faYoutube,
    faBehance,
    faVimeo,
    faPinterest,
    faTelegram,
    faReddit,
    faSnapchat,
    faSpotify,
    faSoundcloud,
    faWeixin,
    faTiktok,
    faDribbble,
    faWhatsapp,
    faSkype,
    faFacebookMessenger,
    faFlickr,
    faTumblr,
    faLinkedin,
    faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { notifyError } from '../../../Constants/Functions';
const fetch_icon = (slug) => {
    switch (slug) {
        case "instagram":
            return faInstagram;
        case "twitch":
            return faTwitch;
        case "twitter":
            return faTwitter;
        case "facebook":
            return faFacebook;
        case "youtube":
            return faYoutube;
        case "behance":
            return faBehance;
        case "vimeo":
            return faVimeo;
        case "pinterest":
            return faPinterest;
        case "telegram":
            return faTelegram;
        case "reddit":
            return faReddit;
        case "snapchat":
            return faSnapchat;
        case "spotify":
            return faSpotify;
        case "soundcloud":
            return faSoundcloud;
        case "wechat":
            return faWeixin;
        case "tiktok":
            return faTiktok;
        case "dribbble":
            return faDribbble;
        case "whatsapp":
            return faWhatsapp;
        case "tinder":
            return faWhatsapp;
        case "skype":
            return faSkype;
        case "messenger":
            return faFacebookMessenger;
        case "flicker":
            return faFlickr;
        case "tumblr":
            return faTumblr;
        case "linkedin":
            return faLinkedin;
        case "github":
            return faGithub;
        default:
            return faFacebook;
    }
};
function Footer(props) {

    // const isFree = useSelector((state) => state.root.user_theme?.tier) == 0;
    const isPro = useSelector((state) => state.root.configurations?.tier) == 1;
    const user_menu = useSelector(state => state?.root?.user_menu)
    const footer = useSelector((state) => state.root.user_theme?.footer_icon);
    const footerIcon = isPro && user_menu.length!==0 ? footer : logoPurple;
    const footerLink = useSelector((state) => state.root.user_theme?.footer_link);
    const footerIconLink = isPro ? footerLink : process.env.REACT_APP_STATIC_SITE_ROOT

    const [title, setTitle] = useState("About me");
    const [detail, setDetail] = useState("To get in Touch with me Submit the form below");
    const [phone, setPhone] = useState("+61 3123216547");
    const [email, setEmail] = useState("contact@your email.com");
    const [address, setAddress] = useState("121 king street MelbourneVictoria 3000 Austria");
    const [mySocialAccounts, setMySocialAccounts] = useState([]);
    const social_section_updated = useSelector(
        (state) => state.root.social_section_updated
    );
    const isMobile = useSelector((state) => state.root.isMobile);
    const subDomain = useSelector(state => state.root.subDomain);
    const userTheme = useSelector(state => state.root.user_theme);


    // useEffect(() => {
    //     props?.fetchFooterSection().then(res => {
    //         if (res?.success) {
    //             if (res?.data?.footer_section) {
    //                 let footer_section = res?.data?.footer_section;
    //                 setTitle(footer_section.title ?? "To get in Touch with me Submit the form below");
    //                 setDetail(footer_section.detail ?? "To get in Touch with me Submit the form below");
    //                 setPhone(footer_section.phone ?? "+61 3123216547");
    //                 setEmail(footer_section.email ?? "contact@your email.com");
    //                 setAddress(footer_section.address ?? "121 king street MelbourneVictoria 3000 Austria");
    //             }
    //         }
    //         else notifyError(res?.message)
    //     }).catch((err) => {
    //         return notifyError(err.message)
    //     })
    // }, [])
    // useEffect(() => {
    //     props
    //         ?.getUserSocialAccounts()
    //         .then((res) => {
    //             if (res?.success)
    //                 setMySocialAccounts(res?.data?.social_links);
    //             else notifyError(res?.message)
    //         })
    //         .catch((err) => {
    //             console.log("error", err);
    //             notifyError(err.message)
    //         });
    // }, [social_section_updated]);

    const has_section = (section) => {
        if (userTheme && userTheme[section] && userTheme[section].visible) {
            return true;
        }
        return false;
    }
    return (

        <Container fluid className="theme-footer-section">
            {/* {userTheme?.show_footer_icon && */}
                <Container>
                    <div onClick={ (e)=> {mixpanel.track("Object Click - Profile Footer - Image Logo"); if (footerIconLink) {window.open(footerIconLink, '_blank')}} } className="d-flex w-100 h-100 justify-content-center align-items-center">
                        {!subDomain ? (
                            <img src={footerIcon} className="img-fluid footer-logo" 
                            onError={({target})=>{ target.src = logoPurple}} 
                            />
                        ) :
                        <img src={footerIcon} className="img-fluid footer-logo" 
                            onError={({target})=>{ target.src = logoPurple}} 
                            />
                        // <a href={!subDomain ? "" : "https://linkst.ar"}></a>
                        }
                    </div>
                </Container>
            {/* } */}

        </Container>
    )
}

export default connect(mapStateToProps, {
    fetchFooterSection,
    getUserSocialAccounts
})(Footer);