import React,{ useState} from 'react';
import { Modal, Button } from "react-bootstrap";
import styles from "./ActionCardModal.module.css";
import { alertInfoIcon } from '../../../assets/images/icons';
import { connect, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

export default function ActionCardModal(props) {
    const {heading, description, cancelText, confirmText} = props;
    const [show, setShow] = useState(true);

    const isMobile = useSelector((state) => state.root.isMobile)
    return (
        <>
            <Modal className={styles.switchCardConfirmModal} contentClassName={styles.modalContent} dialogClassName={styles.modalDialog} show={show} onHide={props?.onCancel} centered>
                <Modal.Header closeButton className={styles.modalHeader} closeVariant="primary" >
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modalBody}>
                            <img className={`img-fluid ${styles.icon}`} src={alertInfoIcon} />
                    <h2 className={styles.modalTitle}>{heading}</h2>
                    <p className={styles.modalText}>{description}</p>
                    <div className={styles.btnBox}>
                        <Button variant="outline-primary" className={`rounded-pill ${styles.btnAction}`} onClick={()=>{props?.onCancel();;mixpanel.track(`Button Pressed - Remove All Custom Section Modal - Keep`);}}>
                        {cancelText}
                        </Button>

                        <Button variant="primary" className={`rounded-pill ${styles.btnAction}`} onClick={()=>{props?.onConfirm();;mixpanel.track(`Button Pressed - Remove All Custom Section Modal - Switch`);}}>
                        {confirmText}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
