import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Custom from "./../../assets/images/section_variations/Mobile-Custom.jpg";
import SectionAdded from "./../../assets/images/section_added_icon.gif";
import { Row, Col, Button, Modal } from "react-bootstrap";
import {
  themeSectionReorder,
  toggleSection,
  fetchUserTheme,
  setSectionVariation,
  getSectionVariations,
  setMultiVariation,
} from "../../Redux/Actions";

import { mapStateToProps } from "../../Constants/utils";
import {
  crossTimesIcon,
  } from "../../assets/images/icons/index";
import BottomTabSectionCards from "./BottomTabSectionCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../Modal";
import { notifyError } from "../../Constants/Functions";
import SwitchCardConfirmModal from '../Modal/SwitchingCardModal/SwitchingCardModal';
import ActionCardModal from '../Modal/ActionModal/ActionCardModal';
import mixpanel from 'mixpanel-browser';
import AddNewLabel from "../Sidebar/SideSections/AddNewLabel";
import LoadingComponent from "../Loader/LoadingComponent/LoadingComponent";
import AddLock from "../Sidebar/SideSections/AddLock";

function AddSection(props) {

  //Local States
  const [tabSelect, setTabSelect] = useState("");
  const [modalOpen,setModalOpen] = useState(false);
  const [localSections, setLocalSections] = useState(null);
  //Global States
  const section_variations = useSelector((state) => state.root.section_variations);
  const [local_section_variations, setLocalSectionVariations] = useState(section_variations);
  const [selected_variations, setSelectedVariations] = useState(section_variations);
  const [sectionAddOrRemoved, setSectionAddOrRemoved] = useState(null);
  const [showModal,setShowModal] = useState(false);
  const [showCustomDelModal,setShowCustomDelModal] = useState(false);
  const [selectedVar, setSelectedVar] = useState({
      slug:null,
      id:null
    });
  const [allowSelect, setAllowSelect] = useState(true);
  const [loading, setLoading] = useState(false);



  //Initialize Redux
  const dispatch = useDispatch();

  //Modal Buttons
  const addMoreSectionBtn = () => {
    setModalOpen(false);
    setSectionAddOrRemoved(null);
    setTabSelect("");
  }

  const startEditingBtn = () => {
    setModalOpen(false);
    setTabSelect("");
    setSectionAddOrRemoved(null);
    dispatch({ type: "BOTTOM_TAB_CLOSE" });
    props?.setSelected("");
  }

  const closeBtn = () =>{
    setModalOpen(false);
  }


  //Add Or Remove Section
  const SwitchOrSet = (section_name,id)=>{

    if(!allowSelect) return;

    let selectedSectionObj = section_variations.find((secVar)=>secVar.slug==section_name);
    setSelectedVar({...selectedVar,
      slug:section_name,
      id
    });
    if(selectedSectionObj &&
        selectedSectionObj.visible &&
        selectedSectionObj.variation_id!=id){
        // trigger modal
        setShowModal(true);
        return;
    }
    setVariation(section_name, id);

    dispatch({
      type: "SET_PROFILE_CHECK_LIST",
      payload: true,
      key: "sectionAdded"
    })

    dispatch({
      type: "SET_PROFILE_CHECK_LIST",
      payload: section_name,
      key: "selectedSection"
    })

  }
  const RemoveOrAdd = (section_name,id)=>{
    if(!allowSelect) return;

    let selectedSectionObj = section_variations.find((secVar)=>secVar.slug==section_name);
    let isSelected = selectedSectionObj?.theme_variations?.find(el=>el.selected==true && el.id==id);
    if(isSelected){
        // trigger modal
        setSelectedVar({
          slug:section_name,
          id
        });
        setShowCustomDelModal(true);
        return;
    }

    setVariation(section_name, id);
  }
  const onConfirmModal = (section_name,id)=>{
    setShowModal(false);
    setShowCustomDelModal(false);
    setVariation(selectedVar?.slug, selectedVar?.id);
  }
  const onCancelModal = ()=>{
    setShowModal(false);
    setShowCustomDelModal(false);
  }
  const setVariation = (section_name, id) => {
    setLoading(true);
    //Select Unselect Locally (Set section variation on Frontend)
    // let temp = { ...selected_variations };
    // if (temp[section_name] == id) {
    //   temp[section_name] = 0;
    // } else {
    //   temp[section_name] = id;
    // }
    // setSelectedVariations({ ...temp });
    let temp = [...local_section_variations];

    let new_arr = [];
    temp.forEach((s) => {
      let visible = s.visible;
      let theme_variations = s.theme_variations;
      if (s.slug == section_name) {
        let oldSelected = s.theme_variations.find(el=>el.selected==true && el.id==id);
        if(section_name=='custom'){
          theme_variations= s.theme_variations.map(el=>{
                if(el.id==id){
                  el.selected = el.id != oldSelected?.id;
                }
                return el;
          });
        }else{
          theme_variations= s.theme_variations.map(el=>{
                el.selected = (el.id==id && el.id != oldSelected?.id);
                return el;
          });
        }
        let isSelected = s.theme_variations.find(el=>el.selected==true)!=null;
        visible = isSelected;
      }
      new_arr.push({ ...s, visible, theme_variations });
    });


    setLocalSectionVariations(new_arr);

    //Set section variation in database
    setAllowSelect(false);
    let apiRoute = section_name.indexOf("custom")!=-1 ? props?.setMultiVariation : props?.setSectionVariation;
    apiRoute({ id, section_name }).then((res) => {
      setAllowSelect(true);
      if (res.success) {
        setLoading(false);
        let userTheme = res?.data?.user_theme;
        let availableSections = res?.data?.available_sections;
        let sectionVisible = res?.data?.section?.visible;
        let loader = document.getElementById('cardModal');

        if (sectionVisible) {
          setSectionAddOrRemoved('Added');
        }
        else {
          setSectionAddOrRemoved('Removed');
        }

        if (loader) {
          loader.style.display = "block";
          setTimeout(() => {
            loader.style.WebkitTransition = 'visibility .5s, opacity .5s';
            loader.style.display = 'none';
          }, 5000);
        }

        if (userTheme && availableSections) {
          dispatch({ type: "SET_USER_THEME", payload: userTheme });
          dispatch({ type: `${section_name.toUpperCase()}_SECTION_UPDATE` });
          // dispatch({ type: "SET_SECTION_VARIATIONS", payload: availableSections });
          dispatch({ type: "SET_USER_MENU", payload: availableSections });
        }
      }else{
      notifyError(res.message);
      setLoading(false);
      }
    }).catch((err) => {
      return notifyError(err.message)
    });
  };

  //Section Variation Selected Variation Marks.
  useEffect(() => {
    if (section_variations && section_variations.length) {
      let temp = { ...selected_variations };
      section_variations.forEach((s) => {
        if (!s.visible) {
          temp[s.slug] = 0;
        } else {
          temp[s.slug] = s.variation_id;
        }
      });
      setSelectedVariations({ ...temp });
    }
  }, [section_variations]);


  const backSubMenuHandler = () => {
    console.log("backSubMenuHandler...")
    dispatch({
      type: "SET_PROFILE_CHECK_LIST",
      payload: false,
      key: "selectSectionClicked"
    })
  }

  //Local Section Cards Sorting.
  useEffect(()=>{
    let temp = section_variations.sort(function (a, b) {
      return a.sequence - b.sequence;
    });

    setLocalSections(temp);

  },[]);


  //Modal Open after Section Add or Removed.
  // useEffect(()=>{
  //   if(sectionAddOrRemoved){
  //     setModalOpen(true);
  //   }
  // },[sectionAddOrRemoved])


  return (
    <>
      <div
        className='bottom-tab-content-box'
        style={{
          width: "100%",
          backgroundColor: "white",
          position: "fixed",
          bottom: "60px",
          zIndex: 599,
        }}
      >

       {/* Section Add Modal */}
       <div id="cardModal" style={{marginBottom:'20px'}} className="modal_card">
            <div className="updateCardIcon">
              <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.09692 4.74504L3.43585 7.08422L9.50298 1.01709" stroke="white" stroke-linecap="round" />
              </svg>
            </div>
            <p>Your Section has been {sectionAddOrRemoved} </p>
          </div>
      {/* <CustomModal style={{ position: 'fixed', zIndex: 9999}} show={modalOpen} onHide={()=>{setModalOpen(false)}}>
      <Modal.Body>
        <div className="text-center">
            <img src={SectionAdded} height={100} />
            <h3 className="font-weight-bold">Your Section has been {sectionAddOrRemoved && sectionAddOrRemoved == 'Added' ? 'added on' : 'removed from'} your linkstar.</h3>
            <div className="my-4 d-flex flex-row justify-content-between">
                   {
                     sectionAddOrRemoved && sectionAddOrRemoved == 'Added' ? (
                    <>
                      <Button
                      variant='primary'
                      className='rounded-pill primary-color py-1 m-1 w-100'
                      style={{ fontSize: 14 }}
                      onClick={startEditingBtn}
                    >
                    Start Editing
                    </Button>

                    <Button
                      className='rounded-pill bg-white btn-outline-primary m-1 py-1 w-100'
                      style={{ fontSize: 13 }}
                      onClick={addMoreSectionBtn}
                    >
                        Add More sections
                    </Button>
                    </>
                     ) : (
                      <Button
                      className='rounded-pill bg-white btn-outline-primary m-1 banner-btn py-1 w-100'
                      style={{ fontSize: 14 }}
                      onClick={closeBtn}
                    >
                        Close
                    </Button>
                     )
                   }
            </div>
        </div>
      </Modal.Body>
    </CustomModal> */}


        {/* Header */}
        <div className='bottom-tab-header'>
          <h3 className='font-weight-bold bottom-tab-title'>Add To Site{tabSelect !== '' && ' - '+tabSelect}</h3>
          {loading ? (
           <div className="mobileTabAddtoSite"> <LoadingComponent /></div>
          ) : (
            <div className='icon-section' style={{ cursor: "pointer" }}>

            <span
              className='icon-box'
              onClick={() => {
                dispatch({ type: "BOTTOM_TAB_CLOSE" });
                props?.setSelected("");
              }}
            >
              <img src={crossTimesIcon} className='img-fluid icon' />
            </span>
          </div>
          )}
        </div>

          {/* Caption Row */}
          <div className='caption-box'>
            <Row>
              <Col className='col-7 v_align'>
                {
                  tabSelect == '' ? (
                    <h6 className='caption-box-header'>
                      <span className='caption-box-text'></span>{" "}
                      Add sections to your Linkstar
                    </h6>
                  ) : (
                    <Button
                      variant='primary'
                      className='rounded-pill primary-color banner-btn py-1 back_btnRes'
                      style={{ fontSize: 14 }}
                      onClick={() =>{ setTabSelect(""); backSubMenuHandler(); }}
                    >

                    <FontAwesomeIcon icon={faArrowLeft} className='card-back-arrow' />
                      {"  "}Back
                    </Button>
                  )
                }
              </Col>
              <Col className='text-end col-5'>
                <Button
                  variant='primary'
                  className='rounded-pill primary-color banner-btn py-1 mobile_managedSection'
                  style={{ fontSize: 14 }}
                  onClick={() => {props?.setSelected("manage");}}
                >
                  Manage Sections
                </Button>
              </Col>
            </Row>
          </div>

          {/* Main Body */}
        <div className='bottom-tab-body'>
          <div className='section-toggle-container mobile_addSection scroller' id="sectionSelectionWrapper">

              {/* if no section is selected then all section will be displayed otherwise selected section variations will be displayed */}
            {
              local_section_variations &&
              local_section_variations.map((el, i) => {
                return (
                  tabSelect == '' ? (
                     el.theme_variations?.length ?
                      (<BottomTabSectionCards
                        title={el?.title}
                        detail={el?.description}
                        onClickCard={() => {setTabSelect(el?.title)}} />):null
                  ) :
                  tabSelect == el?.title &&
                  <div className='section-toggle-wrapper'>
                      {/* <h6 className='caption-box-header mb-1'>
                      <span className='caption-box-text font-weight-bold'>Select</span>{" "}
                      section to <span className='caption-box-text font-weight-bold'>add</span>.

                    </h6>
                    <h6 className='caption-box-header mb-4'>
                        <span className='caption-box-text font-weight-bold'>Unselect</span>{" "}
                      section to <span className='caption-box-text font-weight-bold'>remove</span>.
                    </h6> */}
                    <div className={"section-toggle-box"}>
                      <h5 className='section-title'>{el.title}</h5>
                      <Row className='variation_selection'>
                        {el.theme_variations?.length &&
                          el.theme_variations.map((s) => {
                            return (
                              <Col
                                xs={6}
                                onClick={() => {
                                  // setVariation(el.slug, s.id);
                                  if(s.locked!=true || (el.visible==1 && s.selected))
                                  el.slug.indexOf("custom")!=-1 ? RemoveOrAdd(el.slug, s.id) : SwitchOrSet(el.slug, s.id);
                                }}
                              >
                                <div
                                  className={`single_variation ${
                                    el.visible &&
                                    s.selected
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  {s?.locked==true?<AddLock />:''}
                                  {s?.new_status?<AddNewLabel />:''}
                                  <img
                                    className='img-fluid py-3 '
                                    src={s?.full_mobile_preview ?? Custom}
                                  />
                                  {el.visible &&
                                    s.selected && (
                                      <>
                                      <div className="updateCardIcon">
                                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1.09692 4.74504L3.43585 7.08422L9.50298 1.01709" stroke="white" stroke-linecap="round"/>
                                        </svg>
                                      </div>
                                      </>
                                    )}
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
      {showModal && <SwitchCardConfirmModal onConfirm={()=>{onConfirmModal();}} onCancel={()=>{onCancelModal();}} />}
      {showCustomDelModal && <ActionCardModal onConfirm={onConfirmModal} onCancel={onCancelModal}
        heading={"You are deleting all custom sections that use this type of card"}
        description={"You won't be able to undo the changes. Sections can be deleted separately by hovering over section." }
        cancelText={"Cancel"}
        confirmText={"Confirm Delete"}
      />}
    </>
  );
}

export default connect(mapStateToProps, {
  themeSectionReorder,
  toggleSection,
  setSectionVariation,
  fetchUserTheme,
  getSectionVariations,
  setMultiVariation,
})(AddSection);
