import React, { useEffect, useState } from "react";
import { connect, useSelector,useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { mapStateToProps } from "../../Constants/utils";
import { setColorTheme, getColorThemes } from "../../Redux/Actions";
import { crossTimesIcon } from "../../assets/images/icons/index";
import { notifyError, notifySuccess } from "../../Constants/Functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons"

function ThemeSection(props) {
  const [colorThemes, setColorThemes] = useState([]);
  const [selectedColorTheme, setSelectedColorTheme] = useState(null);
  const dispatch = useDispatch();
  const theme_id = useSelector(
    (state) => state?.root?.user_theme?.theme_color?.id
  );
  const isMobile = useSelector((state) => state.root.isMobile);

  // const notify = (msg) =>
  //   toast(msg, {
  //     position: isMobile ? "bottom-right" : "top-right",
  //     hideProgressBar: true,
  //     autoClose:3000,
  //     toastId:2,
  //     // delay: 1000
  //   });


  const applyColorTheme = (id) => {
    setSelectedColorTheme(id);
    props?.setColorTheme({ color_id: id }).then((res) => {
      if (res?.success) {
        notifySuccess("This theme has been Applied");
        let user_theme = res?.data?.user_theme;
        if (user_theme) {
          let color = user_theme?.theme_color?.style_name;
          document.documentElement.className = color;
          dispatch({ type: 'SET_USER_THEME', payload: user_theme });
        }
      }else notifyError(res?.message)
    }).catch((err) => {
      return notifyError(err.message)
    });
  };

  useEffect(() => {
    props?.getColorThemes().then((res) => {
      if (res.success) {
        setColorThemes(res.data.color_themes);
      }else notifyError(res?.message)
    }).catch((err) => {
      return notifyError(err.message)
    });
  }, []);

  useEffect(() => {
    setSelectedColorTheme(theme_id);
  }, [theme_id]);

  return (
    <>
      <div
        className='bottom-tab-content-box'
        style={{

          width: "100%",
          backgroundColor: "white",
          position: "fixed",
          bottom: "60px",
          zIndex: 999,
        }}
      >
        <div className='bottom-tab-header'>
          <h3 className='font-weight-bold bottom-tab-title'>Color Options</h3>
          <div
            className='icon-section'
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch({type: 'BOTTOM_TAB_CLOSE'});
              props?.setSelected("")
            }}
          >
            {/* temporary disabled until page is developed by designer */}
            {/* <span className='icon-box'>
              <img src={infoIcon} className='img-fluid icon' />
            </span> */}
            <span className='icon-box'>
              <img src={crossTimesIcon} className='img-fluid icon' />
            </span>
          </div>
        </div>
        <div
          className='bottom-tab-body'
          style={{ float: "left", overflowY: "scroll" }}
        >
          <div className='bottom-tab-sub-title-box'>
            <h5 className='font-14 font-weight-bold bottom-tab-sub-title'>
              Select color options
            </h5>
          </div>
          <div className='theme_clr'>

          {colorThemes.length
            ? colorThemes.map((ct) => {
                return (
                  // <div
                  //   style={{ marginBottom: 10, marginTop: 20 }}
                  //   onClick={() => {
                  //     applyColorTheme(ct.id);
                  //   }}
                  // >
                  //   <img
                  //     className='img-fluid'
                  //     src={ct.preview_url}
                  //     style={{
                  //       width: "100%",
                  //       outline:
                  //         selectedColorTheme === ct?.id && "3px solid #8242C6",
                  //     }}
                  //   />
                  //   {selectedColorTheme == ct.id && (
                  //     <h6
                  //       style={{
                  //         fontWeight: "bold",
                  //         color: "#8242C6",
                  //         marginTop: 10,
                  //       }}
                  //     >
                  //       Selected
                  //     </h6>
                  //   )}
                  // </div>
                  <div onClick={() => { applyColorTheme(ct.id) }}
                    className={'color_t '+ct.style_name}
                    style={{cursor:'pointer'}}>
                    {selectedColorTheme === ct?.id ? <FontAwesomeIcon icon={faCheck} /> :'Aa'}
                  </div>
                );
              })
            : null}
          </div>
        </div>
        {/* <ToastContainer limit={1} /> */}
      </div>
    </>
  );
}

export default connect(mapStateToProps, { getColorThemes, setColorTheme })(
  ThemeSection
);
