import React, {useState} from "react";
import { Link } from "react-router-dom";
import {Alert} from "react-bootstrap";

import { logoSvg } from "../../assets/images";
import styles from "./alert.module.css";
import { useSelector } from "react-redux";

export default function JoinAlert(){
    
    const isPro = useSelector((state) => state.root.configurations?.tier) == 1;

    const [show, setShow] = useState(true);
    return(
        <>{!isPro && show &&
            <Alert className={`${styles.join_alert}`} variant="light" onClose={() => setShow(false)} dismissible>
                <a href={`${process.env.REACT_APP_STATIC_SITE_ROOT}`}>
                    <span className={`img-box`}>
                        <img className={`img-fluid ${styles.logoImg}`} src={logoSvg} />
                    </span>
                </a>
                <span className={`${styles.text}`}>Want to make your own Linkstar?</span>
                <span>
                    <a href={`${process.env.REACT_APP_APP_SITE_ROOT}/auth/register`} className={`${styles.btnFreeNow}`}>Create For Free Now</a>
                </span>
            </Alert>
        }
        </>
    )
}