export const colors = {
    primary: '#5B3F91',
    secondary: '#8242C6',
    white: '#fff',
    dark: '#000',
    lightGray: '#ececec',
    gray: '#acacac',
    yellow:'#F8AA54'
}

export const fonts ={
    primaryFont: "MarkPro",
    primaryFontMedium: "MarkPro-Medium",
    primaryFontBold: "MarkPro-Bold",
    primaryFontLight: "MarkPro-Light",
    primaryFontExtraBold: "MarkPro-Heavy",
}