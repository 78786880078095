import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect, useSelector } from "react-redux";

import { mapStateToProps } from "../../../../Constants/utils";

import BannerContext from "../Block/BannerContext";
import CardEditBlock from "../../../ThemeCard/Block/CardEditBlock";
import styles from "./BannerTwo.module.css"
import LikeButton from "Components/LikeButton/LikeButton";

function BannerTwo(props) {
  const isMobile = useSelector((state) => state.root.isMobile);
  const invertedBanner = useSelector((state) => state.root.invertedBanner);

  return (
    <>
      <Row
        className={
          invertedBanner
            ? isMobile
              ? "flex-column-reverse"
              : " flex-row-reverse"
            : ""
        }
      >
        <Col md={12} lg={12} className="align-self-end px-0">
          <BannerContext classUserDetail={styles.userDetail} />
          <LikeButton />
        </Col>
      </Row>
    </>
  );
}
export default React.memo(connect(mapStateToProps)(BannerTwo));