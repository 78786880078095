import React from "react";

function CharacterUiLimit(props) {
  return (
    <div className="character-limit">
      <p className="limit-text text-start">
        Character Limit: {props.count}/{props.range}
      </p>
    </div>
  );
}

export default CharacterUiLimit;
