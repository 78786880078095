import React from 'react';
import styles from './AddLock.module.css'
import { cardProLock } from "../../../assets/images/icons/index";
import { cardProLockMobile } from "../../../assets/images/icons/index";
import { useSelector } from 'react-redux';

function AddLock(props) {
  const isMobile = useSelector((state) => state.root.isMobile);
  return (
    <>
    {
      isMobile?
      <span className={`${styles.lock}`}><img src={cardProLock} /></span>
      :
      <span className={styles.lock}><img src={cardProLock} /></span>
    }
    </>
  );
}


export default AddLock;