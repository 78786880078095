import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { redirectTo } from '../../../../Constants/Functions';
import { faInstagram, faFacebook, faTwitch, faTwitter, faYoutube, faBehance, faVimeo, faPinterest, faTelegram, faReddit, faSnapchat, faSpotify, faSoundcloud, faWeixin, faTiktok, faDribbble, faWhatsapp, faSkype, faFacebookMessenger, faFlickr, faTumblr, faLinkedin, faGithub, } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
    notifyError,
    sanitize_url
} from "../../../../Constants/Functions";
import {
    getUserSocialAccounts,
} from "../../../../Redux/Actions";
import { mapStateToProps } from "../../../../Constants/utils";
import styles from './BannerSocial.module.css'
import SocialElementDemo from "../Element/SocialElementDemo";

import { icon } from "@fortawesome/fontawesome-svg-core";
import { calendly, calendlyBlack } from 'assets/images/icons/social icons/index.js';



function BannerSocial(props) {
    const isMobile = useSelector((state) => state.root.isMobile);
    const subDomain = useSelector((state) => state.root.subDomain);
    const auth = useSelector((state) => state.auth.user);
    // const isPro = useSelector((state) => state.root.configurations?.tier) == 1;
    // const isStudent = useSelector((state) => state.root.configurations?.tier) == 2;
    const isInfluencer = useSelector((state) => state.root.user_theme?.theme?.slug) == 'influencer';
    const social_section_updated = useSelector(
        (state) => state.root.social_section_updated
    );

    const [mySocialAccounts, setMySocialAccounts] = useState([]);
    const styleList = isInfluencer ? { background: 'white', borderRadius: '50%' } : {};
    const styleBadgeDiv = isInfluencer ? { marginLeft: isMobile ? '44%' : '49%', margin: '15px auto' } : {};

    const imgIcons = {
        calendly: calendly,
        calendlyBlack: calendlyBlack
    }

    const fetch_icon = (slug) => {
        switch (slug) {
            case "instagram":
                return faInstagram;
            case "twitch":
                return faTwitch;
            case "twitter":
                return faTwitter;
            case "facebook":
                return faFacebook;
            case "youtube":
                return faYoutube;
            case "behance":
                return faBehance;
            case "vimeo":
                return faVimeo;
            case "pinterest":
                return faPinterest;
            case "telegram":
                return faTelegram;
            case "reddit":
                return faReddit;
            case "snapchat":
                return faSnapchat;
            case "spotify":
                return faSpotify;
            case "soundcloud":
                return faSoundcloud;
            case "wechat":
                return faWeixin;
            case "tiktok":
                return faTiktok;
            case "dribbble":
                return faDribbble;
            case "whatsapp":
                return faWhatsapp;
            case "tinder":
                return faWhatsapp;
            case "skype":
                return faSkype;
            case "messenger":
                return faFacebookMessenger;
            case "flicker":
                return faFlickr;
            case "tumblr":
                return faTumblr;
            case "linkedin":
                return faLinkedin;
            case "github":
                return faGithub;
            default:
                return faGlobe;
        }
    };

    useEffect(() => {
        props
            ?.getUserSocialAccounts()
            .then((res) => {
                if (res?.success)
                    setMySocialAccounts(res?.data?.social_links);
                else notifyError(res?.message)
            })
            .catch((err) => {
                console.log("error", err);
                notifyError(err.message)
            });
    }, [social_section_updated]);
    return (
        <>
            <div className={`social-link-wrapper ${styles.socialLinkWrapper} ${isInfluencer ? styles.influencer : ''}`}>
                {!subDomain ? (<h5 className={`${styles.socialLinkText}`}>Add Social Links</h5>) : null}

                <div className={`${styles.socialLinkBox}`}>
                    <ul className={`${styles.socialLinks} ${isMobile && "text-center"}`}>
                        
                        { mySocialAccounts.length
                            ?
                            mySocialAccounts?.map((val, index) => (
                            <li key={index} style={styleList} className={`${styles.socialLinksList}`}>
                                <a
                                    onClick={() => {
                                        if (subDomain) {
                                            redirectTo(
                                                false,
                                                // val?.social_icon?.prefix_url ? (val?.social_icon?.prefix_url + val?.url) : sanitize_url(val?.url),
                                                '_blank',
                                                subDomain,
                                                'banner'
                                            )
                                        }
                                    }}
                                    target="_blank"
                                    href={val?.url ? sanitize_url(val?.url) : '#' }
                                    className={styles.link}
                                >
                                    <span className={styles.socialIcon}>
                                        {
                                            (val.social_icon.slug in imgIcons)
                                                ? <>
                                                    <svg className={`${styles.calendlyIconSvg} ${isInfluencer ? styles.calendlyIconIn : ''} calendlyIcon`} version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                        width="1052.000000pt" height="1072.000000pt" viewBox="0 0 1052.000000 1072.000000"
                                                        preserveAspectRatio="xMidYMid meet">
                                                        <g transform="translate(0.000000,1072.000000) scale(0.100000,-0.100000)"
                                                            fill="#000000" stroke="none">
                                                            <path d="M4960 10709 c-1038 -83 -1998 -443 -2815 -1055 -391 -293 -785 -686
                                                            -1079 -1079 -616 -820 -981 -1798 -1056 -2830 -13 -180 -13 -590 0 -770 75
                                                            -1032 440 -2010 1056 -2830 294 -392 688 -786 1079 -1079 832 -624 1804 -983
                                                            2860 -1057 212 -15 706 -6 900 15 631 70 1164 217 1717 472 781 360 1491 935
                                                            2020 1635 336 444 605 949 793 1489 61 175 85 283 85 376 0 227 -119 372 -473
                                                            578 -239 139 -475 227 -801 301 -29 6 -29 6 -22 63 4 31 12 95 17 142 6 47 10
                                                            173 10 280 0 107 -4 233 -10 280 -5 47 -13 111 -17 142 -7 57 -7 57 22 63 326
                                                            74 562 162 801 301 354 206 473 351 473 578 0 125 -48 295 -175 616 -357 903
                                                            -971 1709 -1756 2302 -781 591 -1668 940 -2684 1054 -166 19 -770 27 -945 13z
                                                            m725 -579 c1030 -75 1941 -443 2745 -1108 151 -125 471 -446 596 -597 388
                                                            -470 667 -966 859 -1525 43 -127 45 -135 28 -148 -55 -41 -211 -132 -298 -173
                                                            -122 -59 -338 -132 -460 -156 -49 -9 -92 -18 -94 -20 -2 -1 19 -71 47 -155 52
                                                            -159 110 -393 100 -403 -12 -13 -181 -35 -356 -47 -143 -9 -223 -9 -375 0
                                                            -395 24 -671 91 -962 232 -223 108 -366 210 -671 477 -95 83 -203 175 -240
                                                            205 -208 167 -416 269 -642 315 -75 16 -145 18 -522 18 -381 0 -446 -2 -525
                                                            -18 -507 -103 -875 -421 -1030 -890 -66 -200 -70 -247 -70 -777 0 -530 4 -577
                                                            70 -777 155 -469 523 -787 1030 -890 79 -16 144 -18 525 -18 377 0 447 2 522
                                                            18 226 46 434 148 642 315 37 30 145 122 240 205 305 267 448 369 671 477 291
                                                            141 567 208 962 232 152 9 232 9 375 0 175 -12 344 -34 356 -47 10 -10 -48
                                                            -244 -100 -403 -28 -84 -49 -154 -47 -155 2 -2 45 -11 94 -20 122 -24 338 -97
                                                            460 -156 87 -41 243 -132 298 -173 17 -13 15 -21 -28 -148 -192 -559 -471
                                                            -1055 -859 -1525 -125 -151 -445 -472 -596 -597 -921 -762 -2008 -1143 -3180
                                                            -1115 -734 18 -1387 180 -2035 507 -498 251 -875 528 -1280 942 -353 360 -613
                                                            723 -840 1173 -346 684 -515 1392 -515 2155 0 1113 375 2161 1087 3035 113
                                                            138 394 428 535 552 680 597 1503 987 2388 1132 119 19 362 47 480 54 131 8
                                                            486 6 615 -3z"/>
                                                            <path d="M4625 9089 c-879 -62 -1694 -552 -2161 -1299 -71 -113 -640 -1102
                                                            -679 -1180 -132 -263 -227 -576 -271 -900 -24 -172 -24 -528 0 -700 44 -324
                                                            139 -637 271 -900 39 -78 608 -1067 679 -1180 473 -757 1280 -1236 2184 -1299
                                                            193 -14 1246 -14 1447 -1 874 58 1672 517 2148 1236 178 269 807 1392 807
                                                            1440 0 25 -281 49 -480 40 -354 -16 -587 -69 -822 -187 -165 -83 -285 -171
                                                            -553 -408 -191 -169 -280 -239 -419 -333 -263 -176 -549 -282 -861 -318 -119
                                                            -13 -819 -13 -943 1 -953 105 -1644 809 -1732 1764 -13 143 -13 847 0 990 64
                                                            699 453 1278 1055 1571 202 98 447 168 677 193 124 14 824 14 943 1 312 -36
                                                            598 -142 861 -318 139 -94 228 -164 419 -333 268 -237 388 -325 553 -408 235
                                                            -118 468 -171 822 -187 199 -9 480 15 480 40 0 48 -629 1171 -807 1440 -264
                                                            398 -640 729 -1074 945 -345 172 -690 265 -1074 291 -179 12 -1299 11 -1470
                                                            -1z"/>
                                                        </g>
                                                    </svg>

                                                </>
                                                : (<FontAwesomeIcon
                                                    icon={fetch_icon(val.social_icon.slug)}
                                                    className={styles.icon}
                                                />)
                                        }
                                    </span>
                                </a>
                            </li>
                            ))
                            :
                            null
                        }
                    </ul>

                    <SocialElementDemo mySocialAccounts={mySocialAccounts} />

                    {/* {
                        isPro && <div className={`${isInfluencer ? styles.isInfluencerBadge : ''} ${styles.badgeDiv}`}>
                            <h2 style={styleBadgeDiv} className={styles.pro_user}>Pro</h2>
                        </div>
                    }
                    {
                        isStudent && <div className={`${isInfluencer ? styles.influ_stu : ''} ${styles.badgeDiv_stu}`}>
                            <h2 style={styleBadgeDiv} className={`${styles.pro_user} ${styles.stu_tag}`}>Student</h2>
                        </div>
                    } */}

                </div>
            </div>
        </>
    )
}

export default React.memo(
    connect(mapStateToProps, {
        getUserSocialAccounts,
    })(BannerSocial)
);