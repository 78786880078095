import React, {useEffect} from 'react'
import { useDispatch } from "react-redux";
import { arrowRight } from '../../assets/images/icons';
import mixpanel from 'mixpanel-browser';

function BottomTabSectionCards(props) {

    const { title, detail, border, active, defaultTab } = props;

    const dispatch = useDispatch()

    const sectionSelectionHandler = ( ) => {

    //  console.log("%c selectSectionClicked", "font-size: 22px")
        dispatch({
            type: "SET_PROFILE_CHECK_LIST",
            payload: true,
            key: "selectSectionClicked"
        })
    }

    useEffect(()=>{
        if(defaultTab) props?.onClickCard(title);
    },[]);

    return (
        <>
            <li className={`${active && 'active'}`} onClick={(e)=>{ props?.onClickCard(e); sectionSelectionHandler()  }}>
                {title}
                <img src={arrowRight} className='mobile_arrow card-next-arrow' />
            </li>
        </>
    )
}

export default BottomTabSectionCards
