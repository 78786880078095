import { useEffect, useState } from "react";
import { endPoints } from "Constants/utils";
import { getRequest } from "Redux/Actions";
import store from "Redux/store";


const useApiGetUserFont = () => {
  const [loadingGetUserFont, setLoadingGetUserFont] = useState(true);
  const [dataGetUserFont, setDataGetUserFont] = useState(null);

  const apiGetUserFont = async () => {
    let url = endPoints?.getUserFont;

    const userId =store?.getState()?.root?.user_theme?.user_id;

    console.log({userId})

    if(!userId) return

    try {
      let res = await getRequest(`${url}?user_id=${userId}`);
  
      if(res.success) {
        
        document.documentElement.setAttribute('data-font', res?.data?.font_name);
        setLoadingGetUserFont(false);
        setDataGetUserFont(res)
      };
      
      console.log(res?.message);

    } catch(err) {
      console.log(err?.message);
    }
  };

  return { loadingGetUserFont, dataGetUserFont, apiGetUserFont };

};

export default useApiGetUserFont;