import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation, useHistory } from "react-router-dom";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { createStripeCheckout } from "Constants/ApiFunctions";
// import LandingHeader from "Components/LandingPageComponents/Header/LandingHeader";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import LabelInput from "Components/Form/InputLabel/InputLabel";
import styles from './StripeCheckout.module.css'
import { profileImages } from "assets/images/profileImages";
import HtmlParser from "react-html-parser";
import { bg, starImg } from "../../assets/images/index"
import './StripeCheckout.css'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FormCheck from "Components/Form/CheckBox/Checkbox";
import { testUsername } from "Constants/Functions";
import { testUsernameStartingWithNumber } from "Constants/Functions";
import { testEmailRegex } from "Constants/Functions";
import { isEmpty } from "@firebase/util";
import { Spinner } from "react-bootstrap";
import { notifySuccess } from "Constants/Functions";
import { notifyWarning } from "Constants/Functions";


export default function StripeCheckout(props) {

  const ICONS = {...profileImages}

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const [errorName, setErrorName] = useState("");
  const [nameInvalid, setNameInvalid] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [errorPhone, setErrorPhone] = useState("");
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [errorAddress, setErrorAddress] = useState("");
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [errorCity, setErrorCity] = useState("");
  const [cityInvalid, setCityInvalid] = useState(false);
  const [errorState, setErrorState] = useState("");
  const [stateInvalid, setStateInvalid] = useState(false);
  const [errorZipcode, setErrorZipcode] = useState("");
  const [zipcodeInvalid, setZipcodeInvalid] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);

  const [isValid, setIsValid] = useState(false);


  const [step, setStep] = useState(true);

   // Prepare a constant `ip` with empty data by default
  //  const [ip, setIp] = useState();


  //  const getIp = async () => {
  //    // Connect ipapi.co with fetch()
  //    const response = await fetch('https://api.ipify.org/?format=json')
  //    const data = await response.json()
  //    // Set the IP address to the constant `ip`
  //    setIp(data.ip)
  //    console.log('IP...', data.ip)
  //  }

  //  // Run `getIP` function above just once when the page is rendered
  //  useEffect(() => {
  //    getIp()
  //  }, [])

  const currencySign = {
    bykea: location?.state?.detail?.currency || "PKR",
    stripe: location?.state?.detail?.currency || "USD"
  }

  const search = useLocation().search;
  const itemId = new URLSearchParams(search).get("id");
  const [formData, setFormData] = useState({
    item_id: itemId,
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    terms: null
    // user_ip: ip
  });
  let isFormBlank = isEmpty(formData.name) || testUsernameStartingWithNumber(formData.name) || isEmpty(formData.email) || !testEmailRegex(formData?.email) || isEmpty(formData.phone) || !isEmpty(formData.phone) && formData.phone.length < 8;
  const [clientSecret, setClientSecret] = useState("")

  // const productInfo =
  //formData.item_id






const verifyEmail = (event) => {
  if (event.target.name === "email") {
      if (event.target.value === "") {
          setErrorEmail("Please enter your email");
          setEmailInvalid(true);
      }
      if (event.target.value != "" && !testEmailRegex(event.target.value)) {
          setErrorEmail("Please enter a valid email");
          setEmailInvalid(true);
        }
  }
}

// const verifyPhone = (event) => {
//   if (event.target.name === "phone") {
//       if (event.target.value === "") {
//           setErrorPhone("Please enter your phone number");
//           setPhoneInvalid(true);
//       }
//   }
// }


  const hanldeSubmit = (e) => {

    if (formData?.name === "") {
      setErrorName("Please enter your name");
      setNameInvalid(true);
      return
    }
    if (formData?.email === "") {
      setErrorEmail("Please enter your email");
      setEmailInvalid(true);
      return
    }
    if (formData?.email != "" && !testEmailRegex(formData?.email)) {
      setErrorEmail("Please enter a valid email");
      setEmailInvalid(true);
      return
    }
    if (formData?.phone == null) {
      setErrorPhone("Please enter phone number");
      setPhoneInvalid(true);
      return
  }
    if (formData?.address === "") {
      setErrorAddress("Please enter your address");
      setAddressInvalid(true);
      return
    }

    if (formData?.city === "") {
      setErrorCity("Please enter your city");
      setCityInvalid(true);
      return
    }
    if (formData?.state === "") {
      setErrorState("Please enter your state");
      setStateInvalid(true);
      return
    }
    if (formData?.zipcode === "") {
      setErrorZipcode("Please enter your zipcode");
      setZipcodeInvalid(true);
      return
    }

    if (termsAndCondition == false) {
       notifyWarning("Please Accept the terms and conditions");
       return
    }




    let formData1 = new FormData(document.forms[0])
    let obj = Object.fromEntries(
      Array.from(formData1.keys()).map(key => [
        key, formData1.getAll(key).length > 1 ?
          formData1.getAll(key) : formData1.get(key)
      ])
    )
    creatingStripCheckout( obj )

  }

  const creatingStripCheckout = ( data ) => {
      setIsLoading(true);
    createStripeCheckout(data).then(res => {
      setIsLoading(false);
      if(res) {
        console.log("response stripe.......", res)
        setClientSecret(res.payment_intent)
        return notifySuccess("Details verified!")
      }
    });
  }

  // const [phoneNumber, setPhoneNumber] = useState(); // Library

  // useEffect( ()=> {
  //     // console.log({phoneNumber});
  //     // console.log({formData})
  //     // setUserFormData( prev => ({...prev, mobile: phoneNumber }))
  //     setFormData(
  //         prev => ({...prev, phone: phoneNumber }),
  //         setPhoneInvalid(false)
  //     )
  //     setPhoneInvalid(false)
  // }, [phoneNumber, formData])

  const [phoneNumber, setPhoneNumber] = useState(); // Library

  useEffect( ()=> {
    setFormData(
          prev => ({...prev, phone: phoneNumber }),
          setPhoneInvalid(false)
      )
      setPhoneInvalid(false)
      setErrorPhone("")
      // setIsValid(true)

  // }, [phoneNumber, userFormData])
  }, [phoneNumber])



  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  useEffect (()=> {
    setIsFormValid(!nameInvalid && !phoneInvalid && !emailInvalid)

  },[formData])





  return (
    <>
    <Container >

      <div className={styles.bg}>
        <img src={bg} alt="background" />
      </div>

      <div className={styles.header}>

        {/* <div className={`${styles.btn}`}>{HtmlParser(ICONS.leftArrow)}
            <span onClick={(e)=> { clientSecret ? setClientSecret("") : window.location.href = '/'; } }>Back</span>
        </div> */}

        <div onClick={(e)=> {  !clientSecret ? window.location.href = '/' : window.location.reload() } } className={`${styles.btn}`}>{HtmlParser(ICONS.leftArrow)}
            <span >Back</span>
        </div>

        <div className={styles.logo}>
        <a className={``} href={process.env.REACT_APP_STATIC_SITE_ROOT}>{HtmlParser(ICONS.siteLogo)}</a>
        </div>
      </div>

    <div className={`${styles.checkoutPage}`}>
      <Row className={styles.rowCol}>
        <Col md={7}>
          <h2 className={styles.heading}>Payment Details</h2>
          <div className={`${styles.inner_container} stripeCheckout`}>

          {clientSecret ?
              <>
              <Elements options={options} stripe={stripePromise}>
                <StripeCheckoutForm />
              </Elements>
              </>

            :

            <>
            <Form>
                  <Form.Control type="hidden" placeholder="Enter name" className={styles.pkgId} name="item_id" value={formData.item_id} readOnly />
                  {/* <Form.Control type="hidden" placeholder="Ip" className={styles.pkgId} name="user_ip" value={formData.user_ip} readOnly /> */}

                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" name="name"
                          // onChange={ ({target})=> setFormData((prev)=> ({...prev, name:target?.value}) )  }
                          onChange={({ target }) => { setFormData((prev) => ({ ...prev, name: target?.value })); setNameInvalid(false); setErrorName('') }}
                          // onBlur={(e) => {
                          //     verifyName(e);
                          //     }}
                          isInvalid={nameInvalid}
                          />
                          <p className={styles.errorForm}>{errorName}</p>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" name="email"
                        // onChange={ ({target})=> setFormData((prev)=> ({...prev, email:target?.value}) )  }
                        onChange={({ target }) => { setFormData((prev) => ({ ...prev, email: target?.value })); setEmailInvalid(false); setErrorEmail('') }}
                        onBlur={(e) => {
                            verifyEmail(e);
                            }}
                        isInvalid={emailInvalid}
                        />
                        <p className={styles.errorForm}>{errorEmail}</p>
                    </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Contact Number</Form.Label>

                        <PhoneInput
                        className={errorPhone && styles.errorPhoneValid}
                            // placeholder={errorPhone ? errorPhone : 'Enter number'}
                            placeholder={'Enter number'}
                            value={formData?.phone}
                            onChange={setPhoneNumber}
                            name="phone"
                            // onBlur={(e) => {
                            //   verifyPhone(e);
                            //   }}
                            errorMsg={errorPhone}
                            isInvalid={phoneInvalid}
                        />
                        <p className={styles.errorForm}>{errorPhone}</p>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter address" name="address"
                          // onChange={ ({target})=> setFormData((prev)=> ({...prev, address:target?.value}) )  }
                          onChange={({ target }) => { setFormData((prev) => ({ ...prev, address: target?.value })); setAddressInvalid(false); setErrorAddress('') }}
                          // onBlur={(e) => {
                          //     verifyAddress(e);
                          //     }}
                          isInvalid={addressInvalid}
                          />
                          <p className={styles.errorForm}>{errorAddress}</p>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="Enter city" name="city"
                          // onChange={ ({target})=> setFormData((prev)=> ({...prev, city:target?.value}) )  }
                          onChange={({ target }) => { setFormData((prev) => ({ ...prev, city: target?.value })); setCityInvalid(false); setErrorCity('') }}
                          // onBlur={(e) => {
                          //     verifyCity(e);
                          //     }}
                          isInvalid={cityInvalid}
                          />
                          <p className={styles.errorForm}>{errorCity}</p>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="Enter state" name="state"
                          // onChange={ ({target})=> setFormData((prev)=> ({...prev, state:target?.value}) )  }
                          onChange={({ target }) => { setFormData((prev) => ({ ...prev, state: target?.value })); setStateInvalid(false); setErrorState('') }}
                          // onBlur={(e) => {
                          //     verifyState(e);
                          //     }}
                          isInvalid={stateInvalid}
                          />
                          <p className={styles.errorForm}>{errorState}</p>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control type="numbers" placeholder="Enter Zip Code" name="zipcode" maxLength="10"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            setErrorZipcode ('Allow Only Numbers')
                          }
                        }}
                          // onChange={ ({target})=> setFormData((prev)=> ({...prev, zipcode:target?.value}) )  }
                          onChange={({ target }) => { setFormData((prev) => ({ ...prev, zipcode: target?.value })); setZipcodeInvalid(false); setErrorZipcode('') }}
                          // onBlur={(e) => {
                          //     verifyZipcode(e);
                          //     }}
                          isInvalid={zipcodeInvalid}
                          />
                          <p className={styles.errorForm}>{errorZipcode}</p>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Form.Group>
                      <FormCheck
                      className={`customCheckbox ${styles.chechBoxCustom}`}
                      label={[
                        "I agree to Linkstars ",
                          <a href="https://linkst.ar/privacy" target="_blank">
                          Terms of Use
                          </a>
                      ]}
                      feedback='please accept'
                      onChange={() => {
                        setTermsAndCondition(true);
                      }}
                      checked={termsAndCondition}
                    />
                      </Form.Group>

                    </Col>
                  </Row>
                   {/* <Checkbox onText="You like dogs" offText="You don't like dogs"  />  */}

              {/* <input
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(true)}
            />
            <p className={styles.errorForm}>{errorTerms}</p>
                   */}

                  {/* <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="number" placeholder="Enter phone number" name="phone"
                      onChange={ ({target})=> setFormData((prev)=> ({...prev, phone:target?.value}) )  }
                      />
                  </Form.Group> */}
                  <br />
                  <h3 className={styles.subHeading}>Billing</h3>

                  <div className={styles.priceDiv}>
                    <h4>Subtotal</h4>
                    {/* {JSON.stringify(location?.state?.detail)} */}
                    <p className="text-uppercase">{currencySign[location?.state?.detail?.button_type]} {location?.state?.detail?.price}</p>
                  </div>

                  <div className={styles.priceDiv}>
                    <h3>Total Amount</h3>
                    <p className={`${styles.clrBlack} text-uppercase`}>{currencySign[location?.state?.detail?.button_type]} {location?.state?.detail?.price}</p>
                  </div>
                      <Button variant="primary" className={styles.btnSubmit} disabled={isLoading} onClick={(e) => isFormValid ? hanldeSubmit(e) : null}>
                        Next {isLoading ? <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                        className="ms-2"
                      /> : ""} </Button>
              </Form>

              </>

                        }
          </div>
        </Col>

        <Col md={5}>

          <h2 className={styles.heading}>Order Summary</h2>
          <div className={styles.inner_container}>

              <div className={styles.listOfproducts}>
                <div className={styles.mainFlex}>
                <div className={styles.productImg}>
                  <img src={starImg} alt="star" />
                </div>
                <div className={styles.productDes}>
                  <h2>{HtmlParser(location?.state?.detail?.title)}</h2>
                  <h3>{HtmlParser(location?.state?.detail?.points)}</h3>
                </div>
                </div>
                <div className={`${styles.productPrice} text-uppercase`}>
                  <h2>{currencySign[location?.state?.detail?.button_type]} {location?.state?.detail?.price}</h2>
                </div>
              </div>

              <div className={`${styles.pricetotal}`}>
                <h2>Subtotal</h2>
                <h2 className="text-uppercase">{currencySign[location?.state?.detail?.button_type]} {location?.state?.detail?.price}</h2>
              </div>

          </div>
        </Col>
      </Row>
    </div>
    </Container>



    </>
  );
}

