import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav, Dropdown, Button } from "react-bootstrap";
import "./navbar.css";
import { baseUrl, mapStateToProps } from "../../../Constants/utils";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  uploadLogoImage,
  fetchNavSection,
  toggleSection,
  fetchUserTheme,
} from "../../../Redux/Actions";
import { addIcon } from "../../../assets/images/icons/index";
import "./theme-navbar.css";
import "./influncer-navbar.css";
import { notifyError } from "../../../Constants/Functions";
import mixpanel from "mixpanel-browser";

function ThemeNavbar(props) {
  const placeholdertitle = "Your Name?";
  const [brand_text, setBrandText] = useState(placeholdertitle);
  const [expandNav, setExpandNav] = useState(false);
  const [show, setShow] = useState(false);
  const [navBarItem, setNavBarItem] = useState([]);

  const handleShow = () => setShow(!show);

  const subDomain = useSelector((state) => state.root.subDomain);
  const nav_section_updated = useSelector(
    (state) => state.root.nav_section_updated
  );
  const [logo, setLogo] = useState(
    baseUrl + "/theme_assets/logo_placeholder.png"
  );

  // STORE
  const GlobalState = useSelector((state) => state.root);
  const tier = useSelector(state => state.root?.configurations?.tier);
  const section_variations = useSelector((state) => state.root.section_variations);
  const user_menu = useSelector((state) => state.root?.user_menu);
  const dispatch = useDispatch();

  const userTheme = useSelector((state) => state.root.user_theme);
  const tabOpen = useSelector((state) => state.root.tabOpen);
  const isMobile = useSelector((state) => state.root.isMobile);

  // const onImageChange = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     let img = e.target.files[0];
  //     setLogo(URL.createObjectURL(img));
  //     let fd = new FormData();
  //     fd.append("file", img);
  //     props?.uploadLogoImage(fd).then((res) => { });
  //     // this.setState({
  //     //     image: URL.createObjectURL(img)
  //     // });
  //   }
  // };

  // const toggleMenu = (section) => {
  //   let mySec = section.slice(0, -1);
  //   props?.toggleSection({ section }).then((res) => {
  //     if (res.success) {
  //       let user_theme = res?.data?.user_theme;
  //       dispatch({ type: "OPEN_SIDEBAR" });
  //       setExpandNav(false);
  //       if (user_theme) {
  //         dispatch({ type: "SET_USER_THEME", payload: user_theme });
  //         mySec = mySec == "services_section" ? "service_section" : mySec;
  //         if (!has_section(mySec)) {
  //           dispatch({ type: mySec.toUpperCase(), payload: true }); //STAT_SECTION, GALLERY_SECTION, PRICING_SECTION, CONTACT_SECTION, SERVICE_SECTION
  //         }
  //       }
  //     }
  //   });
  // };

  const openAddSection = () => {
    if (isMobile) {
      dispatch({ type: "BOTTOM_TAB_OPEN", payload: "plus" });
      setExpandNav(false);
    } else {
      dispatch({ type: "OPEN_SIDEBAR" });
    }
  };

  // useEffect(() => {
  //   props?.fetchNavSection().then((res) => {
  //     if(res?.success){
  //     if (res?.data?.nav_sections) {
  //       let nav_section = res?.data?.nav_sections;
  //       if (nav_section) {
  //         setLogo(nav_section.brand_logo_url);
  //         setBrandText(nav_section.brand_text ?? placeholdertitle);
  //       }
  //     }}else notifyError(res?.message)
  //   }).catch((err) => {
  //     console.log("applied changes", err);
  //     notifyError(err?.message)
  //   });
  // }, [nav_section_updated]);

  //Sorting of Sections
  useEffect(() => {
    //Sorting
    let newArr = user_menu?.filter(
      // (val) => val !== null && val?.visible && val && val.slug != "banner"
      (val) => val !== null && val?.visible && val && val?.section?.slug != "banner"
    );
    let sortedArray = newArr?.sort(function (a, b) {
      return a.order - b.order;
    });




    sortedArray = sortedArray.filter(sec => {
      /* Hotfix */
      // console.log("section_variations", section_variations.find(s=> s.id==sec.section_id))
      let sv = section_variations.find(s => s.id == sec.section_id)
      // console.log("section_variations", sv)
      return sv && section_variations.find(s => s.id == sec.section_id).theme_variations.find(v => v.tiers == "" || v.tiers.includes(tier));
    });
    newArr && setNavBarItem(sortedArray);
  }, [user_menu, section_variations]);

  // const has_section = (section) => {
  //   if (userTheme && userTheme[section] && userTheme[section].visible) {
  //     return true;
  //   }
  //   return false;
  // };
  return (
    <>
      <Navbar
        bg=""
        expand="lg"
        className={`theme-navbar ${tabOpen && "z-index-10 "} ${props.className
          }`}
        expanded={expandNav}
        style={{ position: tabOpen ? "fixed" : "relative" }}
      >
        <Container className={"navbar-theme-container"}>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setExpandNav(!expandNav);
              handleShow();
            }}
            className={show ? "" : "show"}
          >
            <div className="hamburger">
              <div className="line1 line"></div>
              <div className="line2 line"></div>
              <div className="line3 line"></div>
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto align-items-center">
              <Nav.Link href="#home" className="">
                Home
              </Nav.Link>
              {navBarItem?.map((item, index) => (
                // <Nav.Link key={index} href={`#${item?.section?.slug}`} className="" onClick={() =>{ mixpanel.track(`Link Click - Profile Theme Navbar - Section ${item?.section?.slug}`)} }>
                <Nav.Link key={index} href={`#${item?.section?.slug+'_'+item?.id}`} className="" onClick={() =>{ mixpanel.track(`Link Click - Profile Theme Navbar - Section ${item?.section?.slug}`)} }>
                  {item?.main_title || item?.section?.title}
                </Nav.Link>
              ))}

              {!subDomain ? (
                <Dropdown className=" section-add-dropdown">
                  <Dropdown.Toggle
                    variant="primary-theme"
                    id="dropdown-basic"
                    className="no-toggle-icon rounded-pill py-1"
                    onClick={() => openAddSection()}
                  >
                    {/* <span className="me-1 dropdown-icon">
                      <img src={addIcon} className="img-fluid icon-img" />
                    </span>{" "} */}
                    <span className="dropdown-btn-text">
                      Click to Add Menu Item
                    </span>
                  </Dropdown.Toggle>
                </Dropdown>
              ) : null}
            </Nav>
            {userTheme &&
              userTheme["contact_section"] &&
              userTheme["contact_section"].visible && (
                <Nav className="ms-auto navbar-action-sec">
                  <Nav.Item>
                    <Nav.Link
                      href="#contact"
                      className="rounded-pill py-1 btn btn-primary-theme text-white"
                    >
                      Contact Me
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              )}
            <hr />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default connect(mapStateToProps, {
  fetchNavSection,
  fetchUserTheme,
  uploadLogoImage,
  toggleSection,
})(ThemeNavbar);
