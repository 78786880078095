import store from "../Redux/store";
import { endPoints } from "./utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import displayToast, { Content } from "Constants/Toastify/Toastify";
import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const testEmailRegex = (email) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

// Username Validation
export const testUsername = (name) => {
  return /^(?:[A-Za-z0-9][A-Za-z0-9\-]{0,61}[A-Za-z0-9]|[A-Za-z0-9])$/.test(
    name
  );
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
export const imageCompressor = async (image) => {
  //reference taken from https://solveforum.com/forums/threads/solved-how-to-convert-jpeg-and-or-png-images-to-webp-in-react-js.642396/#post-642537
  let userImage = new Image();

  if(image['type'].split('/')[0] === 'image'){//this if condition is for when image is coming as a formdata-object/file instead of an image
    var reader = new FileReader();

    //This is for previewing the image
    reader.onload = function (event) {
        userImage.src = event.target.result;
    }
    image = new Blob(reader.readAsDataURL(image))
  }
  userImage.src = image;

  return await new Promise((resolve, reject) => {
    try {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      userImage.onload = function () {
        canvas.width = userImage.width
        canvas.height = userImage.height
        ctx.drawImage(userImage, 0, 0);

        // convert canvas to webp
        let webpImage = canvas.toDataURL("image/webp");
        resolve(webpImage)

      };
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }

  })
}

  export const testUsernameStartingWithNumber = (name) => {
    return /^[0-9].+$/.test(name);
  };

  export const testPasswords = (password1, password2) => {
    return password1 === password2;
  };

  export const testPhoneNumber = ( phone ) => {
    return /^[0]?[3]\d{9}$/.test(phone)
  }


  export const notifyError = (msg, title=Content.errorTitle,) => {
    return displayToast('error', title, msg, {autoClose: 3000, pauseOnHover: false,});
  }
  export const notifySuccess = (msg, title=Content.successTitle,) => {
    return displayToast('success', title, msg, {autoClose: 3000, pauseOnHover: false,});
  }
  export const notifyWarning = (msg, title=Content.warnTitle,) => {
    return displayToast('warn', title, msg, {autoClose: 3000, pauseOnHover: false,});
  }
  export const notifyInfo = (msg, title=Content.successTitle,) => {
    return displayToast('info', title, msg, {autoClose: 3000, pauseOnHover: false,});
  }



  export const validatePhoneNumber = (phone) => {
    return /03[0-9]{9}/.test(phone);
    //  let data = phone.target.innerText;
    //   if(data?.length > l){
    //     if ((phone.keyCode == 8 || phone.keyCode == 37 || phone.keyCode == 38 || phone.keyCode == 39 || phone.keyCode == 40) || (phone.keyCode >= 48 && phone.keyCode <= 57) || (phone.keyCode >= 96 && phone.keyCode <= 105))
    //   {
    //   }
    //   else{
    //     let new_str = data.slice(0,l);
    //       phone.target.innerText = new_str;
    //       const input = phone.target;
    //       setEndOfContenteditable(input);
    //   }
    // }
  };

  export const validateDOB = (date) => {
    return new Date().getUTCFullYear() - new Date(date).getUTCFullYear();
  };

  export const validateUrl = (url) => {
    return /(https?:\/\/[^\s]+)/g.test(url) || /(http?:\/\/[^\s]+)/g.test(url);
  };

  export  const isValidUrl = (userInput, acceptOnly ) => {
    let validate = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    let accepted = acceptOnly.some(el => userInput.includes(el) );
    let res = (validate && accepted );
    if( res ) return true;
    return false
  }

  export const validateName = (name) => {
    return /[A-Za-z]/.test(name);
  };

  export const clear_placeholder = (e) => {
    const subDomain = store?.getState()?.auth?.root?.subDomain;
    if (subDomain) {
      return;
    }
    let html = e.target.innerHTML.replace(/\xA0/g, " ").replace("&nbsp;", " ");
    let placeholder = e.target.getAttribute("data-placeholder");
    if (placeholder && placeholder == html) {
      e.target.innerHTML = "";
    }
  };
  export const sanitize_url = (url) => {
    if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
      return url;
    }
    return "https://" + url;
  };
  let toaster_shown = false;
  export const validate_text = (e, l) => {
    const notify = (msg) =>
      toast(msg, {
        delay: 100,
        hideProgressBar: true,
      });
    let data = e.target.innerText;

    if (data?.length > l) {
      if (
        e.keyCode == 8 ||
        e.keyCode == 37 ||
        e.keyCode == 38 ||
        e.keyCode == 39 ||
        e.keyCode == 40
      ) {
      } else {
        let new_str = data.slice(0, l);
        e.target.innerText = new_str;
        const input = e.target;
        setEndOfContenteditable(input);
      }
    }
    // let html = e.target.innerHTML.replace(/\xA0/g, ' ').replace('&nbsp;',' ');
    // let data_type = "alpha_num";
    // if(e.target.getAttribute('data-type'))
    // {
    //   data_type = e.target.getAttribute('data-type');
    // }

    // if (data_type != "points" && e.keyCode == 13) {
    //   // e.target.blur();
    //   // notify('Limit Exceed');
    //   return;
    // }

    // if (data_type == "number")
    // {
    //   if ((e.keyCode == 8 || e.keyCode == 32 || e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40) || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105))
    //   {
    //   }
    //   else{
    //     e.preventDefault();
    //     return;
    //   }
    // }

    // if(l < html.length)
    // {
    //   let new_str = html.slice(0,l);
    //   e.target.innerHTML = new_str;
    //   const input = e.target;
    //   setEndOfContenteditable(input);
    //   if(!toaster_shown)
    //   {
    //     notify('Limit Exceed');
    //     toaster_shown = true;
    //     setTimeout(()=>{
    //       toaster_shown = false;
    //     },5000)
    //   }
    // }
  };

  function setEndOfContenteditable(contentEditableElement) {
    var range, selection;
    if (document.createRange) {
      //Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange(); //Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection(); //get the selection object (allows you to change selection)
      selection.removeAllRanges(); //remove any selections already made
      selection.addRange(range); //make the range you have just created the visible selection
    } else if (document.selection) {
      //IE 8 and lower
      range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
      range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      range.select(); //Select the range (make it the visible selection
    }
  }

  // export const makeRequest = (data, method, url) => (dispatch) =>
  // new Promise((resolve, reject) => {
  //   try {
  //     fetch(url, {
  //       method: method,
  //       mode: "cors",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(data),
  //     }).then((res) => {
  //       res.json().then((data) => {
  //           resolve(data);
  //       });
  //       return;
  //     });
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
  export const contentUpdateHandler = ( e ) => {
    store?.dispatch({ type: "SET_PROFILE_CHECK_LIST", payload: true, key: "cardTitleAdded" })
    store?.dispatch({ type: "SET_PROFILE_CHECK_LIST", payload: true, key: "sectionEdited" })
  }
  export const uploadImageHandler = ( section ) => {
    if ( section !== undefined ) {
      store?.dispatch({
          type: "SET_PROFILE_CHECK_LIST",
          payload: true,
          key: "sectionEdited"
      });
    }
  }

  export const createDraft = (e, table_name, column_name, id) => {
    let html = e.target.innerHTML;
    let placeholder = e.target.getAttribute("data-placeholder");
    // if (html == placeholder) { //if text is placeholder then return without saving on backend
    //   return;
    // }
    if (!html.length) {
      e.target.innerHTML = placeholder;
      // return;
      html = placeholder;
    }
    const token = store?.getState()?.auth?.user?.token;

    fetch(endPoints?.createDraft, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        table_name: table_name,
        column_name: column_name,
        column_value: html,
        id: id,
      }),
    }).then((res) => {
      res.json().then((data) => {
        if (data.success) {
          store?.dispatch({ type: "SET_DRAFTS", payload: data?.data?.drafts });
          if(column_name=='main_title'){
            let count = store?.getState().root.fetchUserMenu+1;
            store?.dispatch({ type: "SET_FETCH_USER_MENU", payload: count });
        }

        } else {
          console.log("error", data);
          notifyError(res?.message)
        }
      }).catch((err) => {
        notifyError(err?.message)
      });
    }).catch((err) => {
      notifyError(err?.message)
    });
  };

  var doubletapDeltaTime_ = 700;
  var doubletap1Function_ = null;
  var doubletap2Function_ = null;
  var doubletapTimer = null;

  export function tap(singleTapFunc, doubleTapFunc) {
    if (doubletapTimer == null) {
      // First tap, we wait X ms to the second tap
      doubletapTimer = setTimeout(doubletapTimeout, doubletapDeltaTime_);
      doubletap1Function_ = singleTapFunc;
      doubletap2Function_ = doubleTapFunc;
    } else {
      // Second tap
      clearTimeout(doubletapTimer);
      doubletapTimer = null;
      doubletap2Function_();
    }
  }

  function doubletapTimeout() {
    // Wait for second tap timeout
    doubletap1Function_();
    if (doubletapTimer) {
      doubletapTimer = null;
    }
  }

  // Date Formating Function

  export const getDate = (date) => {
    let dateObj = new Date(date);
    let month = dateObj.getUTCMonth() + 1;
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    month = month <= 9 ? "0" + month : month;
    day = day <= 9 ? "0" + day : day;

    return year + "-" + month + "-" + day;
  };

  // Redirect Function

  export const redirectTo = (url = false, blank = "_blank", subDomain, slug = null) => {
    if (subDomain != null) {
      try {
        let source = getSourceOfTraffic();
        let data = {
          activity_type: 'clicks',
          subdomain: subDomain,
          slug,
          url,
          source
        };
        fetch(endPoints?.updateActivity, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }).then((res) => {
          res.json().then((data) => {
          });
          return;
        });
      } catch (error) {
        console.log("Exception Error", error);
      }
    }
    if (url != false) {
      url = (url.indexOf('://') === -1) ? 'http://' + url : url;
      window.open(url, blank);
    }
  }

  // Get source of traffic function

  export const getSourceOfTraffic = ()=>{
      let source = null;
      let params = new URLSearchParams(window.location.search);
      let sources = {
        instagram : 'instagram.com',
        google : 'google.com',
        pinterest : 'pinterest.com',
        facebook : 'facebook.com',
        twitter : 't.co',
        linkedin: 'linkedin',
        tiktok: 'tiktok',
        youtube: 'youtube',
        linkstar: 'linkst',
      };
      if(document.referrer!=undefined || document.referrer!=null){
        source = document.referrer;
        Object.keys(sources).forEach(key=>{
          if(source.indexOf(sources[key])!=-1){
            source = (key!='linkstar' || params.get('discover')!=null) ? key : null ;
          }
        })
      }
      return source==null ? 'direct' : source;
  }

 export const kFormatter = (num)=> {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}



/* getGoogleDriveFileName */ 
let getTitle = async (url) => {  
  let response = await fetch( url )
  let html = await response.text()
  const doc = new DOMParser().parseFromString(html, "text/html");
  const title = doc.querySelectorAll('title')[0];
  let res = title.innerText; 
  return res
};

export const getUrlData = async ( url )=>{
 let title = await getTitle( url )
 return title
 console.log(title)
} 


export const getGoogleDriveThumbnail = (id, url, size = 'w800') => {
  if(!url && !id) return null;
  
  if ( url && url?.includes("/d/") ) {
    id = url?.match(/(?:\/d\/)(.*)(?=\/)/g)[0]?.replace("/d/", "") 
  }

  let res;
      // res = `//drive.google.com/thumbnail?authuser=0&sz=${size}&id=${id}`
      res = `//lh3.googleusercontent.com/d/${id}=w800?authuser=0`
  return res
}
