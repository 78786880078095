import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { colors } from "../../Constants/theme";
import "./bottom-tab-mobile.css";
import { Button, Form } from "react-bootstrap";
import {
  setSocialLinks,
  getSocialLinks,
  removeSocialLinks,
  toggleLinkHideStatus,
} from "../../Redux/Actions";
import { mapStateToProps } from "../../Constants/utils";
import TextareaLabel from "../Form/TextareaLabel/TextareaLabel";
import { crossTimesIcon } from "../../assets/images/icons/index";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { notifyError, notifyInfo, notifySuccess, notifyWarning } from "../../Constants/Functions";
import mixpanel from 'mixpanel-browser';

function LinkSection(props) {
  const isMobile = useSelector((state) => state.root.isMobile);

  // const notify = (msg) =>
  //   toast(msg, {
  //     position: isMobile ? "bottom-right" : "top-right",
  //     hideProgressBar: true,
  //     delay: 1000,
  //   });

  const [selectedSocialIcon, setSelectedSocialIcon] = useState(null);
  const [socialInfo, setSocialInfo] = useState([]);
  const dispatch = useDispatch();
  const mySocialRef = useRef();
  const hideLink = useSelector(state => state.auth?.user?.hideLink);

  const socialIconClickHandler = () => {
    dispatch({
      type: "SET_PROFILE_CHECK_LIST",
      payload: true,
      key: "socialIconClicked"
    })
  }

  const onChangeSocialDetail = (eve) => {
    const mySocialIcon = { ...selectedSocialIcon };
    mySocialIcon["url"] = eve?.target?.value;
    mySocialIcon["exists"] = false;
    const url = selectedSocialIcon?.prefix_url + eve?.target?.value;
    setSelectedSocialIcon(mySocialIcon);
  };

  const removeSocialLink = (eve) => {
    eve.preventDefault();
    const data = {
      social_icon_id: selectedSocialIcon?.user_social_id,
    };
    props
      ?.removeSocialLinks(data)
      .then((res) => {
        if (res?.success) {
          dispatch({ type: "SOCIAL_SECTION_UPDATE" });
          getSocialLinks();
          return notifySuccess(res?.message);
        } else notifyError(res?.message)
      })
      .catch((err) => {
        return notifyError("Something Went Wrong");
      });
  };

  const setSocialLink = (eve) => {
    eve.preventDefault();
    if (selectedSocialIcon?.inValid) {
      return notifyWarning("Please Enter Correct URL");
    }
    if (selectedSocialIcon?.url == "") {
      return notifyInfo("Please Enter the URL");
    }
    const data = {
      social_icon_id: selectedSocialIcon?.id,
      url: selectedSocialIcon?.url,
    };
    props
      ?.setSocialLinks(data)
      .then((res) => {
        if (res?.success) {
          dispatch({ type: "SOCIAL_SECTION_UPDATE" });
          getSocialLinks();

          dispatch({
            type: "SET_PROFILE_CHECK_LIST",
            payload: true,
            key: "socialLinkAdded"
          })

          return notifySuccess(res?.message);
        }
      })
      .catch((err) => {
        return notifyError("Something Went Wrong");
      });
  };

  const getSocialLinks = () => {
    props
      ?.getSocialLinks()
      .then((res) => {
        setSelectedSocialIcon(null);
        setSocialInfo(res?.data?.social_icons);
      })
      .catch((err) => {
        console.log("Error", err);
        notifyError(err.message)
      });
  };

  const changeHideLinksStatus = ({ target }) => {
    let status = target.checked;
    dispatch({
      payload: status,
      type: 'TOGGLE_LINKS_HIDE_STATUS'
    });
    props?.toggleLinkHideStatus({ status }).then(res => {
      if (res?.success) {
        // not dispatching here because response is taking longer than 1 sec
      } else {
        console.log('error', res?.message);
      }
    })

  }

  useEffect(() => {
    getSocialLinks();
  }, []);
  useEffect(() => {
    if (selectedSocialIcon !== null) {
      mySocialRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedSocialIcon]);

  return (
    <>
      <div
        className="bottom-tab-content-box"
        style={{
          width: "100%",
          backgroundColor: "white",
          position: "fixed",
          bottom: "60px",
          zIndex: 599,
        }}
      >
        <div className="bottom-tab-header">
          <h3 className="font-weight-bold bottom-tab-title">
            Set Social Links
          </h3>
          <div
            className="icon-section"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch({ type: "BOTTOM_TAB_CLOSE" });
              props?.setSelected("");
            }}
          >
            {/* temporary disabled until page is developed by designer */}
            {/* <span className='icon-box'>
              <img src={infoIcon} className='img-fluid icon' />
            </span> */}
            <span className="icon-box">
              <img src={crossTimesIcon} className="img-fluid icon" />
            </span>
          </div>
        </div>
        <div
          className="bottom-tab-body"
          style={{ float: "left", overflowY: "scroll" }}
        >
          <div className="bottom-tab-sub-title-box">
            <h5 className="font-14 font-weight-bold bottom-tab-sub-title">
              Add Links to your social pages
            </h5>

            <div className="d-flex">
              <Form.Check
                checked={hideLink ? true : false}
                onChange={changeHideLinksStatus}
                className={`link-check me-3`}
                type='checkbox'
                id={`hideAll`}
                label={`Hide Social Links`}
              />
              <div
                className='icon-section'
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props?.setSelected("");
                  dispatch({ type: "CLOSE_SIDEBAR" });
                }}
              >
              </div>
            </div>
          </div>
          <div className="social-icons-wrapper" id="socialSelectContainer">
            {socialInfo?.map((val, ind) => (
              <div
                className="icon-box d-flex flex-column align-items-center"
                style={{ cursor: "pointer", position: "relative" }}
                onClick={() => {
                  selectedSocialIcon === val
                    ? setSelectedSocialIcon(null)
                    : setSelectedSocialIcon(val);

                    socialIconClickHandler()
                  }
                }
              >
                <img
                  className={`align-self-center icon-img`}
                  src={val?.icon_url}
                  width={40}
                  height={40}
                />
                <small
                  className="mt-1 text-center icon-text social-text"
                  style={{
                    fontFamily: "var(--primaryFontBold)",
                    fontSize: 8,
                  }}
                >
                  {val?.name}
                </small>
                {selectedSocialIcon === val && (
                  <div
                    style={{
                      width: "100%",
                      height: "3px",
                      background: colors.primary,
                    }}
                  ></div>
                )}
                {val?.exists && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={colors.primary}
                    style={{
                      position: "absolute",
                      top: 14,
                      left: 12,
                      fontSize: 14,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="px-3 my-3" ref={mySocialRef}>
            {selectedSocialIcon !== null && (
              <div style={{ position: "relative" }}>
                <TextareaLabel
                  label="Whats the web address (URL)?"
                  hasGroup={selectedSocialIcon?.prefix_url}
                  rowLines={1}
                  groupText={
                    selectedSocialIcon?.prefix_url !== "" &&
                    selectedSocialIcon?.prefix_url
                  }
                  placeholder={
                    selectedSocialIcon?.name == "Instagram"
                      ? "enter your username"
                      : selectedSocialIcon?.name == "Twitter"
                        ? "enter your twitter handle"
                        : "enter your profile link"
                  }
                  value={selectedSocialIcon?.url ?? ""}
                  isValid={selectedSocialIcon?.valid}
                  isInvalid={selectedSocialIcon?.inValid}
                  errorMsg={selectedSocialIcon?.error}
                  onChange={onChangeSocialDetail}
                  message={
                    selectedSocialIcon?.exists && (
                      <>
                        <span>Your web page</span>
                        <span className="font-weight-bold">
                          {" "}
                          has been linked,
                        </span>
                        <span> click the below button to </span>
                        <span className="primary-color font-medium">
                          unlink
                        </span>
                      </>
                    )
                  }
                />
                {selectedSocialIcon?.exists ? (
                  <div className="text-end mb-3">
                    <Button variant="primary" onClick={(e) => { removeSocialLink(e);}}>
                      Unlink
                    </Button>
                  </div>
                ) : (
                  <div className="text-end mb-3">
                    <Button variant="primary" onClick={(e) => { setSocialLink(e);}}>
                      Save
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* <ToastContainer style={{ zIndex: 1000 }} limit={1} /> */}
      </div>
    </>
  );
}

export default connect(mapStateToProps, {
  setSocialLinks,
  getSocialLinks,
  removeSocialLinks,
})(LinkSection);
