import React, { useEffect, useState } from "react";
import ImageCropper from "../../../ImageCropper/ImageCropper";
import CustomModal from "../../../Modal";
import { Modal, Button } from "react-bootstrap";
import { editIcon } from "../../../../assets/images/icons/index";
import defaultImage from "../../../../assets/images/profile/avatar.jpeg";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  fetchSliderSection,
  uploadBannerImage,
} from "../../../../Redux/Actions";
import { mapStateToProps } from "../../../../Constants/utils";
import LoadingComponent from "Components/Loader/LoadingComponent/LoadingComponent";
import CropperModal from "../../../Modal/CropperModal/CropperModal";

import styles from "./BannerProfileImage.module.css"

function BannerProfileImage(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [bannerImg, setBannerImg] = useState(null);
  const [showChangeImage, setShowChangeImage] = useState(true);
  const [loading, setLoading] = useState(false);

  const subDomain = useSelector((state) => state.root.subDomain);
  const sliderSection = useSelector((state) => state.root.sliderData);

  const style_class = useSelector((state) => state.root.style_class);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setShowChangeImage(false);
    }, 3000);
  }, []);

  useEffect(() => {
    let url = "";
    if (style_class != "influencer") {
      url = sliderSection?.display_image
        ? sliderSection?.display_image_url
        : null;
    } else {
      url = sliderSection?.slider_image
        ? sliderSection?.slider_image_url
        : null;
    }
    setBannerImg(
      //   sliderSection?.slider_image ? sliderSection?.slider_image_url : null
      url
    );
  }, [sliderSection]);

  const onImageChange = (event) => {

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;

        setImageToCrop(image);
        setModalOpen(true);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const updateBannerImage = (event,croppedImage ) => {
    setLoading(true);
    event.preventDefault();
    setBannerImg(URL.createObjectURL(croppedImage));
    let fd = new FormData();
    fd.append("file", croppedImage);

    if (style_class != "influencer") fd.append("isDisplay", true);

    setModalOpen(false);
    dispatch({ type: "CHANGE_BANNER_INVERSION" });
    props?.uploadBannerImage(fd).then((res) => {
      setLoading(false);

      dispatch({
        type: "SET_PROFILE_CHECK_LIST",
        payload: true,
        key: "profileImageUploaded"
      })

    });
  };

  return loading ? (
    <>
      <LoadingComponent />
    </>
  ) : (
    <>
      <div
        className={
          subDomain
            ? `${styles.userImgBox} user-img-box`
            : `${styles.userImgBox} user-img-box ${showChangeImage && "active"}`
        }
      >
        <CropperModal modalOpen={modalOpen} setModalOpen={setModalOpen}
          uploadApi={updateBannerImage} imageToCrop={imageToCrop} circular={true}
          aspectRatio={1 / 1}  />

        <span className="position-relative">
          {bannerImg ? (
            <img src={bannerImg} className={`img-fluid ${styles.userImage}`} />
          ) : (
            <img src={defaultImage} className={`img-fluid ${styles.userImage}`} />
          )}

          {!subDomain ? (
            <div className={`action-btn-box ${styles.actionBtnBox}`} id="heroBannerUploadBtn">
              <input
                type="file"
                className="d-none"
                id="contained-button-file"
                accept="image/*"
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={onImageChange}
              />
              <label for="contained-button-file">
                <span className={`guide-msg ${styles.iconBox}`}>
                  <img className={`img-fluid ${styles.iconImg}`} src={editIcon} />{" "}
                </span>
              </label>
            </div>
          ) : null}
        </span>
      </div>
      <div
        className={
          subDomain ? "et-mode" : `et-mode ${showChangeImage && "active"}`
        }
      >
        
      </div>
    </>
  );
}

export default React.memo(
  connect(mapStateToProps, {
    fetchSliderSection,
    uploadBannerImage,
  })(BannerProfileImage)
);
