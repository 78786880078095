import React, { useEffect } from 'react'
import styles from './LoadingComponent.module.css'


function LoadingComponent({ display = 'fullscreen', style = {}, type = 'spinner' }) {

    return (
        <div className={`${styles.wrapper}`}
            data-display={display}
            data-type={type}
            style={style}
        >
            <div className={`${styles.loader}`}></div>
        </div>
    )
}

export default LoadingComponent;
