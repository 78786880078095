import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import ReactHtmlParser from 'react-html-parser'

import { iconCheckMarkCircle } from "../../../assets/images/index"


export default function LabelInput(props) {

    return (
        <Form.Group className={`mb-3 ${props.classNameGroup}`} controlId="formBasicEmail">
            <Form.Label className={`mb-2 ${props.classNameLabel}`}>{props.label}</Form.Label>
            <InputGroup className={props.classNameGroup} hasValidation >
            {props.autoComplete?
            <Form.Control 
                type={props.type} 
                placeholder={props.placeholder} 
                value={props.value} 
                name={props.name} 
                autoComplete={props?.autoComplete}
                hidden={true}
                className={props?.className}
             />:null}
             {props.as?
            <Form.Control 
            placeholder={props.placeholder} 
            value={props.value} 
            name={props.name} 
            autoComplete={props?.autoComplete??"on"}
            onChange={props.onChange} 
            onKeyUp={props.onKeyUp} 
            onKeyPress={props.onKeyPress}
            onBlur={props?.onBlur} 
            required={props.require} 
            isValid={props.isValid} 
            isInvalid={props.isInvalid}
            className={props?.className}
            as={props?.as}
             />:<Form.Control 
             type={props.type} 
             placeholder={props.placeholder} 
             value={props.value} 
             name={props.name} 
             autoComplete={props?.autoComplete??"on"}
             onChange={props.onChange} 
             onKeyUp={props.onKeyUp} 
             onKeyPress={props.onKeyPress}
             onBlur={props?.onBlur} 
             required={props.require} 
             isValid={props.isValid} 
             isInvalid={props.isInvalid}
             className={props?.className}
          />}
             {props?.hasGroup && <InputGroup.Text className={props?.groupClassName} id="inputGroupPrepend">{props?.groupText}</InputGroup.Text>}
             { props.message && <Form.Text className="d-flex w-100 text-muted">                 
                  <>
                   <img src={ReactHtmlParser(iconCheckMarkCircle)} alt="icon" />
                   <span className='ps-1'>{props.message}</span>
                  </>
            </Form.Text> }
            <Form.Control.Feedback type="invalid">
                <span>{props.errorMsg}</span>
            </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
    );
}