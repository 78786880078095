const FirebaseConfig = {
  apiKey: "AIzaSyDcWD4sVdZEx_YvbA4Ucbl1FPefj_oDFjw",
  authDomain: "linkstar-336413.firebaseapp.com",
  projectId: "linkstar-336413",
  storageBucket: "linkstar-336413.appspot.com",
  messagingSenderId: "644913499005",
  appId: "1:644913499005:web:872f1c797f78e5ab3c1a14",
  measurementId: "G-C5MXH2HEB9",
}
    
export default FirebaseConfig;
    