import React from "react";
import HtmlParser from "react-html-parser";
import styles from "./PaymentSuccessModal.module.css"
import { partyFace } from "assets/images";
import { Button } from "react-bootstrap";

export default function PaymentSuccessModal (props) {

    return (
        <>
            <div className={styles.modal}>
                <img src={partyFace} />
                <h2>Success!</h2>
                <h3>Thank you for your purchase</h3>
                <p>Your payment has been made.</p>
                <Button onClick={props.handleClose}>Continue</Button>
            </div>
        </>
    )
}