import { faFillDrip, faLink, faPlusSquare, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { colors } from '../../Constants/theme';
import './bottom-tab-mobile.css';
import {
  applyChanges,
  discardChanges,
  themeSectionReorder,
  setSocialLinks,
  getSocialLinks,
  getColorThemes,
  setColorTheme,
  removeSocialLinks
} from "../../Redux/Actions";
import { mapStateToProps } from "../../Constants/utils";
import AddSection from './AddSection';
import LinkSection from './LinkSection';
import ThemeSection from './ThemeSection';
import ManageSections from './ManageSections';
import {
  addSections,
  addSocials,
  addColors,
  manageSections,
  paymentIntegration,
} from "./../../assets/images/icons/index";


function BottomTabMobile(props) {
  const [selected, setSelected] = useState("");
  const [textFieldFocus, settextFieldFocus] = useState(false);
  const tabOpen = useSelector(state => state?.root?.tabOpen);
  const bottomSelectedSection = useSelector(state => state?.root?.bottomSelectedSection);
  const dispatch = useDispatch()

  const showSidebar = useSelector((state) => state?.root?.showSidebar);

  const toggleSection = (section) => {

    if (section != selected) {
      dispatch({ type: 'BOTTOM_TAB_OPEN', payload: section });
      setSelected(section);
    }
    else {
      dispatch({ type: 'BOTTOM_TAB_CLOSE' });
      setSelected('');
    }

  }
  const toolbarSelected = useSelector(state => state?.root?.toolbarSelected);


  useEffect(() => {
    dispatch({ type: "OPEN_SIDEBAR"});

    console.log("selected", selected)
    if( selected && selected == "link" ) {
      dispatch({
        type: "SET_PROFILE_CHECK_LIST",
        payload: true,
        key: "socialNavClicked"
      })  
    }

    if( selected && selected == "plus") {
      dispatch({
        type: "SET_PROFILE_CHECK_LIST",
        payload: true,
        key: "addSectionNavClicked"
      })
    }

    if (tabOpen && selected == "") {
      setSelected(!bottomSelectedSection ? 'plus' : bottomSelectedSection);
    }
  }, [tabOpen]);

  return (
    <>
      {tabOpen && selected === bottomSelectedSection ? <div className='overlay-tab-active'></div> : null}

      <div className='bottom-tab p-0'>
        <div
          id='navBarItem_plus'
          className={`tab-option walk-plus ${selected == "plus" && "active"}`}
          onClick={() => toggleSection("plus")}
          style={{
            backgroundColor:
              selected === ""
                ? colors.white
                : selected == "plus"
                  ? colors.primary
                  : colors.white,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <a
            style={{ textAlign: "center" }}
            class='nav-link py-2'
            aria-current='page'
            title=''
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-original-title='Home'
          >
            <span className='icon-box'>
              <img src={addSections} />
              {/* <FontAwesomeIcon className='bottom-icon' icon={addSections} /> */}
              <p className='option-title icon-section'>Section</p>
            </span>
          </a>
        </div>

        <div
          className={`tab-option walk-link ${selected == "link" && "active"}`}
          onClick={() => toggleSection("link")}
          id="navBarItem_link"
        >
          <a
            style={{ textAlign: "center" }}
            class='nav-link py-2'
            aria-current='page'
            title=''
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-original-title='Home'
          >
            <span className='icon-box'>
              <img src={addSocials} />
              {/* <FontAwesomeIcon className='bottom-icon' icon={addSocials} /> */}
              <p className='option-title icon-section '>Social Links</p>
            </span>
          </a>
        </div>

        <div
          className={`tab-option walk-color ${selected == "color" && "active"}`}
          onClick={() => toggleSection("color")}
        >
          <a
            style={{ textAlign: "center" }}
            class='nav-link py-2'
            aria-current='page'
            title=''
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-original-title='Home'
          >
            <span className='icon-box'>
              <img src={addColors} />
              {/* <FontAwesomeIcon
                className='bottom-icon'
                icon={addColors}
              /> */}
              <p className='option-title icon-section'>Color</p>
            </span>
          </a>
        </div>
      </div>
      {
        selected == 'plus' && showSidebar ? (
          <AddSection selected={selected} setSelected={setSelected} />
        ) : (selected == 'link' || toolbarSelected == 'link' ) && showSidebar ? (
          <LinkSection selected={selected} setSelected={setSelected} textFieldFocus={textFieldFocus} settextFieldFocus={settextFieldFocus} />
        ) : selected == 'color' && showSidebar ? (
          <ThemeSection selected={selected} setSelected={setSelected} />
        ) : selected == "manage" && showSidebar ? (
          <ManageSections selected={selected} setSelected={setSelected} />
        ) : null
      }

    </>
  );
}

export default connect(mapStateToProps, {
  applyChanges,
  discardChanges,
  themeSectionReorder,
  setSocialLinks,
  getSocialLinks,
  getColorThemes,
  setColorTheme,
  removeSocialLinks
})(BottomTabMobile)
