import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMobileAlt,
  faRedoAlt,
  faExchangeAlt,
  faExpand,
  faCompress,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./function-tools.css";
import { mapStateToProps } from "../../Constants/utils";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  invertBanner,
  bannerFlipX,
  bannerFlipY,
  undoAction,
  redoAction,
} from "../../Redux/Actions";
import iconLayout from "../../assets/images/icons/layout-icon.svg";
import flipY from "../../assets/images/icons/flip-y.svg";
import flipX from "../../assets/images/icons/flip-x.svg";
import desktopIcon from "../../assets/images/icons/desktop-icon.svg";
import mobileIcon from "../../assets/images/icons/mobile-icon.svg";
import swipeIcon from "../../assets/images/icons/swipe-icon.svg";
import redoIcon from "../../assets/images/icons/redo-icon.svg";
import redoAltIcon from "../../assets/images/icons/redo-alt-icon.svg";
import expandIcon from "../../assets/images/icons/expand-icon.svg";
import compressIcon from "../../assets/images/icons/compress-icon.svg";
import { notifyError } from "../../Constants/Functions";

function FunctionTools(props) {
  const zoomed = useSelector((state) => state.root.zoomed);
  const isMobile = useSelector((state) => state.root.isMobile);
  const dispatch = useDispatch();
  const [showToolBar, setShowToolBar] = useState(isMobile ? false : true);

  const toggleZoom = () => {
    dispatch({ type: "TOGGLE_ZOOM" });
  };

  const toggleBannerInversion = () => {
    props.invertBanner().then((res) => {
      if (res.success) {
        dispatch({ type: "TOGGLE_BANNER_INVERSION" });
      } else notifyError(res.message)
    }).catch((err) => {
      return notifyError(err.message)
    });
  };

  const toggleFlipX = () => {
    dispatch({ type: "BANNER_FLIP_X" });
    props.bannerFlipX().then((res) => {
      if (res.success) {
      } else notifyError(res?.message)
    }).catch((err) => {
      return notifyError(err.message)
    });
  };

  const toggleFlipY = () => {
    dispatch({ type: "BANNER_FLIP_Y" });
    props.bannerFlipY().then((res) => {
      if (res.success) {
      }
      else notifyError(res?.message)
    }).catch((err) => {
      return notifyError(err.message)
    });
  };

  const undoAction = () => {
    props.undoAction().then((res) => {
      if (res.success) {
        dispatch({ type: res.data.dispatch });
      }
      else notifyError(res?.message)
    }).catch((err) => {
      return notifyError(err.message)
    });
  };

  const redoAction = () => {
    props.redoAction().then((res) => {
      if (res.success) {
        dispatch({ type: res.data.dispatch });
      }else notifyError(res?.message)
    }).catch((err) => {
      return notifyError(err.message)
    });
  };

  return (
    <div className='py-4 pt-3 px-5 function-tools-wrapper'>
      {isMobile && showToolBar && (
        <>
          <div className='function-tools-sec'>
            <div className='function-tools-box'>
              <div className='border-end d-inline left-icons function-tools-option-box'>
                <div className='function-tools-option'>
                  <span className='icon-box'>
                    {/* <FontAwesomeIcon icon={faDesktop} className="tool-icon" /> */}
                    <img src={desktopIcon} className='img-fluid tool-icon' />
                  </span>
                  <span className='icon-box'>
                    {/* <FontAwesomeIcon icon={faLaptop} className="tool-icon" /> */}
                    <img src={mobileIcon} className='img-fluid tool-icon' />
                  </span>
                </div>
              </div>

              <div className='d-flex justify-content-end right-icons function-tools-option-box'>
                <div className=' function-tools-option'>
                  <span className='icon-box' onClick={undoAction}>
                    {/* <FontAwesomeIcon icon={faUndo} className="tool-icon" /> */}
                    <img src={redoAltIcon} className='img-fluid tool-icon' />
                  </span>
                  <span className='icon-box' onClick={redoAction}>
                    {/* <FontAwesomeIcon icon={faRedo} className="tool-icon" /> */}
                    <img src={redoIcon} className='img-fluid tool-icon' />
                  </span>
                </div>
                <div className=' function-tools-option d-md-block'>
                  <span className='icon-box' onClick={toggleBannerInversion}>
                    {/* <FontAwesomeIcon icon={faExchangeAlt} className="tool-icon" /> */}
                    <img src={swipeIcon} className='img-fluid tool-icon' />
                  </span>
                </div>
                <div className=' function-tools-option'>
                  <span className='icon-box d-none d-md-inline'>
                    {/* <FontAwesomeIcon icon={faExchangeAlt} /> */}
                    <img src={flipX} className='img-fluid tool-icon' />
                  </span>
                  <span className='icon-box d-none'>
                    {/* <FontAwesomeIcon icon={faExchangeAlt} /> */}
                    <img src={flipY} className='img-fluid tool-icon' />
                  </span>
                </div>
                <div className=' function-tools-option d-none d-md-block'>
                  <span className='icon-box' onClick={toggleZoom}>
                    {/* <FontAwesomeIcon icon={zoomed ? faExpand : faCompress} className="tool-icon" /> */}
                    <img
                      src={zoomed ? expandIcon : compressIcon}
                      className='img-fluid tool-icon'
                    />
                  </span>
                </div>
                <div className=' function-tools-option d-none d-md-block'>
                  <span className='icon-box'>
                    <img src={iconLayout} className='img-fluid tool-icon' />
                    <span className='ms-1 font-14'>Tools</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isMobile && (
        <div
          className='d-inline d-md-none function-tool-toggler-bar bg-light'
          onClick={() => setShowToolBar(!showToolBar)}
        ></div>
      )}

      {!isMobile && (
        <>
          <div className='function-tools-sec'>
            <div className='function-tools-box'>
              <div className='border-end d-inline left-icons function-tools-option-box'>
                <div className='function-tools-option'>
                  <span className='icon-box' title='Desktop View'>
                    <FontAwesomeIcon
                      icon={faDesktop}
                      className='tool-icon'
                      style={{ fontSize: "22px", color: "gray" }}
                    />
                    {/* <img src={desktopIcon} className='img-fluid tool-icon' /> */}
                  </span>
                  <span className='icon-box' title='Mobile View'>
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className='tool-icon'
                      style={{ fontSize: "22px", color: "gray" }}
                    />
                    {/* <img src={mobileIcon} className='img-fluid tool-icon' /> */}
                  </span>
                </div>
              </div>

              <div className='d-flex justify-content-end right-icons function-tools-option-box'>
                <div className='border-start border-end function-tools-option'>
                  <span className='icon-box' title='Undo' onClick={undoAction}>
                    <FontAwesomeIcon
                      icon={faUndoAlt}
                      className='tool-icon'
                      style={{ fontSize: "22px" }}
                    />
                    {/* <img src={redoAltIcon} className="img-fluid tool-icon" /> */}
                  </span>
                  <span className='icon-box' title='Redo' onClick={redoAction}>
                    <FontAwesomeIcon
                      icon={faRedoAlt}
                      className='tool-icon'
                      style={{ fontSize: "22px" }}
                    />
                    {/* <img src={redoIcon} className="img-fluid tool-icon" /> */}
                  </span>
                </div>
                <div className='border-end function-tools-option d-md-block'>
                  <span
                    className='icon-box'
                    onClick={toggleBannerInversion}
                    title='Toggle Image'
                  >
                    <FontAwesomeIcon
                      icon={faExchangeAlt}
                      className='tool-icon'
                      style={{ fontSize: "22px" }}
                    />
                    {/* <img src={swipeIcon} className='img-fluid tool-icon' /> */}
                  </span>
                </div>
                <div className='border-end function-tools-option'>
                  <span
                    className='icon-box d-none d-md-inline'
                    onClick={toggleFlipX}
                    title='Flip Image Horizontally'
                  >
                    {/* <FontAwesomeIcon icon={faExchangeAlt}  /> */}
                    <img src={flipX} className='img-fluid tool-icon' />
                  </span>
                  <span
                    className='icon-box'
                    onClick={toggleFlipY}
                    title='Flip Image Vertically'
                  >
                    {/* <FontAwesomeIcon icon={faExchangeAlt} /> */}
                    <img src={flipY} className='img-fluid tool-icon' />
                  </span>
                </div>
                <div className='border-end function-tools-option d-none d-md-block'>
                  <span
                    className='icon-box'
                    onClick={toggleZoom}
                    title={!zoomed ? "Zoom In" : "Zoom Out"}
                  >
                    <FontAwesomeIcon
                      icon={zoomed ? faExpand : faCompress}
                      className='tool-icon'
                      style={{ fontSize: "22px" }}
                    />
                    {/* <img
                      src={zoomed ? expandIcon : compressIcon}
                      className='img-fluid tool-icon'
                    /> */}
                  </span>
                </div>
                <div className='border-start function-tools-option d-none d-md-block'>
                  <span className='icon-box' title={"Tools"}>
                    <img src={iconLayout} className='img-fluid tool-icon' />
                    <span className='ms-1 font-14'>Tools</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default connect(mapStateToProps, {
  invertBanner,
  bannerFlipX,
  bannerFlipY,
  undoAction,
  redoAction,
})(FunctionTools);
