import ConsoleLogSetting from "./ConsoleLog";
import GitInformation from "./GitInformation";
const domain = '.' + process.env.REACT_APP_DOMAIN;
const isLocal = process.env.REACT_APP_RUN_AS_LOCALHOST

const Setting = {
  localProfileId: 'aaa', // 'devtest', // 'laibaiftikhar', // 'xplicitr', // 'samaraktar', // 'emaanfreelancer', //'laibaiftikhar', // areebazehra  live4
  joinAlert: true,
  facebookChat: false,
  whatAppChat: false,
  footer: true,
  consoleLog: true,
  sentry: false,
  domain: !isLocal ? '.' + domain : domain,
  gitinfos: true
}

export default Setting;

export {
  ConsoleLogSetting,
  GitInformation
}
