import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './AddNewLabel.module.css'


function AddNewLabel(props) {

  return (
    <>
      <div className={styles.ribbonLabel}>
        <span>New</span>
      </div>

      {/* <svg className={styles.newCardIndicate} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#F98D1C" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M57.8252 24.4491L54.9254 16.7163L52.0257 24.4491L46.5672 26.2685L52.0257 28.088L54.9254 35.8208L57.8252 28.088L63.2837 26.2685L57.8252 24.4491ZM53.0491 53.1058L50.1493 45.373L47.2495 53.1058L41.7911 54.9253L47.2495 56.7447L50.1493 64.4775L53.0491 56.7447L58.5075 54.9253L53.0491 53.1058ZM35.7242 34.5945L31.0448 21.4924L26.3655 34.5945L15.5225 38.2088L26.3655 41.8232L31.0448 54.9253L35.7242 41.8232L46.5672 38.2088L35.7242 34.5945Z" fill="white" />
      </svg> */}

    </>
  );
}


export default AddNewLabel;