import React, { useRef, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Container, Modal } from "react-bootstrap";
import ThemeNavbar from "./ThemeNavbar";
import Banner from "../Banner/Banner";
import { uploadBannerImage } from "./../../../Redux/Actions";
import BannerImage from "../../../assets/images/section_variations/Banner/Influencer-banner.jpg";
import CardEditBlock from "../../ThemeCard/Block/CardEditBlock";
import CustomModal from "../../Modal";
import ImageCropper from "../../ImageCropper/ImageCropper";
import LoadingComponent from "Components/Loader/LoadingComponent/LoadingComponent";
import { notifyError } from "../../../Constants/Functions";
import CropperModal from "../../Modal/CropperModal/CropperModal";
// import LikeButton from "Components/LikeButton/LikeButton";

function ThemeHeader(props) {
  const navRef = useRef();
  const bannerRef = useRef();

  const [modalOpen, setModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  // const [croppedImage, setCroppedImage] = useState(undefined);
  const [bannerImg, setBannerImg] = useState(null);
  const [showChangeImage, setShowChangeImage] = useState(true);
  const [loading, setLoading] = useState(false);

  const isMobile = useSelector((state) => state.root.isMobile);

  const nav_section_updated = useSelector(
    (state) => state.root.nav_section_updated
  );

  // const sliderData = useSelector((state) => state.root.sliderData);

  const subDomain = useSelector((state) => state.root.subDomain);
  const userTheme = useSelector((state) => state.root.user_theme);
  const banner_section_updated = useSelector(
    (state) => state.root.banner_section_updated
  );

  const style_class = useSelector((state) => state.root.style_class);
  const dispatch = useDispatch();

  // Banner Fix Start

  const isFirstRun = useRef(true);

  const banner_data = props.section_data;

  useEffect(() => {
    if (isFirstRun.current == true) {
      dispatch({
        type: 'SLIDER_SECTION_DATA',
        payload: banner_data
      });
      isFirstRun.current = false
    } else {
      // setLoading(true);
      // props?.fetchSliderSection().then((res) => {
      //   setLoading(false);
      //   if(res?.success)
      //   dispatch({
      //     type: 'SLIDER_SECTION_DATA',
      //     payload: res?.data?.section
      //   });
      //   else notifyError(res?.message)
      // }).catch((err) => {
      //   console.log("applied changes", err);
      //   notifyError(err?.message)
      // });
    }

  }, [banner_section_updated]);

  // Banner Fix End


  const onImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;

        setImageToCrop(image);
        setModalOpen(true);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const updateBannerImage = (event, croppedImage) => {
    setLoading(true);
    event.preventDefault();
    setBannerImg(URL.createObjectURL(croppedImage));
    let fd = new FormData();
    fd.append("file", croppedImage);
    setModalOpen(false);
    dispatch({ type: "CHANGE_BANNER_INVERSION" });
    props?.uploadBannerImage(fd).then((res) => {
      
      dispatch({
        type: "SET_PROFILE_CHECK_LIST",
        payload: true,
        key: "profileImageUploaded"
      })

      setLoading(false);
    }).catch((err) => {
      console.log("applied changes", err);
      notifyError(err?.message)
    });
  };

  useEffect(() => {
    if (navRef?.current) navRef.current.scrollIntoView({ behavior: "smooth" });
  }, [nav_section_updated]);

  useEffect(() => {
    if (bannerRef?.current)
      bannerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [banner_section_updated]);

  useEffect(() => {
    setBannerImg(banner_data?.slider_image_url);
  }, [userTheme]);

  const bannerStyle = style_class == "influencer" ? {
      // backgroundImage:
      //   ` linear-gradient(to bottom, rgba(114, 113, 114, 0.2), rgba(0, 0, 0, 0.8)), url(${ bannerImg!=null ? bannerImg : BannerImage
      //   })`,
      //   backgroundSize: "100% 100%"
      backgroundImage: ` linear-gradient(to bottom, rgba(114, 113, 114, 0.2), rgba(0, 0, 0, 0.8)), url(${ bannerImg!=null ? bannerImg : BannerImage})`,
      backgroundSize: "center",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "inherit"
    } : {};

  return loading ? (
    <>
      <LoadingComponent />
    </>
  ) : (
    <>
      {style_class == "influencer" && (
        // <CustomModal
        //   show={modalOpen}
        //   backdrop={"static"}
        //   onHide={() => {
        //     setModalOpen(false);
        //     setCroppedImage(undefined);
        //   }}
        // >
        //   <Modal.Header closeButton className="border-bottom-0">
        //     <Modal.Title></Modal.Title>
        //   </Modal.Header>
        //   <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        //     <div>
        //       <ImageCropper
        //         aspectRatio={16 / 9}
        //         imageToCrop={imageToCrop}
        //         onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
        //       />
        //     </div>
        //     {croppedImage && (
        //       <>
        //         <Button
        //           variant="primary"
        //           onClick={updateBannerImage}
        //           className="my-3"
        //         >
        //           Set
        //         </Button>
        //       </>
        //     )}
        //   </Modal.Body>
        // </CustomModal>
      <CropperModal modalOpen={modalOpen} setModalOpen={setModalOpen}
        uploadApi={updateBannerImage} imageToCrop={imageToCrop}
        aspectRatio={16 / 9} section={props?.section} 
        circular={props?.circular}/>
      )}

      <Container
        fluid
        // style={{ backgroundImage: style_class == 'influencer' && `url(${bannerImg ?? BannerImage})` }}
        className={`px-0 theme-header-section show_boundary ${style_class} `}
        // style={{
        //   background:
        //     style_class == "influencer" &&
        //     ` linear-gradient(to bottom, rgba(114, 113, 114, 0.2), rgba(0, 0, 0, 0.8)), url(${ bannerImg!=null ? bannerImg : BannerImage
        //     }) no-repeat center center / cover`,
        // }}
        style={bannerStyle}

      >
        <ThemeNavbar innerRef={navRef} />

        {!subDomain && style_class == "influencer" && (
          <CardEditBlock pen={"banner"} parentName={"heroBanner"} onImageChange={onImageChange} />
        )}

        {/* <div className='themeHeader_like'>
        <LikeButton />
        </div> */}

        {/* <Banner innerRef={bannerRef} /> */}

        {(!isMobile && style_class == "influencer") ||
          style_class == "basic" ||
          style_class == "student" ||
          style_class == "freelancer" ? (
          <Banner innerRef={bannerRef} />
        ) : null}
      </Container>
      {isMobile && style_class == "influencer" ? (
        <Banner innerRef={bannerRef} />
      ) : null}
    </>
  );
}

export default connect(null, { uploadBannerImage })(ThemeHeader);
