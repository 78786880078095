import React, { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap"
import LoadingComponent from "Components/Loader/LoadingComponent/LoadingComponent";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchSliderSection } from '../../../Redux/Actions';
import BannerOne from "./Variant/BannerOne";
import BannerTwo from "./Variant/BannerTwo";
import "./banner.css";
import "./bannerTwo.css";
// import PageLoader from "../../PageLoader";

function Banner(props) {
  const [loading, setLoading] = useState(false);
  let dispatch = useDispatch();

  const style_class = useSelector(
    (state) => state.root.style_class
  );

  // const banner_section_updated = useSelector(
  //   (state) => state.root.banner_section_updated
  // );

  const subDomain = useSelector((state) => state.root.subDomain);

  // Old Function
  // useEffect(() => {
  // setLoading(true);
  // props?.fetchSliderSection().then((res) => {
  // setLoading(false);
  // dispatch({
  // type: 'SLIDER_SECTION_DATA',
  // payload: res?.data?.section
  // });
  // console.log("banner", res?.data?.section)
  // });
  // }, [banner_section_updated]);

  // Getting Slider Section from root instead of hitting api
  // const isFirstRun = useRef(true);
  // const banner_data = props.section_data;

  // const banner_data = useSelector(state=>{
  //   state.root.user_theme.sections.forEach((sec)=>{
  //     if(sec.section.slug=='banner'){
  //       return sec;
  //     }
  //   })
  //   return state.root.user_theme.sections[0];
  // })

  // useEffect(() => {
  //   if (isFirstRun.current == true) {
  //     dispatch({
  //       type: 'SLIDER_SECTION_DATA',
  //       payload: banner_data
  //     });
  //     isFirstRun.current = false
  //   } else {
  //     setLoading(true);
  //     props?.fetchSliderSection().then((res) => {
  //       setLoading(false);
  //       dispatch({
  //         type: 'SLIDER_SECTION_DATA',
  //         payload: res?.data?.section
  //       });
  //     });
  //   }

  // }, [banner_section_updated]);

  return (
    loading ? (
      <>
        <LoadingComponent />
        {/* {
          subDomain ? <PageLoader/> : <LoadingComponent />  
        } */}
      </>) : (
      <Container className={`theme-header-banner-section`}  >
        <div className="theme-header-banner-box">
          {style_class == "influencer" ? <BannerTwo /> : <BannerOne />}
        </div>
      </Container>
    )

  );
}


export default connect(null, { fetchSliderSection })(Banner)