import {React, useEffect, useState} from 'react';
import { Row, Col } from "react-bootstrap";
import styles from'./LikeButton.module.css';
import './LikeButton.css';
import { useSelector } from "react-redux";
import { kFormatter } from 'Constants/Functions';
import { getLikes, resetLikes, sendLike } from 'Constants/ApiFunctions';
import { cookies } from 'Constants/Functions';
import mixpanel from 'mixpanel-browser';
import { setUser } from '@sentry/react';

function LikeButton() {

    const [isChecked, setIsChecked] = useState(false);
    const [allowLike, setAllowLike] = useState(true);
    const [userLiked, setUserLiked] = useState(false);
    const [likes, setLikes] = useState('1');
    const subDomain = useSelector(state=> state.root.subDomain);
    const isInfluencer = useSelector((state) => state.root.user_theme?.theme?.slug) == 'influencer';
    const IP = useSelector((state) => state.root.user_theme?.ip_address);
    const auth = useSelector((state) => state.auth.user);

    const visitor = cookies.get('link_username') ?? 'guest';

    const saveLikesLocally = (visitor)=>{
        let oldLikes = localStorage.getItem('likes');
        if(oldLikes && oldLikes!=''){
            oldLikes = JSON.parse(oldLikes);
            if(Array.isArray(oldLikes)){
                oldLikes.push(visitor);
            }else{
                oldLikes = [visitor];
            }
        }else{
            oldLikes = [visitor];
        }

        localStorage.setItem('likes', JSON.stringify(oldLikes) );
    }

    const hasLiked = (visitor)=>{
        let likes = localStorage.getItem('likes');
        if(likes && likes!=''){
            likes = JSON.parse(likes);
            if(Array.isArray(likes)){
                return likes.indexOf(visitor) != -1; //visitor exists in array = true
            }
        }

        return false;
    }

    const handleChange = event => {
        if(!allowLike){
            return;
        }
        sendLike().then(res=>{
            resetLikes(setLikes);
            // setIsChecked(current => !current);
            mixpanel.track("Button Click - Profile Banner - Like")
            setIsChecked(true);
            setAllowLike(false);
            setUserLiked(true);
            // localStorage.setItem('like',subDomain);
            saveLikesLocally(visitor);
        });
    };

    useEffect(()=>{
        // resetLikes(setLikes);
        // let liked = localStorage.getItem('like') == subDomain;
        let liked = hasLiked(visitor);
        getLikes().then(likes=>{
            if(!likes) return;

            let myLike = likes?.find(like=> like?.from_ip_address == IP);
            if(liked || myLike || auth){
                setAllowLike(false);
                setIsChecked(true);
            }
            if (userLiked) {
            setLikes( kFormatter(likes?.length+1) );
            }
            else
            {
            setLikes( kFormatter(likes?.length) );
            }
        });
    },[]);


    return(
        <>
            <div className={`LikeButton ${isChecked ? 'checked' : 'unchecked'} ${isInfluencer ? 'isInfluencerChecked' : 'isInfluencerUnchecked'}`}>
                <input type="checkbox" id="checkbox" onChange={handleChange} />
                <label for="checkbox" className='LikeLabel'>

                <svg id="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group" fill="none" fill-rule="evenodd" transform="translate(467 392)">
                    <path style={isChecked ? {fill: '#e2264d'}: {}} d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" id="heart" stroke={isChecked ? '#e2264d' : '#000'}/>
                    <circle id="main-circ" fill="#E2264D" opacity="0" cx="29.5" cy="29.5" r="1.5"/>

                    <g id="grp7" opacity="0" transform="translate(7 6)">
                        <circle id="oval1" fill="#9CD8C3" cx="2" cy="6" r="2"/>
                        <circle id="oval2" fill="#8CE8C3" cx="5" cy="2" r="2"/>
                    </g>

                    <g id="grp6" opacity="0" transform="translate(0 28)">
                        <circle id="oval1" fill="#CC8EF5" cx="2" cy="7" r="2"/>
                        <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2"/>
                    </g>

                    <g id="grp3" opacity="0" transform="translate(52 28)">
                        <circle id="oval2" fill="#9CD8C3" cx="2" cy="7" r="2"/>
                        <circle id="oval1" fill="#8CE8C3" cx="4" cy="2" r="2"/>
                    </g>

                    <g id="grp2" opacity="0" transform="translate(44 6)">
                        <circle id="oval2" fill="#CC8EF5" cx="5" cy="6" r="2"/>
                        <circle id="oval1" fill="#CC8EF5" cx="2" cy="2" r="2"/>
                    </g>

                    <g id="grp5" opacity="0" transform="translate(14 50)">
                        <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2"/>
                        <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2"/>
                    </g>

                    <g id="grp4" opacity="0" transform="translate(35 50)">
                        <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2"/>
                        <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2"/>
                    </g>

                    <g id="grp1" opacity="0" transform="translate(24)">
                        <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2"/>
                        <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2"/>
                    </g>
                    </g>
                </svg>
                {/* <p className={`countTxt ${isChecked ? 'showCount' : 'hideCount'}`}>14K</p> */}
                <p className={`countTxt `}>{isChecked ? likes : 'Like'}</p>
                </label>
            </div>

        </>
    )
}

export default LikeButton;