import React, { useEffect, useLayoutEffect, useRef, useState, Suspense } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  applyChanges,
  discardChanges,
  fetchDraft,
  fetchUserTheme,
  getSectionVariations,
  updateActivity,
  walkthroughGuide
} from "Redux/Actions";
import { mapStateToProps } from "Constants/utils";
import {getSourceOfTraffic} from 'Constants/Functions';
import LoadingComponent from "Components/Loader/LoadingComponent/LoadingComponent";
import FunctionTools from "Components/FunctionTools/FunctionTools";

import BottomTabMobile from "Components/BottomTabMobile/BottomTabMobile";
import "./basic-theme.css";
import "./basic-theme-font.css"

import ThemeHeader from "Components/Sections/Header/ThemeHeader";
import Footer from "Components/Sections/Footer/Footer";
import JoinAlert from "Components/Alert/JoinAlert";
import Setting from "Setting/Setting";
import { useLocation } from "react-router-dom";
import GlobalModal from "Components/Modal/GlobalModal/GlobalModal";
import PaymentSuccessModal from "Components/Modal/PaymentSuccessModal/PaymentSuccessModal";
import { useApiGetUserFont } from "hooks/Apis";


const GallerySection = React.lazy(() => import('Components/Sections/Gallery/GallerySection'));
const BookSection = React.lazy(() => import('Components/Sections/Book/BookSection'));
const BlogSection = React.lazy(() => import('Components/Sections/Blog/BlogSection'));
const MusicSection = React.lazy(() => import('Components/Sections/Music/MusicSection'));
const ExperienceSection = React.lazy(() => import('Components/Sections/Experience/ExperienceSection'));
const ServiceSection = React.lazy(() => import('Components/Sections/Service/ServiceSection'));
const InternshipSection = React.lazy(() => import('Components/Sections/Internship/InternshipSection'));
const EducationSection = React.lazy(() => import('Components/Sections/Education/EducationSection'));
const ProjectSection = React.lazy(() => import('Components/Sections/Project/ProjectSection'));
const HighlightsSection = React.lazy(() => import('Components/Sections/Highlights/HighlightsSection'));
const CustomSection = React.lazy(() => import('Components/Sections/Custom/CustomSection'));
const VideoSection = React.lazy(() => import('Components/Sections/Video/VideoSection'));
const VideoSlider = React.lazy(() => import('Components/Sections/Video/VideoSlider'));

const CouponSection = React.lazy(() => import('Components/Sections/Coupon/CouponSection'));
const CollaborationSection = React.lazy(() => import('Components/Sections/Collaboration/CollaborationSection'));
const PricingSection = React.lazy(() => import('Components/Sections/Pricing/PricingSection'));
const ContactSection = React.lazy(() => import('Components/Sections/Contact/ContactSection'));
const PortfolioSection = React.lazy(() => import('Components/Sections/Portfolio/PortfolioSection'));
const InstagramSection = React.lazy(() => import('Components/Sections/Instagram/InstagramSection'));

let dynamic_sections = {
  "books": BookSection,
  "contact": ContactSection,
  "experience": ExperienceSection,
  "projects": ProjectSection,
  "gallery": GallerySection,
  "internship": InternshipSection,
  "education": EducationSection,
  'music': MusicSection,
  'pricing': PricingSection,
  'services': ServiceSection,
  'highlights': HighlightsSection,
  'custom': CustomSection,
  'blogs': BlogSection,
  'collab': CollaborationSection,
  'coupon': CouponSection,
  'videos': VideoSection,
  'portfolio': PortfolioSection,
  'instagram': InstagramSection
}


function BasicTheme(props) {

  const search = useLocation().search;
  const payment_status=new URLSearchParams(search).get("payment_status");
  const [paymentModal, setPaymentModal] = useState(payment_status);

  const subDomain = useSelector((state) => state.root.subDomain);
  const isMobile = useSelector((state) => state.root.isMobile);
  const userTheme = useSelector((state) => state.root.user_theme);
  const zoomed = useSelector((state) => state.root.zoomed);
  const [isScrolling, setIsScrolling] = useState(false);
  const galleryRef = useRef();
  const statsRef = useRef();
  const customRef = useRef();
  const pricingRef = useRef();
  const servicesRef = useRef();
  const contactRef = useRef();
  const blogRef = useRef();
  const navRef = useRef();
  const bannerRef = useRef();
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [flashBorders, setFlashBorders] = useState("");
  const auth = useSelector((state) => state.auth?.user);
  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);
  const custom_section_updated = useSelector(
    (state) => state.root.custom_section_updated
  );
  const price_section_updated = useSelector(
    (state) => state.root.price_section_updated
  );
  const service_section_updated = useSelector(
    (state) => state.root.service_section_updated
  );
  const gallery_section_updated = useSelector(
    (state) => state.root.gallery_section_updated
  );
  const showBottomTab = useSelector((state) => state.root.showBottomTab);
  const style_class = useSelector((state) => state.root.style_class);

  const fetchUserMenu = useSelector((state) => state.root.fetchUserMenu);
  const section_variations = useSelector((state) => state.root.section_variations);
  const [localSectionVariations, setLocalSectionVariations] = useState(null);
  const firstTime = useRef(false);
  const tier = useSelector(state=> state.root?.configurations?.tier);
  const { apiGetUserFont } = useApiGetUserFont()

  // useEffect(()=>{
  //   // Not hitting api for first time the component renders, will render when section var. renders
  //   if(firstTime.current){
  //     fetchMenu();
  //   }
  //   firstTime.current = true;
  // },[fetchUserMenu,section_variations])


  const stat_section_updated = useSelector(
    (state) => state.root.stat_section_updated
  );
  const contact_section_updated = useSelector(
    (state) => state.root.contact_section_updated
  );

  let [bannerData, setBannerData] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  function closePaymentModal () {
    setPaymentModal (false)
    window.history.pushState({}, document.title, "/" + "");
  }

  useEffect(() => {
    if (auth && auth.username == "") {
      return history?.push("auth/social-register");
    }
  }, []);


  useEffect(() => {
    props.getSectionVariations().then((res) => {
      if (res.success && res.data.sections) {
        setLocalSectionVariations(res.data.sections);
        dispatch({
          type: "SET_SECTION_VARIATIONS",
          payload: res.data.sections,
        });
      }

    });
    // document.fonts.load("12px MarkPro").then( () => { setLoading(false) } );
    // document.fonts.load("12px MarkPro-Bold").then( () => { setLoading(false) } );
    // document.fonts.load("12px MarkPro-Medium").then( () => { setLoading(false) } );
    document.fonts.load("12px MarkPro");
    document.fonts.load("12px MarkPro-Bold");
    document.fonts.load("12px MarkPro-Medium");

  }, []);

  useEffect(() => {//auth.guide false per walkthrough dikheyga true per nahi dikheyga
    if (auth && (!auth.guide ?? true))
      setStepsEnabled(false);
    let x = 1;
    let scroll_e = false;
    if (!stepsEnabled) {
      document.addEventListener("scroll", function (e) {
        if (!isScrolling && !scroll_e) {
          setIsScrolling(true);
          scroll_e = true;
          setTimeout(() => {
            setIsScrolling(false);
            scroll_e = false;
          }, 500);
        }
      });
    }
  }, [stepsEnabled]);




  useEffect(() => {
    if(!localSectionVariations) return; //UserTheme Must not load before loading section variations

    if (subDomain) {

      let source = getSourceOfTraffic();
      props?.updateActivity({
        activity_type: 'views',
        subdomain: subDomain,
        source
      })
    }
    setTimeout(() => {
      setIsScrolling(false);
    }, 500);
    setLoading(true);
    props?.fetchUserTheme().then((res) => {
      setLoading(false);
      if (res.success) {
        let user_theme = res?.data?.user_theme;
        if (user_theme) {
          //WIP 
          user_theme?.sections?.map(s=> {
            if(s?.section?.slug == 'pricing'){
              s.items = s?.items?.map(el=>{
                if(el?.button_type == "stripe"){
                  el.currency = "USD" 
                }
                return el
              })
            }
            return s
          })
          dispatch({ type: "SET_USER_THEME", payload: user_theme });
          dispatch({ type: "SET_USER_MENU", payload: user_theme?.sections });

          setLoading(false);

          let color = user_theme?.theme_color?.style_name;
          document.documentElement.className = color;
          if(user_theme?.theme_font?.font_name != null)//this work will be done in future -- pushing it in advance - shahnoor
            document.documentElement.setAttribute('data-font',user_theme?.theme_font?.font_name)
          if (user_theme.sections) {
            for (let i = 0; i < user_theme.sections.length; i++) {
              if (user_theme?.sections[i]?.section?.slug == "banner") {
                dispatch({ type: "SLIDER_SECTION_DATA", payload: user_theme?.sections[i] })
              }
            }
          }
        }
      }else{
          window.location = `${process.env.REACT_APP_STATIC_SITE_ROOT}`
      }


    }).catch((err) => {
      if (err == 401) {
        localStorage.removeItem("user");
        dispatch({ type: "LOGOUT" });
        window.location = `${process.env.REACT_APP_STATIC_SITE_ROOT}`
      }
    });




  }, [localSectionVariations]);


  useEffect(() => {
    if (isMobile) {
      setFlashBorders("initial_render");
      setTimeout(() => {
        setFlashBorders("");
      }, 4000);
    }
    let temp = [];
    let index = 0;
    if (userTheme) {
      userTheme?.sections?.forEach((unique_sections) => {
        if (unique_sections.order) {
          index = unique_sections.order;
        }
        while (temp[index]) {
          index = index + 100;
        }
        let sectionWithVariation = localSectionVariations?.find(s=> s.id==unique_sections.section_id);
        let allow = sectionWithVariation?.theme_variations?.find( v => v.tiers=="" || v.tiers?.includes(tier) );
        let SectionName = dynamic_sections[unique_sections.section?.slug];
        // Setting Banner section_data prop
        if (unique_sections.section?.slug == 'banner') {
          setBannerData(unique_sections);
        }
        // Setting Banner section_data prop end
        if (SectionName && (!subDomain || (subDomain && unique_sections.visible)) && allow) {
          temp[index] = (
            <Container
              key={index}
              fluid
              // id={unique_sections.section?.slug ?? "section-" + unique_sections.section.id}
              id={unique_sections?.section?.slug ? `${unique_sections?.section?.slug}_${unique_sections?.id}` : ' '}
              className={`theme-${unique_sections?.section?.slug}-section theme-section-wrapper ${unique_sections.variation?.slug}`}
            >
              <Suspense fallback={<div></div>}>
                <SectionName section_data={unique_sections} slug={unique_sections.section?.slug} />
              </Suspense>
            </Container>
          );
        }
      })
      setSections(temp);
    }

    apiGetUserFont()

  }, [userTheme]);



  useEffect(() => {
    if (customRef?.current)
      customRef.current.scrollIntoView({ behavior: "smooth" });
  }, [custom_section_updated]);



  useLayoutEffect( () => {
    // document.fonts.load("12px MarkPro-Bold").then( () => { setLoading(false) } );
    document.fonts.load("12px MarkPro-Bold");
  }, [])


  return (
    <>

      {/* { !bannerData && <LoadingComponent /> } */}

      {loading ? (
        <>
          <LoadingComponent />
        </>
      ) : (
        <>
          <div
            className={`main-wrapper bg-light ${flashBorders}`}
            style={{ marginBottom: isMobile && !subDomain ? 48 : 0 }}
          >

            <div
              className={`${zoomed
                ? "wrapper freelancer-wrapper "
                : "wrapper freelancer-wrapper zoom-out"
                } ${style_class}`}
            >
              { (subDomain && bannerData) && <JoinAlert />}
              {bannerData && <ThemeHeader section_data={bannerData} />}
              {sections}

              { Setting.footer && <>
              <Footer /> </>}



              { payment_status && <>
                <GlobalModal
                    // header={{title: "Payment"}}
                    size="md"
                    show={paymentModal}
                    // handleClose={() => setPaymentModal(false)}
                    handleClose={closePaymentModal}
                    handleShow={() => setPaymentModal(true)}
                    >
                <PaymentSuccessModal handleClose={closePaymentModal} />
                </GlobalModal>
                </>
              }

            </div>
          </div>

          {isMobile && !subDomain && showBottomTab ? (
            <>
              {!isScrolling && (
                <div className="manage-access-btn">
                  <Button
                    variant="primary"
                    className="rounded-pill primary-color manage-btn-float banner-btn px-3 walk-manage"
                    onClick={() => {
                      dispatch({ type: "BOTTOM_TAB_OPEN", payload: "manage" });
                    }}
                  >
                    Manage Sections
                  </Button>
                </div>
              )}
              <BottomTabMobile />
            </>
          ) : null}

        </>
      )}
    </>
  );
}

export default React.memo(
  connect(mapStateToProps, {
    applyChanges,
    discardChanges,
    fetchDraft,
    fetchUserTheme,
    getSectionVariations,
    updateActivity,
    walkthroughGuide
  })(BasicTheme)
);