import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import BannerProfileImage from "../Block/BannerProfileImage";
import BannerContext from "../Block/BannerContext";
import LoadingComponent from "Components/Loader/LoadingComponent/LoadingComponent";
import styles from "./BannerOne.module.css"
import LikeButton from "Components/LikeButton/LikeButton";

function BannerOne(props) {
  const isMobile = useSelector((state) => state.root.isMobile);
  const invertedBanner = useSelector((state) => state.root.invertedBanner);

  return (
    <>
      <Row
        className={
          invertedBanner
            ? isMobile
              ? "flex-column-reverse"
              : " flex-row-reverse"
            : ""
        }
      >
        <Col md={6} lg={3}>
          <BannerProfileImage />
          <LikeButton />
        </Col>

        <Col md={6} lg={9} className="align-self-center">
          <BannerContext classUserDetail={styles.userDetail} />
        </Col>
      </Row>
    </>
  );
}
export default React.memo(BannerOne);