import React, { useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { colors } from '../../../Constants/theme';

export default function TextareaLabel(props) {
    
    const { groupText } = props
    const dispatch = useDispatch();


    const storeSocialLinkPrefix = ( prefixLink ) => {
      dispatch({
        type: "SET_PROFILE_CHECK_LIST",
        payload: prefixLink,
        key: "socialLinkPrefix"
      })
    }

    useEffect( ()=> {
        storeSocialLinkPrefix( groupText )
    }, [groupText])

    return (
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{props.label}</Form.Label>
            <InputGroup>
            {props?.hasGroup && <InputGroup.Text id="inputGroupPrepend" style={{fontSize: 14, color: colors.primary}}>{props?.groupText}</InputGroup.Text>}
            <Form.Control
                as="textarea"
                ref={props?.ref}
                rows={props.rowLines}
                placeholder={props.placeholder}
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                onBlur={props?.onBlur}
                required={props.require}
                onFocus={props.onFocus}
                isValid={props.isValid}
                isInvalid={props.isInvalid} />
            </InputGroup>
            <Form.Text className="text-muted">
                {props.message}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
                {props.errorMsg}
            </Form.Control.Feedback>
        </Form.Group>
    );
}