const MetaDataConfig = {
    "title":"Linkstar",
    "description": "My Personalized web space - Linkstar",
    "icon":"./favicon.png",
    "og_url":"https://linkst.ar",
    "og_type" : "article",
    "og_title": "Linkstar",
    "og_description": "My Personalized web space - Linkstar",
    "og_image": "./favicon.png",
    "apple_icon": "./favicon.png"
}

export default MetaDataConfig;