import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./bottom-tab-mobile.css";
import { Row, Col, Button } from "react-bootstrap";
import {
  themeSectionReorder,
  toggleSection,
  fetchUserTheme,
  setSectionVariation,
} from "../../Redux/Actions";
import { refetchSectionVariations} from '../../Constants/ApiFunctions';
import { mapStateToProps } from "../../Constants/utils";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
  crossTimesIcon,
  desktopIcon,
} from "../../assets/images/icons/index";
import { notifyError } from "../../Constants/Functions";
import mixpanel from 'mixpanel-browser';

function ManageSections(props) {
  const [sections, setSections] = useState([]);
  const [selected_variations, setSelectedVariations] = useState({});

  // const section_variations = useSelector((state) => state.root.section_variations);
  // const section_variations = useSelector((state)=>state.root.user_theme?.sections);
  const section_variations = useSelector((state)=>state.root.user_menu);
  let mySections = section_variations;


  const dispatch = useDispatch();


  const SortableItem = SortableElement(({ value, index, allowed }) => (
    <>
      <li
        style={{ cursor: "pointer", margin: "4px 12px" }}
        className={`${"btn btn-outline-primary rounded-pill manage-section-button selected-section-button text-start py-1"}`}
      >

        {/* <img className='msg-icon' src={selectedImage} /> */}
        <span className='text-truncate'> {value?.main_title ? value?.main_title : value?.section?.title}</span>{" "}
        <span className='icon-box'>
          {" "}
          <img className='img-fluid icon' src={desktopIcon} />{" "}
        </span>
      </li>
    </>
  ));

  const SortableList = SortableContainer(({ items, allowed }) => {
    return (
      <ul className='list-unstyled d-flex flex-column managed_list'>
        <li
          style={{ cursor: "pointer", margin: "7.5px 12px" }}
          className={`btn btn-outline-primary rounded-pill text-start manage-section-button lock py-1`}
        >
          <span className='text-truncate'>
            {<FontAwesomeIcon className='msg-icon' icon={faLock} />} {"Header"}
          </span>{" "}
          <span className='icon-box'>
            {" "}
            <img className='img-fluid icon' src={desktopIcon} />{" "}
          </span>
        </li>
        {items.map((value, index) => (
          <>
            {value.visible && value.section?.sortable ? (
              <SortableItem
                key={`item-${value}`}
                allowed={allowed}
                index={index}
                value={value}
                // disabled={value.name === allowed ? false : true}
              />
            ) : null}
          </>
        ))}

        <li
          style={{ cursor: "pointer", margin: "7.5px 12px" }}
          className={`btn btn-outline-primary rounded-pill text-start manage-section-button lock py-1`}
        >
          <span className='text-truncate'>
            {<FontAwesomeIcon className='msg-icon' icon={faLock} />} {"Footer"}
          </span>{" "}
          <span className='icon-box'>
            {" "}
            <img className='img-fluid icon' src={desktopIcon} />{" "}
          </span>
        </li>
      </ul>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {

    const mySections = [...sections];
    setSections(arrayMoveImmutable(mySections, oldIndex, newIndex));
    const sectionsToBeSend = arrayMoveImmutable(mySections, oldIndex, newIndex).map((val) => val?.id);
    props?.themeSectionReorder(sectionsToBeSend)
    .then((res) => {
        let user_theme = res?.data?.user_theme;
        let available_sections = res?.data?.available_sections;
        if (user_theme && available_sections) {
          dispatch({ type: "SET_USER_THEME", payload: user_theme });
          dispatch({ type: "SET_SECTION_VARIATIONS", payload: available_sections });
        }
      })
      .catch((err) => {
        console.log("Errrr======>", err);
        notifyError(err.message)
      });
  };


  // useEffect(() => {
  //   if (section_variations && section_variations.length) {
  //     let temp = { ...selected_variations };
  //     section_variations.forEach((s) => {
  //       if (!s.visible) {
  //         temp[s.name] = 0;
  //       } else {
  //         temp[s.name] = s.variation_id;
  //       }
  //     });
  //     setSelectedVariations({ ...temp });
  //   }
  // }, [section_variations]);

  useEffect(() => {
    let newArr = section_variations.filter((val) => (val !== null && val?.visible) && val);
    newArr?.sort((a, b) => {
      return a.order - b.order
    })
    //Sorting
    setSections(newArr);
  }, [section_variations]);

  return (
    <>
      <div
        className='bottom-tab-content-box'
        style={{
          width: "100%",
          backgroundColor: "white",
          position: "fixed",
          bottom: "60px",
          zIndex: 599,
        }}
      >
        <div className='bottom-tab-header'>
          <h3 className='font-weight-bold bottom-tab-title'>Manage Sections</h3>
          <div
            className='icon-section'
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch({ type: "BOTTOM_TAB_CLOSE" });
              props?.setSelected("");
            }}
          >
            <span className='icon-box'>
              <img src={crossTimesIcon} className='img-fluid icon' />
            </span>
          </div>
        </div>

        <div className='para-text manage-caption-section mt-2 px-3'>
          <Row className='mt-2 py-1 d_flex_property'>
            <Col>
              <h6 className="caption-header">
              Reorder your sections by pressing and dragging in position
                {/* <span className='caption-header-text'> Hold and drag</span>  */}
              </h6>
            </Col>
            <Col>
              <div className='right-section text-end'>
                <div className='para-text'>
                  <Button
                    variant='primary'
                    className='rounded-pill primary-color py-1 banner-btn'
                    onClick={() => {
                      props?.setSelected("plus");
                    }}
                  >
                    Add More Sections
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className='my-3 px-3'>
          <div style={{ height: "auto" }}>
            <SortableList
              items={sections}
              onSortEnd={onSortEnd}
              helperClass='sortableHelper'
              lockAxis={"y"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps, {
  themeSectionReorder,
  toggleSection,
  fetchUserTheme,
  setSectionVariation,
})(ManageSections);
