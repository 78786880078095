import store from "../Redux/store";
import { endPoints } from "./utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess, kFormatter, notifyWarning } from "./Functions";
import {getRequest, postRequest} from '../Redux/Actions.js';
import mixpanel from 'mixpanel-browser';

const dispatch = store?.dispatch;
const globalState = store?.getState();
const subDomain = globalState?.root?.subDomain;
// export const submitPackage = (eve, packageId, is_new, dispatch, history) => {
//     eve?.preventDefault();
//     const url = endPoints?.registerPackage;
//     postRequest(url,{ package_id: packageId, domain: window.location.host, is_new: is_new })
//       .then((res) => {
//         if (res?.success) {
//           dispatch({
//             type: "USER_LOGIN",
//             payload: res?.data?.user,
//           });
//           if (res?.data?.user?.user_package?.package) {
//             dispatch({
//               type: "SET_TIER",
//               payload: res?.data?.user?.user_package?.package?.tier,
//             });
//             dispatch({
//               type: "SET_PACKAGE_ALREADY_SELECTED",
//               payload: null,
//             });
//           }
//           localStorage.setItem("user", JSON.stringify(res?.data?.user));
//         } else {
//           notifyError(res?.message);
//         }
//         // history?.push("/register-theme");
//         const to = is_new ? "/register-theme" : "/dashboard";
//         history?.push(to);
//       })
//       .catch((err) => {
//         console.log("Err", err);
//         notifyError(err?.message)
//       });
//   };

export const submitPackage = (eve, packageId, is_new, history)=>{
  eve?.preventDefault();
  const url = endPoints?.changePlan;
  // const url = endPoints?.registerPackage;
  const data =  {package_id: packageId, is_new,domain: window.location.host};
  postRequest(url, data).then((res)=>{
    if (res?.success) {
      localStorage.removeItem("trialDate")
      if (!is_new && res?.data.url &&  res?.data.url != '') {
          localStorage.setItem("order_id", res?.data?.order_id);
          window.open(res?.data?.url, "_blank")
          window.close()
      } else {
          dispatch({
              type: "USER_LOGIN",
              payload: res?.data?.user,
          });
          if (res?.data?.user?.user_package?.package) {
              dispatch({
                  type: "SET_TIER",
                  payload: res?.data?.user?.user_package?.package?.tier,
              });
          }
          localStorage.setItem("user", JSON.stringify(res?.data?.user));
          const to = is_new ? "/register-theme" : "/dashboard";
          history?.push(to);
      }
    } else {
        notifyError(res?.message);
    }
  }).catch((err) => {
        console.log("Err", err);
        notifyError(err?.message)
    });
}

  export const refetchSectionVariations = (fetchUserTheme, setRefetch = false)=>{
    const dispatch = store?.dispatch;
    const auth = store?.getState().auth?.user;
    fetchUserTheme().then(res => {
      if(res.success){
        let sections = res.data?.user_theme?.sections;

        if(sections){
          dispatch({ type: 'SET_USER_THEME_SECTIONS', payload: sections });
        }
        if(setRefetch){
          dispatch({ type: 'SET_FETCH_USER_MENU', payload: false });
        }

      }else{
        console.log(res.message);
      }
    }).catch((err) => {
      return notifyError(err.message)
    });
  }

  export const fetchMenu = ()=>{
    const dispatch = store?.dispatch;
    const subDomain = store?.getState().root?.subDomain;
    const url = subDomain ?  endPoints?.getUserUserMenu : endPoints?.getUserMenu;
    getRequest(url).then(res => {
      if(res.success){
        let menu = res.data?.menu;

        if(menu){
          dispatch({ type: 'SET_USER_MENU', payload: menu ?? [] });
        }

      }else{
        console.log(res.message);
      }
    }).catch((err) => {
      return notifyError(err.message)
    });
  }

  export const fetchUserTheme = ()=>{
    try{
      let url = subDomain ? endPoints?.getUserUserTheme : endPoints?.getUserTheme;
      getRequest(url).then((res) => {
          if(res.status === 401)
          {
            // reject(401);
            notifyError('Unauthorized')
            return;
          }
          if (res.success) {
            let user_theme = res?.data?.user_theme;
            if (user_theme) {
              dispatch({ type: "SET_USER_THEME", payload: user_theme });
              // const section_variations = globalState?.root?.section_variations;
              // section_variations.map(
              //   (item) => (
              //     item["name"] === section_name, (item["visible"] = 0)
              //   )
              // );
            }
          }
          return;
        });
    } catch (error) {
      console.log("Error", error);
    }
  }


  export const getSectionItem = async (id)=>{
    const url = subDomain ?  endPoints?.getUserSectionItem : endPoints?.getSectionItem;
    try{
      const res = await getRequest(url, {id});
      if(res.success){
        let item = res.data?.item;
        if(item){
          return item;
        }
      }else{
        notifyError(res.message);
        return null;
      }
    } catch(err){
      notifyError(err.message);
      return null;
    };
  }



export const resetLikes =  (setLikes)=>{
  getLikes().then(likes=>{
      if(likes?.length){
          setLikes( kFormatter(likes?.length) );
      }
  });
}

export const getLikes = async ()=>{
  let url = endPoints?.getLikes;
  let globalState = store?.getState();
  let username = globalState?.auth?.user?.username || globalState?.root?.subDomain;
  let data = {username : username  };

  try{
    let response = await postRequest(url, data);

    if(response?.success){
      return response?.data?.likes;
    }
    console.log('like err: ', response?.message);
    return false;
  }catch(e){
    console.log('like error: ',e);
  }
  return false;

}

export const sendLike = async (visitor='guest')=>{
  let url = endPoints?.sendLike;
  let globalState = store?.getState();
  let username = globalState?.auth?.user?.username || globalState?.root?.subDomain;
  let data = visitor!='guest' ? {username, visitor  } : {username : username };

  try{
    let response = await postRequest(url, data);

    if(response?.success){
      return response?.data?.likes;
    }
    console.log('like err: ', response?.message);
    return false;
  }catch(e){
    console.log('like error: ',e);
  }
  return false;
}


export const userFromDomain = async (domain, enableMixpanel=true)=>{
  let url = endPoints?.userFromDomain;
  try{
    let response = await postRequest(url, {domain}, enableMixpanel);

    if(response?.success){
      return response?.data;
    }
    return false;
  }catch(e){
    console.log('domain error: ',e);
    return false
  }
  return false;
}



export const createStripeCheckout = async ( data , enableMixpanel=true)=>{
  console.log("data", data)
  let url = endPoints?.createStripeCheckout;
  try{
    let response = await postRequest(url, data, enableMixpanel);

    if(response?.success){
      return response?.data;
    }
    return notifyError(response?.message);
  }catch(e){
    console.log('stripe error: ',e);
    return false
  }
}

export const getUserConfiguraions = async (username=null)=>{
  let url = endPoints?.getUserConfiguraions;
  username = username || store?.getState()?.root?.subDomain;

  try{
    let response = await postRequest(url, {username}, false);

    if(response?.success){
      if(response?.data?.info){
        store?.dispatch({type: 'SET_CONFIGURATIONS', payload: response?.data?.info });
      }
      return response?.data;
    }

    console.log('configurations err: ',response?.message);
    return false;
  }catch(e){
    console.log('configurations err: ',e);
    return false;
  }
}

export const getTier = async (username=null)=>{
  let url = endPoints?.getTier;
  // let username = store?.getState()?.root?.subDomain;
  username = username || store?.getState()?.root?.subDomain;

  try{
    let response = await postRequest(url, {username});
    if(response?.success){
      const isTrial = response?.data?.is_trial
      const tier = response?.data?.tier
      store?.dispatch({type: 'SET_CONIGURATIONS_TIER', key: 'tier', payload:tier });
      store?.dispatch({type: 'SET_CONIGURATIONS_TIER', key: 'is_trial', payload:isTrial });
      return response?.data; 
    }
    console.log('configurations err: ',response?.message);
    return false;
  }catch(e){
    console.log('configurations err: ',e);
    return false
  }
}