export const profileImages = { 
    siteLogo: `<svg width="104" height="24" viewBox="0 0 104 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M91.6336 12.0237C91.1128 11.11 89.9194 10.7913 89.008 11.3013C88.8128 11.4075 84.8853 13.6598 84.7335 18.9932C85.7316 19.2694 86.7731 19.6944 87.8797 20.3319C88.6609 20.7781 89.008 21.6918 88.7477 22.5205C88.7043 22.648 88.6392 22.7967 88.5741 22.9242C88.0533 23.7954 86.925 24.1141 86.0137 23.6466C86.2957 23.8166 86.6212 23.9016 86.9684 23.9016C87.1203 23.9016 87.2722 23.8804 87.4024 23.8591C88.4439 23.6254 89.0948 22.6055 88.8345 21.5855C87.6627 16.6771 90.5486 14.8073 90.8958 14.5948C91.8288 14.0636 92.1543 12.9374 91.6336 12.0237Z" fill="#8CC86D"/>
    <path d="M88.5526 22.9241C88.6394 22.7966 88.6828 22.6692 88.7262 22.5204C88.9866 21.6917 88.6394 20.778 87.8582 20.3318C86.7516 19.6944 85.6884 19.2694 84.712 18.9932C84.6903 20.0343 84.7988 21.1818 85.1025 22.4354C85.2327 22.9666 85.5799 23.3916 86.0139 23.6466C86.9035 24.1141 88.0318 23.8166 88.5526 22.9241Z" fill="#8CC86D"/>
    <path d="M91.7855 13.5538C91.5034 14.3612 90.7006 14.9137 89.7893 14.85C81.5873 14.1488 80.2636 7.98669 80.1986 7.73171C79.9816 6.71178 80.6759 5.7131 81.7174 5.50062C82.759 5.30938 83.7788 5.94683 83.9741 6.96676C84.0609 7.34923 84.9939 10.6215 90.1147 11.0677C91.178 11.1527 91.9591 12.0876 91.8723 13.1288C91.8506 13.2563 91.8289 13.4051 91.7855 13.5538Z" fill="#91D8F6"/>
    <path d="M72.3658 13.49C72.6261 14.2974 73.4507 14.8711 74.362 14.7861C82.564 14.1274 83.931 7.96537 83.9961 7.71039C84.2131 6.69046 83.5404 5.69179 82.4772 5.4793C81.4357 5.26682 80.4159 5.92552 80.1989 6.94545C80.1121 7.32792 79.1791 10.6002 74.0365 11.0252C72.9733 11.1102 72.1705 12.0238 72.279 13.065C72.279 13.2138 72.3007 13.3625 72.3658 13.49Z" fill="#EE3197"/>
    <path d="M77.1829 23.9226C78.0509 23.9226 78.8537 23.3277 79.0707 22.4565C80.9584 14.5733 75.4036 11.4073 75.165 11.2798C74.2319 10.7698 73.0602 11.0885 72.5178 12.0022C71.997 12.9159 72.3225 14.0633 73.2338 14.5733C73.581 14.7858 76.4669 16.6769 75.2952 21.5853C75.0565 22.6052 75.6857 23.6251 76.7273 23.8589C76.8791 23.9014 77.031 23.9226 77.1829 23.9226Z" fill="#F58634"/>
    <path d="M85.0803 22.4583C84.7765 21.1834 84.668 20.036 84.6897 19.0161C79.3736 17.5287 75.9886 20.5885 75.815 20.7372C75.0339 21.4597 75.0122 22.6496 75.7499 23.4145C76.4877 24.1582 77.7028 24.2007 78.4622 23.4995C78.766 23.2233 81.4566 21.0772 85.8831 23.627C85.9048 23.6483 85.9482 23.6483 85.9699 23.6695C85.5576 23.4145 85.2104 22.9896 85.0803 22.4583Z" fill="#F2C94C"/>
    <path d="M1.41227 22.7146C1.13019 22.4384 1 22.1197 1 21.7585V1.38115C1 1.01993 1.13019 0.679952 1.41227 0.42497C1.69435 0.127491 2.01983 0 2.4104 0C2.77927 0 3.10475 0.127491 3.38683 0.403721C3.64721 0.679952 3.7991 0.998679 3.7991 1.3599V21.7372C3.7991 22.0984 3.66891 22.4384 3.38683 22.6934C3.12644 22.9696 2.80097 23.0971 2.4104 23.0971C2.01983 23.1396 1.69435 22.9909 1.41227 22.7146Z" fill="#8B39B2"/>
    <path d="M7.11889 4.61198C6.79341 4.29325 6.61983 3.88953 6.61983 3.37957C6.61983 2.8696 6.79341 2.46588 7.11889 2.14715C7.44437 1.82843 7.85664 1.67969 8.334 1.67969C8.83307 1.67969 9.24534 1.82843 9.57081 2.14715C9.89629 2.46588 10.0699 2.8696 10.0699 3.37957C10.0699 3.88953 9.89629 4.29325 9.57081 4.61198C9.24534 4.93071 8.83307 5.07945 8.334 5.07945C7.85664 5.07945 7.46607 4.93071 7.11889 4.61198ZM7.35757 22.7157C7.09719 22.4395 6.9453 22.1207 6.9453 21.7595V8.47921C6.9453 8.11798 7.07549 7.79926 7.35757 7.52303C7.61795 7.26804 7.94343 7.1193 8.334 7.1193C8.70288 7.1193 9.05005 7.24679 9.31043 7.52303C9.59251 7.77801 9.7227 8.09673 9.7227 8.47921V21.7383C9.7227 22.0995 9.59251 22.4395 9.31043 22.6945C9.02835 22.9707 8.70288 23.0982 8.334 23.0982C7.94343 23.1407 7.61795 22.9919 7.35757 22.7157Z" fill="#8B39B2"/>
    <path d="M24.261 7.7342C25.2374 8.26541 25.9969 9.03036 26.561 10.029C27.1252 11.0277 27.4073 12.2176 27.4073 13.5988V21.7795C27.4073 22.1407 27.2771 22.4594 26.995 22.7356C26.7346 22.9906 26.4091 23.1394 26.0186 23.1394C25.6497 23.1394 25.3242 23.0119 25.0421 22.7356C24.7818 22.4807 24.6299 22.1619 24.6299 21.7795V13.8538C24.6299 12.4939 24.1959 11.4314 23.3497 10.624C22.5034 9.81655 21.4619 9.43408 20.2034 9.43408C18.9232 9.43408 17.86 9.8803 16.992 10.7515C16.1241 11.6227 15.6901 12.7276 15.6901 14.0238V21.7795C15.6901 22.1407 15.5599 22.4594 15.2779 22.7356C14.9958 22.9906 14.6703 23.1394 14.3014 23.1394C13.9326 23.1394 13.6071 23.0119 13.325 22.7356C13.0646 22.4807 12.9127 22.1619 12.9127 21.7795V8.4779C12.9127 8.11667 13.0429 7.79795 13.325 7.52172C13.5854 7.26673 13.9109 7.11799 14.3014 7.11799C14.6703 7.11799 15.0175 7.24549 15.2779 7.52172C15.5599 7.7767 15.6901 8.09543 15.6901 8.4779V9.49783C16.276 8.69038 17.0137 8.05293 17.9251 7.60671C18.8364 7.16049 19.8562 6.92676 20.9628 6.92676C22.1997 6.94801 23.3063 7.20299 24.261 7.7342Z" fill="#8B39B2"/>
    <path d="M43.8541 21.8224C43.8541 22.3112 43.6588 22.6724 43.2899 22.9274C43.0512 23.0761 42.7692 23.1611 42.4871 23.1611C42.0097 23.1611 41.6408 22.9699 41.3805 22.6086L36.1294 15.2142L33.1568 17.764V21.7587C33.1568 22.1199 33.0266 22.4599 32.7445 22.7149C32.4624 22.9911 32.1369 23.1186 31.7681 23.1186C31.3992 23.1186 31.0737 22.9911 30.7916 22.7149C30.5313 22.4387 30.3794 22.1199 30.3794 21.7587V1.38139C30.3794 1.02016 30.5096 0.680188 30.7916 0.425206C31.052 0.148975 31.3775 0.0214844 31.7681 0.0214844C32.1369 0.0214844 32.4841 0.148975 32.7445 0.425206C33.0266 0.701436 33.1568 1.02016 33.1568 1.38139V14.3642L41.1418 7.39471C41.3588 7.18223 41.6625 7.07599 42.0097 7.07599C42.4437 7.07599 42.7692 7.22473 43.0512 7.54345C43.2682 7.77719 43.3767 8.07467 43.3767 8.43589C43.3767 8.86086 43.2248 9.17959 42.8994 9.45582L38.1908 13.493L43.572 21.1C43.7673 21.3125 43.8541 21.5675 43.8541 21.8224Z" fill="#8B39B2"/>
    <path d="M45.8941 22.1199C45.4384 21.8649 45.2214 21.5037 45.2214 21.0362C45.2214 20.7175 45.3516 20.42 45.5903 20.2075C45.829 19.9738 46.1327 19.8676 46.4582 19.8676C46.6535 19.8676 46.8271 19.9101 46.9573 19.9738C48.3026 20.6113 49.6479 20.93 51.0149 20.93C51.9696 20.93 52.7725 20.7175 53.4017 20.3138C54.031 19.9101 54.3564 19.4001 54.3564 18.7626C54.3564 18.1677 54.0744 17.7215 53.4885 17.4452C52.9026 17.1478 52.013 16.829 50.7762 16.5103C49.6262 16.2128 48.6715 15.8941 47.9337 15.6179C47.196 15.3204 46.5667 14.8742 46.0459 14.258C45.5252 13.6418 45.2648 12.8556 45.2648 11.8569C45.2648 10.3907 45.7856 9.22207 46.8271 8.30838C47.8686 7.41594 49.2573 6.96973 50.9498 6.96973C51.796 6.96973 52.6857 7.09722 53.597 7.33095C54.5083 7.56468 55.3329 7.88341 56.0706 8.28713C56.2659 8.39338 56.4395 8.54212 56.548 8.7546C56.6782 8.94584 56.7216 9.15832 56.7216 9.37081C56.7216 9.68953 56.5914 9.98701 56.3527 10.1995C56.114 10.4332 55.8102 10.5395 55.4848 10.5395C55.2895 10.5395 55.1159 10.497 54.9423 10.412C54.2913 10.0933 53.6404 9.83827 52.946 9.64704C52.2734 9.4558 51.6441 9.37081 51.08 9.37081C50.1686 9.37081 49.4092 9.60454 48.845 10.0508C48.2592 10.497 47.9771 11.0707 47.9771 11.7719C47.9771 12.2394 48.129 12.6006 48.4545 12.8981C48.7799 13.1955 49.1705 13.4293 49.6479 13.578C50.1252 13.748 50.7979 13.9392 51.6875 14.1942C52.7942 14.4704 53.7272 14.7679 54.4432 15.0442C55.1593 15.3204 55.7885 15.7666 56.3093 16.3403C56.8301 16.914 57.0687 17.679 57.0687 18.6564C57.0687 20.1225 56.4829 21.27 55.3112 22.1199C54.1395 22.9698 52.7291 23.3736 51.0366 23.3736C49.2356 23.3311 47.4997 22.9273 45.8941 22.1199Z" fill="#8B39B2"/>
    <path d="M70.6302 22.3955C70.4567 22.608 70.2397 22.7568 69.9793 22.8417C69.0029 23.1392 68.0915 23.3092 67.2453 23.3092C65.6396 23.3092 64.3811 22.7992 63.4915 21.8006C62.8839 21.2056 62.4716 20.5257 62.233 19.7395C61.9943 18.9533 61.8858 17.9971 61.8858 16.8922V9.96516H59.5207C59.1735 9.96516 58.8697 9.83767 58.6093 9.60393C58.3706 9.3702 58.2404 9.07272 58.2404 8.7115C58.2404 8.35027 58.3706 8.07404 58.6093 7.81906C58.848 7.58533 59.1518 7.45783 59.5207 7.45783H61.8858V3.61186C61.8858 3.25063 62.016 2.91066 62.2981 2.65567C62.5801 2.37944 62.9056 2.25195 63.2745 2.25195C63.6434 2.25195 63.9905 2.37944 64.2509 2.65567C64.533 2.9319 64.6632 3.25063 64.6632 3.61186V7.47908H68.9378C69.2849 7.47908 69.5887 7.60657 69.8491 7.84031C70.0878 8.07404 70.218 8.37152 70.218 8.73274C70.218 9.09397 70.0878 9.3702 69.8491 9.62518C69.6104 9.85891 69.3066 9.98641 68.9378 9.98641H64.6632V17.2959C64.6632 18.0183 64.7283 18.6133 64.8585 19.0808C64.9887 19.5482 65.2056 19.9307 65.5311 20.2494C65.9868 20.6956 66.5726 20.9081 67.3321 20.9081C67.8746 20.9081 68.5255 20.8019 69.3066 20.5894C69.3717 20.5682 69.4802 20.5469 69.6321 20.5469C69.9576 20.5469 70.2614 20.6532 70.4784 20.8869C70.717 21.1206 70.8255 21.3968 70.8255 21.7581C70.8906 21.9706 70.8038 22.183 70.6302 22.3955Z" fill="#8B39B2"/>
    <path d="M103.113 7.33071C103.395 7.5857 103.525 7.90442 103.525 8.2869C103.525 8.64812 103.395 8.96685 103.113 9.24308C102.831 9.49806 102.505 9.6468 102.136 9.6468C100.574 9.6468 99.3372 10.1143 98.4259 11.028C97.5146 11.9416 97.0589 13.259 97.0589 15.0014V21.8222C97.0589 22.1834 96.9287 22.5021 96.6466 22.7784C96.3645 23.0334 96.0391 23.1821 95.6702 23.1821C95.3013 23.1821 94.9758 23.0546 94.6938 22.7784C94.4334 22.5234 94.2815 22.2047 94.2815 21.8222V8.47813C94.2815 8.11691 94.4117 7.79818 94.6938 7.52195C94.9541 7.26697 95.2796 7.11823 95.6702 7.11823C96.0391 7.11823 96.3862 7.24572 96.6466 7.52195C96.9287 7.77693 97.0589 8.09566 97.0589 8.47813V9.49806C97.6231 8.71187 98.3391 8.09566 99.207 7.64944C100.097 7.20322 101.073 6.96949 102.136 6.94824C102.505 6.94824 102.852 7.07573 103.113 7.33071Z" fill="#8B39B2"/>
    </svg>  
    `,

    leftArrow: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6667 10L3.33337 10" stroke="#5B3F91" stroke-width="2.08333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 16.667L3.33337 10.0003L10 3.33366" stroke="#5B3F91" stroke-width="2.08333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `
}