const initialState = {
  user: null
};

export default function initialStateFunc (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'USER_LOGIN':
      return {
        ...state,
        user: payload
      }
    case "USER_GUIDE":
      return {
        ...state,
        user: { ...state.user, guide: payload }
      }
    case 'SET_USER':
      return {
        ...state,
        user: payload
      }
    case 'SET_GOOGLE_USER':
      return {
        ...state,
        googleUser: payload
      }
    case "UPDATE_AVATAR":
      return {
        ...state,
        user: { ...state.user, avatar_url: payload }
      }
    // case "USER_GUIDE":
    //   return {
    //     ...state,
    //     user:{...state.user,guide:payload}
    //   }
      case "TOGGLE_LINKS_HIDE_STATUS":
      return {
        ...state,
        user:{
          ...state.user,
          hideLink:action.payload
        },
      };
    case 'LOGOUT':
      return {
        ...initialState,
      }
    default: // Do nothing
  }




  return state;
}
