import React, { useEffect, useState } from 'react';
import BasicTheme from './Basic/BasicTheme';
import './theme.css';

// import mixpanel from 'mixpanel-browser';
import { connect, useSelector } from "react-redux";
import { walkthroughGuide } from "Redux/Actions";
import { mapStateToProps } from "Constants/utils";


function Theme(props) {
  const isMobile = useSelector(state => state.root.isMobile);
  // const auth = useSelector(state => state.auth.user);
  // const searchParams = new URLSearchParams(window.location.search)
  const [success, setSuccess] = useState(null)
  // const dispatch = useDispatch()
  const sections = useSelector((state) => state?.root?.user_theme?.sections);

  useEffect(() => {
    // redirect to section //generic
    let hash = window.location?.hash;

    if ( hash && ("onhashchange" in window) ) {
      window.onhashchange = function () {
        hash.scrollIntoView({behavior: "instant", block: "end", inline: "nearest"});
      }
    }

  }, [sections]);


  return (
    <div className="theme-wrapper">
      <div className={`divider-wrapper ${isMobile ? "flex-column" : ""}`}>
        <div id="right" className="column">
          <BasicTheme success={success} />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, {
  walkthroughGuide,
})(Theme);
