import { useState } from "react";
import { endPoints } from "Constants/utils";
import { postRequest } from "Redux/Actions";
import store from "Redux/store";
import { cookies } from "Constants/Functions";
import Setting from "Setting/Setting";

const useApiAddLinkTracking = () => {
  
  const [loadingAddLinkTracking, setLoadingAddLinkTracking] = useState(true);
  const [dataAddLinkTracking, setDataAddLinkTracking] = useState(null);
  
  const apiAddLinkTracking = async (view_url, activity_type) => {
    let url = endPoints?.addLinkTracking;

    const dataPayLoad = {
      link: (view_url && window.location.pathname != '/verify-email' ) ? view_url : cookies.get('visitor_from'),
      outside_link: cookies.get('visitor_platfrom') != 'null' ? cookies.get('visitor_platfrom') : 'direct' ,
      user_id: store.getState().auth.user?.id,
      activity_type: activity_type ? activity_type : 'view'
    }
     
    try {
      let res = await postRequest(url, dataPayLoad);
  
      if(res.success) {
        setLoadingAddLinkTracking(false);
        setDataAddLinkTracking(res);

        if(activity_type=='signup') {
          cookies.remove("visitor_from", { path: '/', domain: Setting?.domain });
          cookies.remove("visitor_platfrom", { path: '/', domain: Setting?.domain });  
        }
        
        return
      };

    } catch(err) {
      console.log(err?.message);
    }
  };

  return { loadingAddLinkTracking, dataAddLinkTracking, apiAddLinkTracking };

};

export default useApiAddLinkTracking;