import { cookies } from "Constants/Functions";
import Setting from "Setting/Setting";

export default function LinkTracking() {

    let hasVisitorFrom = cookies.get('visitor_from');

    if(!hasVisitorFrom) {
      let visitor_from = document.location.href;
      let visitor_platform = document.referrer || null;
          
        cookies.set("visitor_from", visitor_from, { path: '/', domain: Setting?.domain });
        cookies.set("visitor_platfrom", visitor_platform, { path: '/', domain: Setting?.domain });
    }


    return(<></>);
    
}