import { toast } from "react-toastify";
import { toastError, toastSuccess, toastWarn, toastInfo } from "assets/images/toast/index"
import styles from "./Toastify.module.css"

const displayToast = (type, title, desc, setting) => {

  let TYPES = {
    error: toastError,
    success: toastSuccess,
    warn: toastWarn,
    info: toastInfo
  }

  toast(
    <div className={`${type}`}>
      <div className={`${styles.title}`}>
        <span className={`${styles.icon} ${type}`}>
          <img src={TYPES[type] ? TYPES[type] : toastInfo} alt="icon" />
        </span>
        <p className={`${styles.text}`}>{title}</p>
      </div>
      <div className={`${styles.desc}`}>{desc}</div>
    </div>,
    setting
  );

}

export default displayToast;


// Text messages
const Content = {
  successTitle: "Yay! Everything worked.",
  warnTitle: "Oh no, there was a problem!",
  errorTitle: "This can’t be done."
}

export {
  Content
}