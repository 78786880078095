export const baseUrl = process.env.REACT_APP_API_ROOT || 'https://aws.linkst.ar/api';
export const profileBaseUrl = process.env.REACT_APP_PROFILE_API_ROOT || 'https://profile.linkst.ar/api';
export const builderBaseUrl = process.env.REACT_APP_BUILDER_API_ROOT || 'https://builder-api.linkst.ar/api';
export const subscriptionBaseUrl = process.env.REACT_APP_API_SUBSCRIPTION || "https://subscription.linkst.ar/api";

export const facebookAppId = "325819152738685"; //ali_sd619@hotmail.com
export const googleClientId =
  "644913499005-ug7hgb0tju183aoqfdh7h535gk5en1l3.apps.googleusercontent.com"; //info@linkst.ar
export const instagramToken = "IGQVJXU3BGQlB3OFluaWVNVjc0WnJIdi1fYjFhcUpjcEVXdWx3c1lJVUJRVlZAJSjlLWTJGQ3N4Qi1saWU4cGtFc3BzQmkyT0lVVVdON2s3b0l2M1hxYTFwY3pfMDhEb2lwNlp6RDRSN2JWaUpGZAW54dQZDZD";

export const endPoints = {
  login: baseUrl + "/login",
  loginToken: baseUrl + "/user_from_token",
  userFromDomain: baseUrl + "/user_from_domain",
  walkGuide: baseUrl + "/user/flags/guide",
  reset: baseUrl + "/reset",
  loginFB: baseUrl + "/login/facebook",
  loginGoogle: baseUrl + "/login/google",
  register: baseUrl + "/register",//info
  registerUserInfo: baseUrl + "/user/update_info",
  registerSocial: baseUrl + "/register/social-complete",//check this out
  registerAbout: baseUrl + "/register/about",//interest
  resetPassword: baseUrl + "/profile/reset_password",
  resetForgotPass: baseUrl + "/reset",
  unreadMessages: baseUrl + "/dashboard/contact_form/unread",
  deleteSection: baseUrl + "/theme/generic_section/delete_all_items",
  secretValidate: baseUrl + "/password/secret_validate",
  resetForgottedPassword: baseUrl + "/reset_password",
  resendEmail: baseUrl + "/verification/resend_email",
  userEmailVerification: baseUrl + "/verification/verify_email",
  bykeaAccountDetails: baseUrl + "/fetch/user/bykea_account",
  registerPackage: baseUrl + "/update/package",//package
  uploadAvatarImage: baseUrl + "/user/upload_avatar",
  verify: baseUrl + "/register/verify",//verification of availability of email and username
  getInterests: baseUrl + "/fetch/interests",
  getAnalytics: baseUrl + "/fetch/analytics",
  getMonthlyAnalytics: baseUrl + "/fetch/monthly_analytics",
  getAllAnalytics: baseUrl + "/user_analytics/views?q=",
  getClickAnalytics: baseUrl + "/user_analytics/clicks",
  getUserSpecificInterests: baseUrl + "/fetch/user_theme/user_interests",
  getUserSteps: baseUrl + "/fetch/user_theme/user_steps",
  getUserTypes: baseUrl + "/fetch/user_types",
  getPackages: subscriptionBaseUrl + "/fetch/packages",
  getThemes: baseUrl + "/fetch/themes",
  getSectionVariations: baseUrl + "/theme/available_sections", //this will not be called from here
  getUserSectionVariations: profileBaseUrl + "/fetch/user/available_sections", 
  setSectionVariation: baseUrl + "/theme/section/set_variation",
  getColorThemes: baseUrl + "/fetch/color_themes",
  uploadGenericCardThumbnail:
    baseUrl + "/theme/generic_section/update_card_thumbnail",
  setTheme: baseUrl + "/theme/set",//set theme
  setColorTheme: baseUrl + "/color_theme/set",
  createDraft: baseUrl + "/theme/create_draft",
  applyDraft: baseUrl + "/theme/apply_changes",
  discardDraft: baseUrl + "/theme/discard_changes",
  redoAction: baseUrl + "/theme/redo_change",
  undoAction: baseUrl + "/theme/undo_change",
  getUserRoles: baseUrl + "/fetch/roles",
  getUserRoleInterests: baseUrl + "/fetch/rolesInterests",
  getProjectSection: baseUrl + "/fetch/user_theme/projects",
  getEducationSection: baseUrl + "/fetch/user_theme/education",
  getInternshipSection: baseUrl + "/fetch/user_theme/internship",
  getDraft: baseUrl + "/fetch/user_theme/draft_changes",
  getNavSection: baseUrl + "/fetch/user_theme/nav_section",
  getSliderSection: baseUrl + "/fetch/user_theme/banner",
  getPricingSection: baseUrl + "/fetch/user_theme/pricing",
  getExperienceSection: baseUrl + "/fetch/user_theme/experience",
  getServiceSection: baseUrl + "/fetch/user_theme/services",
  getBookSection: baseUrl + "/fetch/user_theme/books",
  getBlogSection: baseUrl + "/fetch/user_theme/blogs",
  getCollabSection: baseUrl + "/fetch/user_theme/collab",
  getCouponSection: baseUrl + "/fetch/user_theme/coupon",
  getVideoSection: baseUrl + "/fetch/user_theme/videos",
  getCustomSection: baseUrl + "/fetch/user_theme/custom",
  getMusicSection: baseUrl + "/fetch/user_theme/music",
  getGallerySection: baseUrl + "/fetch/user_theme/gallery",
  getContactSection: baseUrl + "/fetch/user_theme/contact",
  getFooterSection: baseUrl + "/fetch/user_theme/footer_section",
  sendBykeaNumber: baseUrl + "/bykea_send_number",
  sendBykeaOTP: baseUrl + "/bykea_send_otp",
  addPricingSection: baseUrl + "/add_pricing_section",
  getOrderHistory: baseUrl + "/fetch/user/invoices",
  getUserProfile: baseUrl + "/fetch/user_theme/user_profile",
  setUserDetail: baseUrl + "/user_profile/set",
  getLikes: profileBaseUrl + "/user/profile/get-likes",
  sendLike: profileBaseUrl + "/user/profile/like",
  getUserNavSection: baseUrl + "/fetch/user/nav_section",
  getSectionItem: baseUrl + "/theme/generic_section/get_item",
  getUserSliderSection: builderBaseUrl + "/fetch/user/banner",
  getUserPricingSection: builderBaseUrl + "/fetch/user/pricing",
  getUserServiceSection: builderBaseUrl + "/fetch/user/services",
  getUserBookSection: builderBaseUrl + "/fetch/user/books",
  getUserBlogSection: builderBaseUrl + "/fetch/user/blogs",
  getUserCollabSection: builderBaseUrl + "/fetch/user/collab",
  getUserCouponSection: builderBaseUrl + "/fetch/user/coupon",
  getUserVideoSection: builderBaseUrl + "/fetch/user/videos",
  getUserCustomSection: builderBaseUrl + "/fetch/user/custom",
  getUserProjectSection:  builderBaseUrl + "/fetch/user/projects",
  getUserEducationSection:  builderBaseUrl + "/fetch/user/education",
  getUserExperienceSection: builderBaseUrl + "/fetch/user/experience",
  getUserMusicSection: builderBaseUrl + "/fetch/user/music",
  getUserGallerySection: builderBaseUrl + "/fetch/user/gallery",
  getUserContactSection: builderBaseUrl + "/fetch/user/contact",
  getUserFooterSection: baseUrl + "/fetch/user/footer_section",
  getUserStats: builderBaseUrl + "/fetch/user/highlights",
  getUserSocialAccounts: profileBaseUrl + "/fetch/user/social_links",
  getUserUserTheme: profileBaseUrl + "/fetch/user/user_theme",
  getUserSectionItem: baseUrl + "/fetch/user/get_item",
  addPricingItem: baseUrl + "/theme/pricing/add_item",
  deletePricingItem: baseUrl + "/theme/pricing/delete_item",
  deleteGalleryItem: baseUrl + "/theme/gallery/delete_item",
  // addServiceItem: baseUrl + "/theme/service/add_item",
  updateServiceButton: baseUrl + "/theme/service/update_button",
  toggleServiceButton: baseUrl + "/theme/service/toggle_button",
  addGenericItem: baseUrl + "/theme/generic_section/add_item",
  deleteBookItem: baseUrl + "/theme/book/delete_item",
  updateBookButton: baseUrl + "/theme/book/update_button",
  toggleBookButton: baseUrl + "/theme/book/toggle_button",
  deleteGenericItem: baseUrl + "/theme/generic_section/delete_item",
  updateButtonGenericSection: baseUrl + "/theme/generic_section/update_button",
  toggleButtonGenericSection: baseUrl + "/theme/generic_section/toggle_button",
  uploadBannerImage: baseUrl + "/theme/slider/update_banner",
  invertBanner: baseUrl + "/theme/slider/toggle_mirror",
  bannerFlipX: baseUrl + "/theme/slider/toggle_mirror_horizontally",
  bannerFlipY: baseUrl + "/theme/slider/toggle_mirror_vertically",
  uploadLogoImage: baseUrl + "/theme/navbar/update_logo",
  uploadGalleryImage: baseUrl + "/theme/gallery/upload_image",
  uploadFeaturedGalleryImage: baseUrl + "/theme/gallery/update_thumbnail",
  getUserTheme: baseUrl + "/fetch/user_theme",
  getUserMenu: baseUrl + "/fetch/user_theme/user_menu", //auth
  getUserUserMenu: baseUrl + "/fetch/user/user_menu", //subdomain
  themeSectionReorder: baseUrl + "/theme/reorder/sections",
  setSocialLink: baseUrl + "/social_link/set",
  removeSocialLink: baseUrl + "/social_link/unset",
  getSocialIcons: baseUrl + "/fetch/social_icons",
  getSocialAccounts: baseUrl + "/fetch/user_theme/social_links",
  // deleteStats: baseUrl + "/theme/stat/delete_item",
  getStats: baseUrl + "/fetch/user_theme/highlights",
  setStats: baseUrl + "/stat_items",
  addStats: baseUrl + "/theme/stat/add_item",
  contact_form: baseUrl + "/contact_form",
  contact_company: baseUrl + "/contact_company",
  checkout: baseUrl + "/actions/create_invoice",
  userInvoiceDownload: baseUrl + "/fetch/user/invoice/download",
  manage_theme_data: baseUrl + "/manage_theme_data",
  getSourceOfTraffic: baseUrl + "/user_analytics/source_views",
  fetchFormData: baseUrl + "/dashboard/contact_forms",
  readMessage: baseUrl + "/dashboard/contact_form/read",
  deleteUserMessage: baseUrl + "/dashboard/contact_form/delete",
  getUserThemes: baseUrl + "/dashboard/user_theme",
  walkGuide: baseUrl + "/user/flags/guide",
  addVideoItem: baseUrl + "/theme/video/add_video",
  toggleLinkHideStatus: baseUrl + "/social_link/toggle_hide",
  switchSectionPositions: baseUrl + "/theme/switch_position/sections",
  toggleSectionVisibility: baseUrl + "/theme/toggle_section",
  cloneSection: baseUrl + "/theme/clone_section",
  setMultiVariation: baseUrl + "/theme/set_multi_variation",
  changePlan: baseUrl + "/user/plan/activate",
  getDiscoveryCategory: baseUrl + "/discovery/fetch/category",
  getDiscoveryIndustry: baseUrl + "/discovery/fetch/industry",
  getDiscoveryBudget: baseUrl + "/discovery/fetch/budget",
  getDiscoveryBrief: baseUrl + "/discovery/fetch/project_type",
  setDiscoveryData: baseUrl + "/discovery/create_request",
  getPlanStatus: baseUrl + "/user/plan/updateInfo",
  // Activity api
  updateActivity: baseUrl + "/activity",
  createStripeCheckout: baseUrl + "/actions/create_stripe_invoice",
  getUserConfiguraions: baseUrl + "/user/configurations",


  //drive
  // addGoogleDrive: baseUrl + "/drive/set",
  getGoogleDrive: baseUrl + "/drive/get",

  //portfolio
  getUserPortfolioSection: baseUrl + "/fetch/user/portfolio",
  getPortfolioSection: baseUrl + "/fetch/user_theme/portfolio",

  //instagram
  // getUserInstagramSection: builderBaseUrl + "/theme/section/get_instagram_data",
  getInstagramSection: builderBaseUrl + "/theme/section/get_instagram_data",
  addLinkTracking: baseUrl + "/linktracking/add",

  getUserFont: builderBaseUrl + "/fonts/get_user_font_name",
  getTier: subscriptionBaseUrl + "/user/tier"
};

export const mapStateToProps = (state) => {
  return {
    myState: state,
  };
};
