import React,{useEffect, useState} from 'react';
import { Modal, Button } from "react-bootstrap";
import styles from "./SwitchingCardModal.module.css";
import { alertInfoIcon } from '../../../assets/images/icons';
import { connect, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

export default function SwitchCardConfirmModal(props) {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isMobile = useSelector((state) => state.root.isMobile)
    return (
        <>
            <Modal className={styles.switchCardConfirmModal} contentClassName={styles.modalContent} dialogClassName={styles.modalDialog} show={show} onHide={props?.onCancel} centered>
                <Modal.Header closeButton className={styles.modalHeader} closeVariant="primary" >
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modalBody}>
                            <img className={`img-fluid ${styles.icon}`} src={alertInfoIcon} />
                    <h2 className={styles.modalTitle}>You are switching card styles</h2>
                    <p className={styles.modalText}>Some information may be lost, are you sure you want to switch?</p>
                    <div className={styles.btnBox}>
                        <Button variant="outline-primary" className={`rounded-pill ${styles.btnAction}`} onClick={()=>{props?.onCancel();;mixpanel.track(`Button Pressed - Switch Card Modal - Keep`);}}>
                        Keep Existing Card
                        </Button>

                        <Button variant="primary" className={`rounded-pill ${styles.btnAction}`} onClick={()=>{props?.onConfirm();;mixpanel.track(`Button Pressed - Switch Card Modal - Switch`);}}>
                        Switch Card 
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
