import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import HtmlParser from "react-html-parser";
import { profileImages } from "assets/images/profileImages";

// Redux
import { connect, useSelector } from "react-redux";
import { checkout } from "Redux/Actions";
import { mapStateToProps, } from "Constants/utils";

// 3rd Party
import mixpanel from 'mixpanel-browser';

import { testEmailRegex, testPhoneNumber } from "Constants/Functions";

// Components
import LabelInput from "Components/Form/InputLabel/InputLabel";
import { RoundedButton } from "Components/Buttons/Button";
import LandingHeader from "Components/LandingPageComponents/Header/LandingHeader";
import styles from "./Checkout.module.css"
import { notifyError } from "Constants/Functions";

function Checkout(props) {
    // const isMobile = useSelector((state) => state.root.isMobile);
    const route_data = props.location.state;

    const ICONS = {...profileImages}

    const [order, setOrder] = useState({
        name: "",
        email: "",
        phone: "",
        amount: 0,
        username: null,
        service_title: "",
    });

    const subDomain = useSelector((state) => state.root.subDomain);
    const [emailInValid, setEmailInValid] = useState(false);
    const [nameInValid, setNameInValid] = useState(false);
    const [mobileInValid, setMobileInValid] = useState(false);

    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorMobile, setErrorMobile] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState([""]);

    const validateName = (e) => {
        if (!order.name) {
            setNameInValid(true);
            setErrorName("Please enter your name");
        }
    }

    const validateEmail = (e) => {
        if (!testEmailRegex(order.email)) {
            setEmailInValid(true);
            setErrorEmail("Please enter a correct email");
        }

        if (!order.email) {
            setEmailInValid(true);
            setErrorEmail("Please enter your email");
        }
    }

    const validatePhone = (e) => {
        if (!order.phone) {
            setMobileInValid(true);
            setErrorMobile("Please enter your mobile number");
        }

        if (!testPhoneNumber(order.phone)) {
            setMobileInValid(true);
            setErrorMobile("Please enter a valid mobile number");
        }
    }

    const validate = (e) => {

        if (!order.name) {
            setNameInValid(true);
            setErrorName("Please enter your name");
        }
        if (!testEmailRegex(order.email)) {
            setEmailInValid(true);
            setErrorEmail("Please enter a correct email");
        }

        if (!order.email) {
            setEmailInValid(true);
            setErrorEmail("Please enter your email");
        }

        if (!order.phone) {
            setMobileInValid(true);
            setErrorMobile("Please enter your mobile number");
        }

        if (!testPhoneNumber(order.phone)) {
            setMobileInValid(true);
            setErrorMobile("Please enter a valid mobile number");
        }
        if (!order.name || !testEmailRegex(order.email) || !order.email || !order.phone || !testPhoneNumber(order.phone))
        {
            console.log("return")
            return
        }
    };

    const getAuthData = (e) => {
        setOrder({
            ...order,
            [e?.target?.name]: e?.target?.value,
        });
    };



    const submitData = (e) => {

        if( mobileInValid ) {
            console.log("mobileInValid")
            return
        }

        // e.preventDefault();
        // let qstring = "&details=" + order.name;
        setIsLoading(true);
        //do checkout
        props?.checkout(order).then((res) => {

            if (res.success) {
                if (res.data?.api_response && res.data?.api_response?.subcode === "10001") {
                    window.open(res.data?.api_response.invoice_url, "_self");
                }

                if (res.data?.api_response&& res.data?.api_response?.subcode === "90001" ) {
                    setFormError(res.data?.api_response.data.error)
                }
                if (res.data?.api_response?.failed === true) {
                    notifyError(res.data?.api_response?.message)
                }
            }

            setIsLoading(false);
            mixpanel.track(`Bykea Checkout`);
        })

    };


    useEffect(() => {
        if (subDomain != null && route_data) {
            let amount = route_data.amount;
            amount = amount.replace(',','');
            amount = amount.replace(' ','');
            amount = amount.replace(/[A-Za-z]/g, "");
            if(amount.indexOf('.')!=-1){
                amount = amount.substr(0,amount.indexOf('.'));
            }
            let detail = route_data.detail;
            amount = amount.replace(/<\/?[^>]+(>|$)/g, "");
            // if (amount.indexOf('<br>') === amount.length - 4) {
            //     amount = amount.slice(0, amount.length - 4);
            // }
            amount = parseFloat(amount);
            setOrder({
                ...order,
                username: subDomain,
                amount: amount,
                service_title: detail?.title || detail?.button_title ,
            })
        }
    }, []);



    return (
        <>
            {/* <LandingHeader /> */}
            <Container fluid className={`px-0 ${styles.checkoutSection}`}>

                <Container className='py-1'>

                    <div className={styles.header}>
                        <a className={`${styles.btn}`} href={'/'}>{HtmlParser(ICONS.leftArrow)} <span>Back</span></a>
                        <div className={styles.logo}>
                            <a className={``} href={process.env.REACT_APP_STATIC_SITE_ROOT}>{HtmlParser(ICONS.siteLogo)}</a>
                        </div>
                    </div>

                    <div className={`${styles.contentSec} pt-5`}>
                      <h1 className='main-title'>Checkout</h1>
                      <p className={`${styles.desc}`}>Enter your details to proceed</p>
                    </div>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="mx-auto">
                            <Card className='auth-card mt-3 mb-5 border-0'>
                                <Card.Body>
                                    <LabelInput
                                        label='Full Name'
                                        placeholder='Enter Full Name'
                                        type='text'
                                        value={order.name}
                                        name={"name"}
                                        onBlur={validateName}
                                        onChange={(e) => {
                                            setNameInValid(false);
                                            getAuthData(e);
                                        }}
                                        isInvalid={nameInValid}
                                        errorMsg={errorName}
                                        classNameLabel='font-bold'
                                    />
                                    <LabelInput
                                        label='Email'
                                        placeholder='Enter Email'
                                        type='email'
                                        value={order.email}
                                        name={"email"}
                                        onBlur={validateEmail}
                                        onChange={(e) => {
                                            setEmailInValid(false);
                                            getAuthData(e);
                                        }}
                                        isInvalid={emailInValid}
                                        errorMsg={errorEmail}
                                        classNameLabel='font-bold'
                                    />
                                    <LabelInput
                                        label='Mobile Number'
                                        placeholder='Enter Mobile Number'
                                        type='number'
                                        value={order.phone}
                                        name={"phone"}
                                        isInvalid={mobileInValid}
                                        errorMsg={errorMobile}
                                        onBlur={validatePhone}
                                        onChange={(e) => {
                                            getAuthData(e);
                                            setMobileInValid(false);
                                        }}
                                        classNameLabel='font-bold'
                                    />
                                    <div className={`${styles.amountBox}`}>
                                        <strong className={`${styles.amountBoxStrong}`}><span>Total Amount:</span> <span>PKR {order.amount}</span></strong>
                                    </div>
                                    <p>{formError && formError}</p>


                                    <div className='text-center'>
                                        <RoundedButton
                                            name={"Checkout"}
                                            onClick={() => {
                                                validate(); submitData();
                                            }}
                                            loading={isLoading}
                                            className='btn-min-width font-14'
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            {/* <ToastContainer limit={1} /> */}
        </>
    );
}

export default connect(mapStateToProps, {
    checkout
})(Checkout);
