import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./card-edit-block.css";
import mixpanel from 'mixpanel-browser';
import { connect, useDispatch, useSelector } from "react-redux";
export default function CardEditBlock(props) {
  const { showPenIcon = true } = props;
  const { parentName } = props;

  const dispatch = useDispatch()
  const profileImageUploaded = useSelector(state => state?.root?.profileCheckList?.profileImageUploaded);

  // This function calculate X and Y
  const getPosition = () => {
    dispatch({
      type: "SET_PROFILE_CHECK_LIST",
      payload: document.getElementById(`heroBannerUploadBtn`)?.getBoundingClientRect().left + window.scrollX,
      key: "heroBannerUploadBtnX"
    })
    dispatch({
      type: "SET_PROFILE_CHECK_LIST",
      payload: document.getElementById(`heroBannerUploadBtn`)?.getBoundingClientRect().top + window.scrollY,
      key: "heroBannerUploadBtnY"
    })

  };
  var resizeTimeout;
  if (parentName == 'heroBanner')
    window.onresize = function () {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (profileImageUploaded != true)
          getPosition();
      }, 500);
    };
  if (parentName == 'heroBanner')
    window.onscroll = function () {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (profileImageUploaded != true)
          getPosition();
      }, 500);
    };

  useEffect(() => {
    if (parentName == 'heroBanner')
      getPosition();
  }, [])

  return (
    <>
      <div id={`${parentName}UploadBtn`} className={`card-edit-block ${props.showImageLoader == true && 'disabledEdit'}`} >
        {showPenIcon && (
          <span className="edit-box edit-option" style={{ cursor: "pointer" }}>
            <label htmlFor={props?.pen}>
              <input
                id={props?.pen}
                className="d-none"
                name={"cardPicture"}
                type={"file"}
                accept="image/png, image/jpeg, image/webp"
                onChange={(e) => { props?.onImageChange(e); mixpanel.track(`OnImageChange - ${props?.pen ? props?.pen : 'CardEditBlock'}`); }}
              />
              <FontAwesomeIcon
                icon={faPen}
                className="influencer-edit-banner"
              />
            </label>
          </span>
        )}

        {props?.showDeleteIcon && (
          <span
            className="option-box edit-option"
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="text-danger"
              onClick={() => { props?.onDeleteItem(); mixpanel.track(`Button Pressed - Delete Card - ${props?.pen ? props?.pen : 'CardEditBlock'}`); }}
            />
          </span>
        )}

        {/* <span className="option-box edit-option" style={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faEllipsisH} />
            </span> */}
      </div>
    </>
  );
}
