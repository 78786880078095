import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { connect } from "react-redux"
// import Swal from "sweetalert2"
// import withReactContent from 'sweetalert2-react-content'
// import check from '../assets/images/tickmark.png'
import Lottie from "react-lottie";
import tickAnimate from "assets/images/gif/green-check.json";
import errorAnimate from "assets/images/gif/error-animation.json";
import mixpanel from 'mixpanel-browser';
import {Container} from 'react-bootstrap';
import LandingHeader from "Components/LandingPageComponents/Header/LandingHeader";
import { Link } from "react-router-dom";
import "./BykeaThankyou.css";


const BykeaThankyou = (props) => {
    const searchParams = new URLSearchParams(window.location.search)
    const success = (searchParams.get('invoice_success') === 'true');
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: success ? tickAnimate : errorAnimate,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
         {/* <LandingHeader /> */}
         <Container fluid className='px-0 bykea-thankyou' style={{'padding-top':'4rem'}}>
                <Container className='py-1'>
                    <div className="data-box text-center py-2" >
                        <div className="pb-3">
                            <Lottie options={defaultOptions}
                                height={100}
                                width={100}
                            />
                        </div>
                        <h1 className="title">Payment Info</h1>
                        <h3 className="text">{`Your payment has been ${success ? 'successful' : 'failure'}`}</h3>
                    </div>
                </Container>
                        <Link to={"/"}
                            className='backToHomeModal'>Go Back
                        </Link>
            </Container>
        </>
    )
}

export default connect(null, null)(BykeaThankyou)