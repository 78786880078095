import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import EditText from "../../../EditText/EditText";

import {
    createDraft,
} from "../../../../Constants/Functions";
import {
    fetchSliderSection,
} from "../../../../Redux/Actions";
import { mapStateToProps } from "../../../../Constants/utils";
import BannerSocial from "./BannerSocial"

import styles from "./BannerContext.module.css"
import stylesSocial from './BannerSocial.module.css'



function BannerContext(props) {
    const isMobile = useSelector((state) => state.root.isMobile);
    const subDomain = useSelector((state) => state.root.subDomain);
    const placeholdertitle = subDomain ? "" : "Your Name";
    const placeholderboldtitle = subDomain ? "" : "Quick Bio";

    const [id,setId] = useState(0);
    const [title, setTitle] = useState(placeholdertitle);
    const [boldTitle, setBoldTitle] = useState(placeholderboldtitle);
    const sliderSection = useSelector((state) => state.root.sliderData);
    // const hideLink = useSelector(state=>state?.auth?.user?.hideLink);
    const hideLink = useSelector(state=>state?.root?.configurations?.hide_link);

    const isInfluencer = useSelector((state) => state.root.user_theme?.theme?.slug) == 'influencer';
    const isPro = useSelector((state) => state.root.configurations?.tier) == 1;
    const isStudent = useSelector((state) => state.root.configurations?.tier) == 2;
    const styleList = isInfluencer ? { background: 'white', borderRadius: '50%' } : {};
    const styleBadgeDiv = isInfluencer ? { marginLeft: isMobile ? '44%' : '49%', margin: '15px auto' } : {};



    useEffect(()=>{
        setId(sliderSection?.id);
        setTitle(sliderSection?.title ?? placeholdertitle);
        setBoldTitle(sliderSection?.bold_title ?? placeholderboldtitle);
    },[sliderSection])



    return (
        <>
            <div className={`${props.classUserDetailBox} ${styles.userDetailBox} user-detail-box`}>
                <div className={`${props.classUserDetail} ${styles.userDetail} user-detail`}>

                    <EditText
                        tag={'h2'}
                        text={title}
                        placeholderText={placeholdertitle}
                        className={`${styles.name} name`}
                        characterLimit={50}
                        draftText={(eve) => createDraft(eve, "slider_sections", "title",id)}
                    />

                    <EditText
                        tag={'h2'}
                        text={boldTitle}
                        placeholderText={placeholderboldtitle}
                        className={`${styles.description} description`}
                        characterLimit={220}
                        draftText={(eve) => createDraft(eve, "slider_sections", "bold_title",id)}
                    />
                </div>

                {!hideLink && <BannerSocial />}
            </div>

            {
                isPro && <div className={`${isInfluencer ? stylesSocial.isInfluencerBadge : ''} ${stylesSocial.badgeDiv}`}>
                    <h2 style={styleBadgeDiv} className={stylesSocial.pro_user}>Pro</h2>
                </div>
            }
            {
                isStudent && <div className={`${isInfluencer ? stylesSocial.influ_stu : ''} ${stylesSocial.badgeDiv_stu}`}>
                    <h2 style={styleBadgeDiv} className={`${stylesSocial.pro_user} ${stylesSocial.stu_tag}`}>Student</h2>
                </div>
            }
        </>
    )
}

export default React.memo(
    connect(mapStateToProps, {
        fetchSliderSection,
    })(BannerContext)
);