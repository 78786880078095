import { useEffect } from "react";
import Setting from "./Setting";

const ConsoleLogSetting = ( ) => {
    
    useEffect( ()=> {
        if(!Setting.consoleLog) {
            console.log = function() {} 
            console.warn = function() {} 
            console.error = function() {}
        }

    }, [])
}

export default ConsoleLogSetting;