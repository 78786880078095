import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { colors } from "../../Constants/theme";
import "./button.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mixpanel from 'mixpanel-browser';


function RoundedButton({ className, name, onClick, style, loading, ref }) {

  return (
    <button
      ref={ref}
      className={`btn btn-primary px-3 rounded-pill ${className}`}
      disabled={loading}
      onClick={(e)=>{ onClick(e); mixpanel.track(`Button Pressed - ${name}`); }}
    >
      {loading ? (
        <>
          {name}
          <Spinner
            as='span'
            animation='grow'
            size='sm'
            role='status'
            aria-hidden='true'
            className="ms-2"
          />
        </>
      ) : (
        name
      )}
    </button>
  );
}


function OutlinedAndFilledButton({ outlined, name, onClick, style, loading, icon, className }) {

  return (
    <button className={`btn btn-primary  rounded-pill ${className} `} style={{ backgroundColor: outlined ? 'transparent' : '#5B3F91', color: outlined ? '#5B3F91' : colors.white, ...style }} disabled={loading} onClick={(e)=>{ onClick(e); mixpanel.track(`Button Pressed - ${name}`); }}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: '3px' }} /> {name}
    </button>
  )
}

function Button({ name, onClick, style }) {

  return (
    <button
      className='btn btn-sm'
      onClick={(e)=>{ onClick(e); mixpanel.track(`Button Pressed - ${name}`); }}
      style={{
        backgroundColor: colors.primary,
        color: colors.white,
        padding: "2px 20px",
        ...style,
      }}
    >
      {name}
    </button>
  );
}

function SelectableButtons({ name, onClick, style, isSelected }) {

  return (
    <button
      className={`btn ${isSelected ? "btn-badge-pill" : "btn-badge-pill active"
        }`}
      onClick={(e)=>{ onClick(e); mixpanel.track(`Button Pressed - ${name}`); }}
    >
      {name}
    </button>
  );
}

function SelectableButtonSecondary({ name, onClick, style, isSelected }) {

  return (
    <button
      className={`btn ${isSelected ? "btn-badge-pill-secondary" : "btn-badge-pill-secondary active"
        }`}
      onClick={(e)=>{ onClick(e); mixpanel.track(`Button Pressed - ${name}`); }}
    >
      {name}
    </button>
  );
}

function OutlineButton({ className, name, onClick, style }) {

  return (
    <button
      className={`btn btn-primary px-2 outline-pill ${className}`}
      onClick={(e)=>{ onClick(e); mixpanel.track(`Button Pressed - ${name}`); }}
      style={{
        ...style,
      }}
    >
      {name}
    </button>
  );
}

export {
  RoundedButton, Button, SelectableButtons, OutlinedAndFilledButton, OutlineButton
} 
