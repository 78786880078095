import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// 3rd Party Libs
import MetaTags from 'react-meta-tags';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { MessengerChat } from 'react-messenger-chat-plugin';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import mixpanel, { get_distinct_id } from 'mixpanel-browser';
import HTMLReactParser from "html-react-parser";



// Config
import Setting, { ConsoleLogSetting, GitInformation } from "./Setting/Setting";
import { FirebaseConfig, MetaDataConfig, MixPanelConfig, GoogleConfig, FacebookConfig } from "Config";

// Theme
import {baseUrl} from './Constants/utils';
import "./Styles/base/typography.css";
import "./Styles/App.css";
import Theme from "./Pages/Theme/Theme";
// import Login from "./Pages/Login/Login";
import LoadingComponent from "./Components/Loader/LoadingComponent/LoadingComponent";
import UserCheckout from "./Pages/User/Checkout"
import BykeaThankyou from './Pages/Static/BykeaThankyou';

import UserStripeCheckout from "Pages/User/StripeCheckout";
import {userFromDomain} from 'Constants/ApiFunctions';
import { FontLoad } from "Components/FontLoad/FontLoad";

//Sentry import
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getUserConfiguraions, getTier } from "Constants/ApiFunctions";
import HtmlParser from "react-html-parser";
import { FacebookPixel } from "Config/FacebookPixel";
import { GoogleTagManager } from "Config/GoogleTagManager";
import LinkTracking from "Constants/LinkTracking";
import useApiAddLinkTracking from "hooks/useApiAddLinkTracking";



if(Setting.sentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}




const app = initializeApp(FirebaseConfig);
const analytics = getAnalytics(app);
console.warn = console.error = () => { };







const SwitchRouter = () => {
  
  const root = useSelector((state)=>state?.root?.user_theme);
  const { apiAddLinkTracking } = useApiAddLinkTracking()


  useEffect(()=> {
    apiAddLinkTracking(window.location.href, 'view')
  }, [])


  return(
    <>
      <LinkTracking />
      <FacebookPixel id={ (root?.user_id === 10996) && 756729152584024 } />
      <GoogleTagManager id={ (root?.user_id === 28674) && 'GTM-MSJWHQD' } />
      <ConsoleLogSetting />
      <GitInformation />
      <Switch>
        <Route exact path='/' component={Theme} />
        <Route path="/theme" component={Theme} />
        {/* <Route path="/login" component={Login} /> */}
        <Route path="/user/checkout" component={UserCheckout} />
        <Route path="/user/stripe-checkout" component={UserStripeCheckout} />
        <Route path="/bykea/response" component={BykeaThankyou} />
        <Route exact path='/:source' component={Theme} />
      </Switch>
      { Setting.facebookChat && <> <MessengerChat pageId={FacebookConfig.pageId} /> </> }
    </>
  )
}


const sub_domain = () =>{
  let full = window.location.host;
  //window.location.host is subdomain.domain.com
  let parts = full.split(".");
  let sub = parts[0];

  console.log(full);

  if (
    sub !== "linkstarr" &&
    sub !== "api" &&
    sub !== "app" &&
    sub !== "linkst" &&
    sub !== "www" &&
    sub !== "frontend" &&
    sub !== "api" &&
    sub !== "aws" &&
    sub !== "localhost:3000" &&
    sub !== "192" &&
    sub !== "18" &&
    parts.indexOf("ngrok") === -1 &&
    sub.indexOf('localhost') === -1
  ) {
    return sub;
  }

  if( sub.indexOf('localhost') === 0 || window.hostname !== "linkst.local" ) {
    return Setting.localProfileId
  }

  return "aaa";
}



function Main(props) {
  let dispatch = useDispatch();
  const auth = useSelector((state)=>state.auth.user);
  const root = useSelector((state)=>state?.root?.user_theme);
  const userID = useSelector((state) => state.auth?.user?.id);
  const [metadata, setMetadata] = useState( MetaDataConfig )
  const [loading, setLoading] = useState(true);

  console.log({userID})


  const handleCustomDomain = async (host)=>{
    host = host.replace('www.','');
    host = host.replace(':3009','');
    console.log('domain: ', host);
    let user = await userFromDomain(host, false);
    if(!user){
      window.location = 'https://linkst.ar';
      return;
    }

    let name = user?.fullname;
    let icon = user?.icon || metadata.icon;

    dispatch({ type: "SET_SUBDOMAIN", payload: user?.username });
    getTier(user?.username);
    getUserConfiguraions(user?.username).then( ()=> setLoading(false) );
    setMetadata({
      ...metadata,
      'title': name + " - Linkstar",
      'og_title': name + " - Linkstar",
      'og_image' : icon,
      'fetched' : true,
    })
  }

  // useEffect(() => {
  //   setLoading(false);
  // }, []);


  useEffect(() => {

    let host  = window.location.host;
    if( !(host.includes("linkst.ar") || host.includes("sav-vy.com") || host.includes("linkst.local") ) ){
      handleCustomDomain(host);
      mixpanel.init(`${process.env.REACT_APP_MIXPANEL_KEY}`, {ignore_dnt: true});
      mixpanel.alias(userID, get_distinct_id);
      mixpanel.identify(userID)
      mixpanel.track('Page View - Profile - User Profile Viewed');
      return;
    }

    let sub = sub_domain();

    if ( sub ) {
      dispatch({ type: "SET_SUBDOMAIN", payload: sub });
      getTier(sub);
      getUserConfiguraions(sub).then( ()=> setLoading(false) );
      // set_meta(sub);
    }

    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_KEY}`, {ignore_dnt: true});
    mixpanel.alias(userID, get_distinct_id);
    mixpanel.identify(userID)
    mixpanel.track('Page View - Profile - User Profile Viewed');

  }, []);





  useEffect(()=>{
    if(auth && auth.username) {
      // set_meta(auth.username);
    }
  },[auth])

  // console.log(root?.title)

  useEffect(() => {

    if(root !== null){
      // console.log('theme load')
      // console.log("title", root?.sections[0]?.title)
      let isLinkstarDomain = window.location.hostname.includes('linkst.ar');
      let getTitle = root?.sections?.find((sec) => (sec?.section_id===1) ? sec?.title : null );
          getTitle = getTitle?.title?.replace(/<[^>]*>?/gm, '')

      let metaTitle = ( !getTitle && isLinkstarDomain ) ? "Linkstar" : (getTitle && isLinkstarDomain ) ? getTitle + " - Linkstar" : getTitle

      setMetadata({
        ...metadata,
        'title': metaTitle ,
        'og_title': metaTitle,
        // 'og_image' : icon,
        'fetched' : true,
      })
    }

  }, [root])







  // const set_meta = (sub) => {
  //   // fetch(baseUrl + "/get_fullname",
  //   //   {
  //   //     method: 'POST',
  //   //     mode: "cors",
  //   //     headers: { "Content-Type": "application/json" },
  //   //     body: JSON.stringify({ username: sub })
  //   //   }).then((res) => {
  //   //     return res.json();
  //   //   }).then((body) => {
  //   //     if (body.success) {
  //   //       let name = body.data?.fullname;
  //   //       let icon = metadata.icon;
  //   //       if(body.data?.icon){
  //   //         icon = body.data?.icon;
  //   //       }
  //   //       setMetadata({
  //   //         ...metadata,
  //   //         'title': name + " - Linkstar",
  //   //         'og_title': name + " - Linkstar",
  //   //         'og_image' : icon,
  //   //         'fetched' : true,
  //   //       })
  //   //     } else {
  //   //       window.location.href = "https://linkst.ar";
  //   //     }

  //   //     FontLoad( ['MarkPro','MarkPro-Medium'], ()=>setLoading(false) )
  //   //     // setLoading(false);
  //   //   })
  // }



  return (
    <div className='App' data-app="Profile" data-version="4.10.22">
      <GoogleReCaptchaProvider
        reCaptchaKey={GoogleConfig.reCaptchaId}
        useEnterprise={false}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined // optional, default undefined
        }}
      >
      <MetaTags>
        <title>{metadata.title}</title>
        <link rel="icon" type="image/png" href={metadata.icon} />
        <meta name="description" content={metadata.description} />
        <meta property="og:url" content={metadata.og_url} />
        <meta property="og:type" content={metadata.og_type} />
        <meta property="og:title" content={metadata.og_title} />
        <meta property="og:description" content={metadata.og_description} />
        <meta property="og:image" content={metadata.og_image} />
        <link rel="apple-touch-icon" href={metadata.apple_icon} />
      </MetaTags>

      { loading ? (
        <>
         {/* <LoadingComponent /> */}
        </>
      ) : (
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <SwitchRouter/>
          </Suspense>
        </Router>
      )}
      <ToastContainer
       limit={5}
       />
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default Main;