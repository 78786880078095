import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Modal, Spinner } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "../../Modal";
import ImageCropper from "../../ImageCropper/ImageCropper";
import mixpanel from 'mixpanel-browser';


export default function CropperModal(props){
    const [croppedImage, setCroppedImage] = useState(undefined);
    const [showCropButton, setShowCropButton] = useState(true);
    const { modalOpen, setModalOpen, imageToCrop, aspectRatio, uploadApi } = props;

    const dispatch = useDispatch();

    const uploadImageHandler = ( section ) => {
      console.log("uploadImageHandler... image uploaded", props?.section);
      if ( section !== undefined ) {
        dispatch({
            type: "SET_PROFILE_CHECK_LIST",
            payload: true,
            key: "sectionEdited" 
        });
      }
    }


    return (
        <CustomModal backdrop={"static"} show={modalOpen} onHide={() => { setModalOpen(false); setCroppedImage(undefined) }}>
          <Modal.Header closeButton>
            <Modal.Title className='mobile-cropper-title'>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column justify-content-center align-items-center mobile-cropper-body ">
            <div>
              {imageToCrop ? (<ImageCropper
                aspectRatio={aspectRatio}
                imageToCrop={imageToCrop}
                onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                circular={props.circular}
                setShowCropButton={setShowCropButton}
              />) : (null)}
            </div>

          </Modal.Body>
          <Modal.Footer className="mobile-cropper-modal">
            {croppedImage && (
              <>
                <Button disabled={!showCropButton} variant="primary" onClick={(e)=>{uploadApi(e, croppedImage); mixpanel.track(`Button Pressed - ${props?.section} - UploadGenericCardThumbnail`); uploadImageHandler(props?.section || 'card' /* to avid undefined TEMP */ ) }} className="px-3">
                  {showCropButton ? 'Crop' : (<Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />) }
                  
                </Button>
              </>
            )}
          </Modal.Footer>
        </CustomModal>
    );
}
