import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clear_placeholder, validate_text } from "../../Constants/Functions";
import CharacterUiLimit from "./CharacterUiLimit";
import ReactHtmlParser from "react-html-parser";
import mixpanel from 'mixpanel-browser';

// styles
import styles from "./EditText.module.css";

function EditText(props) {
  //Props recieved
  const { tag, text, placeholderText, className, characterLimit, draftText } =
    props;
  const isMobile = useSelector((state) => state.root.isMobile);
  //Local states
  const [textEditable, setTextEditable] = useState();
  const [textLength, setTextLength] = useState();

  //Global states
  const subDomain = useSelector((state) => state.root.subDomain);

  const dispatch = useDispatch();
  //Creating Custom Tag From tag Prop.
  const CustomTag = `${tag}`;

  //Run on First time and Every time when the value of props.text will be change.
  useEffect(() => {
    setTextEditable(text);
    setTextLength(text?.length);
  }, [text]);


  //Only numbers will be allowed on key press
  const onlyNumbers = (event) => {
    if (event.keyCode != 8 && !/\d|-/.test(event.key)) {
      event.preventDefault();
    }
  };
  //Only numbers will be allowed on key press
  const onlyPricing = (event) => {
    var key = event ? event.keyCode : event.which;

    console.log(event.keyCode);
    if (event.keyCode == 8 || event.keyCode == 46
      || event.keyCode == 37 || event.keyCode == 39 || (event.keyCode >= 96 && event.keyCode <= 105)) {
      return true;
    }
    else if (key < 48 || key > 57) {
      event.preventDefault();
    }
  };
  const onTyping = (e) => {
    if (props?.numberOnly) {
      onlyNumbers(e)
    }
    if (props?.pricingOnly) {
      onlyPricing(e)
    }
    setTimeout(() => {
      if (e.target?.innerText?.length >= 0) {
        setTextLength(
          e.target?.innerText?.length > characterLimit
            ? characterLimit
            : e.target?.innerText?.length
        );
      }
      validate_text(e, characterLimit);
    }, 200);
  }


  //Return JSX
  return (
    <div className={styles.editableContentBox}>
      <CustomTag
        suppressContentEditableWarning={true}
        className={className}
        data-placeholder={placeholderText}
        onClick={(e) => {
          if (!subDomain) {
            placeholderText === text && clear_placeholder(e);
            dispatch({ type: "HIDE_BOTTOM_TAB" });
            setTimeout(() => {
              if (e.target?.innerText?.length >= 0) {
                setTextLength(
                  e.target?.innerText?.length > characterLimit
                    ? characterLimit
                    : e.target?.innerText?.length
                );
              }
            }, 200);
          }
        }}
        contentEditable={!subDomain}
        onKeyUp={isMobile ? onTyping : () => { }}
        onKeyDown={isMobile ? () => { } : onTyping}
        // onKeyPress={isMobile?()=>{}:onTyping}
        onPaste={(e) => {
          setTimeout(() => {
            validate_text(e, characterLimit);
          }, 10);
        }}
        onBlur={(e) => {
          // if (e.target?.innerText && className !== "list-text") {
          dispatch({ type: "SHOW_BOTTOM_TAB" });
          // setTextEditable(e.target.innerText);
          // }
          draftText(e);
          if (props.onBlur) {
            props.onBlur(e);
          }
        }}
      >
        {ReactHtmlParser(textEditable)}
      </CustomTag>
      {characterLimit && (
        <>
          {/* <CharacterUiLimit count={textLength} range={characterLimit} /> */}
          <div className={styles.characterLimit}>
            <p className={styles.limitText}>
              Character Limit: {textLength}/{characterLimit}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default EditText;
