import TagManager from 'react-gtm-module'

export const GoogleTagManager = ({ id }) => {

    if(!id) {
        console.log('c% not GTM', 'font-size: 24px; color: orange')
        return
    }
    
    const tagManagerArgs = {
        gtmId: id // 'GTM-000000'
    }
    
    TagManager.initialize(tagManagerArgs)

    return (<></>)
}