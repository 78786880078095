import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faLinkedin, faTwitter, faDribbble } from "@fortawesome/free-brands-svg-icons"
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import "../Block/bannerSocial.css"

export default function SocialElementDemo(props) {
    const subDomain = useSelector((state) => state.root.subDomain);
    const dispatch = useDispatch();
    const arr = props?.mySocialAccounts

    const openLinkToolBar = ()=>{
        dispatch({
            type:'SET_TOOLBAR_SELECTED',
            payload:'link'
        });
        dispatch({ type: "OPEN_SIDEBAR"});
    }



    return (
        <>
            {!subDomain ?
                arr?.length<1 ? (<ul className={`list-inline social-links demo-social-links`} >
                    <li className={`list-inline-item mx-2 my-2 social-links-list`} onClick={openLinkToolBar}>
                        <span className="me-2 social-icon">
                            <FontAwesomeIcon
                                icon={faInstagram}
                                className="icon"
                            />
                        </span>
                    </li>

                    <li className={`list-inline-item mx-2 my-2 social-links-list`} onClick={openLinkToolBar}>
                        <span className="me-2 social-icon">
                            <FontAwesomeIcon
                                icon={faTiktok}
                                className="icon"
                            />
                        </span>
                    </li>

                    <li className={`list-inline-item mx-2 my-2 social-links-list`} onClick={openLinkToolBar}>
                        <span className="me-2 social-icon">
                            <FontAwesomeIcon
                                icon={faLinkedin}
                                className="icon"
                            />
                        </span>
                    </li>

                    <li className={`list-inline-item mx-2 my-2 social-links-list`} onClick={openLinkToolBar}>
                        <span className="me-2 social-icon">
                            <FontAwesomeIcon
                                icon={faDribbble}
                                className="icon"
                            />
                        </span>
                    </li>

                    <li className={`list-inline-item mx-2 my-2 social-links-list`} onClick={openLinkToolBar}>
                        <span className="me-2 social-icon">
                            <FontAwesomeIcon
                                icon={faPlus}
                                className="icon"
                                title={"add social icon"}
                            />
                        </span>
                    </li>
                </ul >
                ) : (
                    <ul className={`list-inline social-links demo-social-links`} >
                        <li className={`list-inline-item mx-2 my-2 social-links-list`} onClick={openLinkToolBar}>
                            <span className="me-2 social-icon">
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className="icon"
                                    title={"add social icon"}
                                />
                            </span>
                        </li>
                    </ul>
                )
            : null
            }
        </>
    )
}