import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {useSelector } from "react-redux";


function CustomModal(props) {
  const isMobile = useSelector((state) => state.root.isMobile);

  return (
    <Modal
      {...props}
      size={props.size}
      backdrop={props?.backdrop}
      aria-labelledby="contained-modal-title-center"
      centered={!isMobile}
      dialogClassName={props.className}
    >
      {props?.children}      
    </Modal>
  );
}


export default CustomModal;