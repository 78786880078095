import ReactPixel from 'react-facebook-pixel';

export const FacebookPixel = ({ id, email }) => {
    const advancedMatching = { em: email };
    const options = { autoConfig: true, debug: false, };
            // '641047893613562' // Linkstar Pixel
            if(id) {
                ReactPixel.init( id , advancedMatching, options);
                ReactPixel.pageView();
            }
    return (<></>)
}