import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { logoPurple } from "../../../assets/images";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import "./landingHeader.css";
import styles from "./LandingHeader.module.css";
import mixpanel from 'mixpanel-browser';

function LandingHeader(props) {

  const location = useLocation();

  const [path, setPath] = useState("");
  useEffect(() => {
    setPath(location.pathname);
  }, [])

  return (
    <>
      <Navbar expand='lg' className={"main-navbar"}>
        <Container>
          <Link to={`${process.env.REACT_APP_STATIC_SITE_ROOT}`}>
            <img className={`img-fluid landing-logo ${styles.logo}`} src={logoPurple} />
          </Link>
          <Navbar.Brand href='#home'>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto' >
              <a className={`nav-link ${styles.navLink}`} href={`${process.env.REACT_APP_STATIC_SITE_ROOT}/discover`}>For Businesses</a>
              <a className={`nav-link ${styles.navLink}`} href={`${process.env.REACT_APP_STATIC_SITE_ROOT}/how-it-works`}>How it Works</a>
              {/* <Nav.Link className={`${styles.navLink}`} id="UserDiscoveryBtn" href='/#UserDiscovery'>For Businesses</Nav.Link> */}
              <a className={`nav-link ${styles.navLink}`} href={`${process.env.REACT_APP_STATIC_SITE_ROOT}/pricing`}>Pricing</a>
            </Nav>
            <Nav>
              <Nav.Item>
                <a href={`${process.env.REACT_APP_STATIC_SITE_ROOT}/auth/login`} className='mx-2'>
                  <Button onClick={()=>{ mixpanel.track(`Button Pressed - Landing Page - Sign in`); }} variant='primary' className={`rounded-pill landing-login-btn ${styles.navBtn}`} >
                    Sign In
                  </Button>
                </a>
              </Nav.Item>

              <Nav.Item>
                <a href={`${process.env.REACT_APP_STATIC_SITE_ROOT}/auth/register`} className=''>
                  <Button onClick={()=>{ mixpanel.track(`Button Pressed - Landing Page - Sign Up`); }} variant='primary' className={`rounded-pill landing-register-btn ${styles.navBtn} ${styles.navSignUp}`}>
                    Create New Account
                  </Button>
                </a>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default LandingHeader;
