import React from 'react';
import { Form } from 'react-bootstrap';
import './Checkbox.css'
export default function FormCheck(props) {

    return (
        <Form.Check className={`form-check ${props.className}`}>
            <Form.Check.Input type="checkbox" onChange={props.onChange} />
            <Form.Check.Label className={props.classNameLabel} isValid={props.isValid} isInvalid={props.isInvalid}>
                {props.label}
            </Form.Check.Label>
            <Form.Control.Feedback type={props.validText}>
                {props.feedback}
            </Form.Control.Feedback>
        </Form.Check>
    
    )
}