import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import './ImageCropper.css'

function ImageCropper(props) {
  let { imageToCrop, onImageCropped, aspectRatio, setShowCropButton } = props;
  props?.section=='collab'?aspectRatio=1/1:aspectRatio=props?.aspectRatio
  const imgRef = useRef(null);
  const [imgToCrop,setImgToCrop] = useState(imageToCrop);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: aspectRatio });
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(()=>{
    setImgToCrop(props.imageToCrop);
  },[props.imageToCrop])
  
  const onLoad = useCallback((img) => {
    imgRef.current = img;

  }, []);

  useEffect(() => {
    if (completedCrop && previewCanvasRef.current && imgRef.current) {

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          // returning an error
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          blob.name = 'croppedImage.webp';
          // creating a Object URL representing the Blob object given
          const croppedImageUrl = window.URL.createObjectURL(blob);

          resolve(blob);
          onImageCropped(blob)//sending cropped image to parent 
          setShowCropButton(true);
        },
        "image/webp",
        image.naturalHeight <=360 ? 1 : 0.8
      );
    });
    }
  }, [completedCrop]);

  return (
    <div className={props?.circular? "custom-cropper" : "" }>{/*add condition to cropper's css*/}
      <ReactCrop
        src={imgToCrop}
        onImageLoaded={onLoad}
        ruleOfThirds
        imageStyle={{
          objectFit: "contain",
        }}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => { setShowCropButton(false); setCompletedCrop(c); }}
        crossorigin="anonymous" // to avoid CORS-related problems
        circularCrop={props?.circular ? true : false}
      />
      <canvas className="d-none" style={{ display:'none' }} //preview canvas  (calling this is important so that the final image is rendered properly and sent to parent)
        ref={previewCanvasRef}
        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
        // style={{
        //   width: Math.round(completedCrop?.width ?? 0),
        //   height: Math.round(completedCrop?.height ?? 0)
        // }}
      />
    </div>
  );
}

// ImageCropper.defaultProps = {
//   onImageCropped: () => { },
// };

export default ImageCropper;
