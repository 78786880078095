import { endPoints } from "../Constants/utils";
import store from "./store";
import mixpanel from 'mixpanel-browser';
// import axios from 'axios';

export const loginUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - loginUser`);
    const { email, password } = data;
    try {
      fetch(endPoints?.login, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const resetUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - resetUser`);
    const { email } = data;
    try {
      fetch(endPoints?.reset, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const resetForgotPass = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - resetForgotPass`);
    const { email } = data;
    try {
      fetch(endPoints?.resetForgotPass, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const secretValidate = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - secretValidate`);
    try {
      fetch(endPoints?.secretValidate, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const resetForgottedPassword = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - resetForgottedPassword`);
    try {
      fetch(endPoints?.resetForgottedPassword, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const loginGoogle = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - loginGoogle`);
    const { access_token, email, name } = data;
    try {
      fetch(endPoints?.loginGoogle, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          access_token: access_token,
          name: name,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const loginFacebook = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - loginFacebook`);
    const { access_token, email, name } = data;
    try {
      fetch(endPoints?.loginFB, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          access_token: access_token,
          name: name,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const registerUserInfo = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - registerUserInfo`);
    const { firstName, lastName, mobile, age, location } = data;
    var token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.registerUserInfo, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          mobile: mobile,
          dob: age,
          location: location,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });


export const getOrderHistory = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getOrderHistory`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getOrderHistory, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getBykeaDetails = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getBykeaDetails`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.bykeaAccountDetails, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getUnreadMessages = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUnreadMessages`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.unreadMessages, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchEducationSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchEducationSection`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getEducationSection, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchInternshipSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchInternshipSection`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getInternshipSection, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchProjectSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchProjectSection`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getProjectSection, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const walkthroughGuide = () => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - walkthroughGuide`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.walkGuide, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getUserProfile = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserProfile`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getUserProfile, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getUserAnalytics = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserAnalytics`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getAnalytics, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getUserRoleInterests = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserRoleInterests`);
    const token = store?.getState()?.auth?.user?.token;
    const {slug} = data
    try {
      fetch(endPoints?.getUserRoleInterests, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({role:slug}),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const sendBykeaNumber = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - sendBykeaNumber`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.sendBykeaNumber, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const sendBykeaOTP = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - sendBykeaOTP`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.sendBykeaOTP, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const toggleSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - deleteSection`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.deleteSection, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const deleteSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - deleteSection`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.deleteSection, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const getUserRoles = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserRoles`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getUserRoles, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const setUserDetail = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - setUserDetail`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.setUserDetail, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

  export const getUserSpecificInterests = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserSpecificInterests`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getUserSpecificInterests, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const registerUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - registerUser`);
    const { email, password1, domain, mobile, age, location } = data;
    try {
      fetch(endPoints?.register, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password1,
          username: domain,
          mobile: mobile,
          dob: age,
          location: location,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const registerNewUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - registerNewUser`);
    const { email, password, username, mobile, dob, location, name,firstName,lastName,role} = data;

    try {
      fetch(endPoints?.register, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          username: username,
          name:name,
          first_name:firstName,
          last_name:lastName,
          mobile: mobile,
          dob: dob,
          location: location,
          role:role,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const SocialregisterUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - SocialregisterUser`);
    const { firstName, lastName, domain, mobile, age, location } = data;
    try {
      var token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.registerSocial, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          username: domain,
          mobile: mobile,
          dob: age,
          location: location,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const registerAbout = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - registerAbout`);
    const { user_type, interests } = data;
    try {
      var token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.registerAbout, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          user_type: user_type,
          interests: interests,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });


export const passwordReset = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - passwordReset`);
    try {
      var token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.resetPassword, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const emailVerification = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - emailVerification`);
    try {
      var token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.userEmailVerification, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const resendEmail = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - resendEmail`);
    try {
      var token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.resendEmail, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const verifyUserEmail = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - verifyUserEmail`);
    try {
      var token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.userEmailVerification, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const verifyUsername = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - verifyUsername`);
    try {
      fetch(endPoints?.verify, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ username: data }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const verifyEmail = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - verifyEmail`);
    try {
      fetch(endPoints?.verify, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email: data }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getUserTypes = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserTypes`);
    try {
      fetch(endPoints?.getUserTypes, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getUserInterests = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserInterests`);
    // const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getInterests, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchUserTheme = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchUserTheme`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;

      fetch(subDomain ? endPoints?.getUserUserTheme : endPoints?.getUserTheme, {
        priority:"high",
        method: "GET",
        // mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Subdomain": subDomain,
        },
      }).then((res) => {
        if(res.status === 401)
        {
          reject(401);
          return;
        }
        res.json().then((data) => {
          dispatch({ type: "SET_DRAFTS", payload: data?.data?.drafts });
          resolve(data);
        });
        return;
      });
    } catch (error) {
      console.log("Error", error);
      reject(error);
    }
  });

export const fetchDraft = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchDraft`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.getDraft, {
        priority:'low',
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          dispatch({ type: "SET_DRAFTS", payload: data?.data?.drafts });
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const uploadGalleryImage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - uploadGalleryImage`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.uploadGalleryImage, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          boundary: 974767299852498929531610575,
          Authorization: "Bearer " + token,
        },
        body: data,
      }).then((res) => {
        res.json().then((data) => {
          console.log("UPLOADED");

          dispatch({
            type: "SET_PROFILE_CHECK_LIST",
            payload: true,
            key: "sectionEdited"
          })

          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const uploadFeaturedGalleryImage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - uploadFeaturedGalleryImage`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.uploadFeaturedGalleryImage, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          boundary: 974767299852498929531610575,
          Authorization: "Bearer " + token,
        },
        body: data,
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

// upload generic card thumbnail

export const uploadGenericCardThumbnail = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - uploadGenericCardThumbnail`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.uploadGenericCardThumbnail, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          boundary: 974767299852498929531610575,
          Authorization: "Bearer " + token,
        },
        body: data,
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const uploadLogoImage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - uploadLogoImage`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.uploadLogoImage, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: data,
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const bannerFlipX = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - bannerFlipX`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.bannerFlipX, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const bannerFlipY = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - bannerFlipY`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.bannerFlipY, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const invertBanner = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - invertBanner`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.invertBanner, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const uploadAvatarImage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - uploadAvatarImage`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.uploadAvatarImage, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          boundary: 974767299852498929531610575,
          Authorization: "Bearer " + token,
        },
        body: data,
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const uploadBannerImage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - uploadBannerImage`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.uploadBannerImage, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          boundary: 974767299852498929531610575,
          Authorization: "Bearer " + token,
        },
        body: data,
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });


export const fetchNavSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchNavSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;

      fetch(
        subDomain ? endPoints?.getUserNavSection : endPoints?.getNavSection,
        {
          priority:'low',
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Subdomain": subDomain,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchSliderSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchSliderSection`);
    try {
      dispatch({ type: "LOADING_TRUE" });
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;

      fetch(
        subDomain
          ? endPoints?.getUserSliderSection
          : endPoints?.getSliderSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const getMonthlyAnalytics = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getMonthlyAnalytics`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getMonthlyAnalytics, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

  export const getAllAnalytics = (data) => (dispatch) =>

  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getAllAnalytics`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(`${endPoints?.getAllAnalytics}${data}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });


  export const getClickAnalytics = (data) => (dispatch) =>

  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getClickAnalytics`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getClickAnalytics, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
// export const addServiceItem = (data) => (dispatch) =>
//   new Promise((resolve, reject) =>
    // // mixpanel.track(`API hit - addServiceItem`);
//     try {
//       const token = store?.getState()?.auth?.user?.token;
//       fetch(endPoints?.addServiceItem, {
//         method: "POST",
//         mode: "cors",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: "Bearer " + token,
//         },
//       }).then((res) => {
//         res.json().then((data) => {
//           resolve(data);
//         });
//         return;
//       });
//     } catch (error) {
//       reject(error);
//       console.log("Error", error);
//     }
//   });

export const addGenericItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - addGenericItem`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.addGenericItem, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const updateButtonGenericItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - updateButtonGenericItem`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.updateButtonGenericSection, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const deleteGenericItem =
  (data, dispatch = () => {}, fetchUserTheme = () => {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - deleteGenericItem`);
      try {
        const token = store?.getState()?.auth?.user?.token;
        fetch(endPoints?.deleteGenericItem, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(data),
        }).then((res) => {
          res.json().then(async (data) => {
            const size = data?.data?.items?.length;
            const section_name = data?.data?.parent_section?.table_title;

            if (!size) {
              fetchUserTheme()?.then((res) => {
                if (res.success) {
                  let user_theme = res?.data?.user_theme;
                  if (user_theme) {
                    dispatch({ type: "SET_USER_THEME", payload: user_theme });

                    const section_variations =
                      store?.getState()?.root?.section_variations;

                    section_variations.map(
                      (item) => (
                        item["name"] === section_name, (item["visible"] = 0)
                      )
                    );
                  }
                }
              });
            }
            resolve(data);
          });
          return;
        });
      } catch (error) {
        reject(error);
        console.log("Error", error);
      }
    });

export const toggleButtonGenericItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - toggleButtonGenericItem`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.toggleButtonGenericSection, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const updateServiceButton = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - updateServiceButton`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.updateServiceButton, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
          // dispatch({ type: 'SERVICES_SECTION_UPDATE' });
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getDiscoveryCategory = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getDiscoveryCategory`);
    try {
      fetch(endPoints?.getDiscoveryCategory, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getDiscoveryIndustry = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getDiscoveryIndustry`);
    try {
      fetch(endPoints?.getDiscoveryIndustry, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getDiscoveryBudget = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getDiscoveryBudget`);
    try {
      fetch(endPoints?.getDiscoveryBudget, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getDiscoveryBrief = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getDiscoveryBrief`);
    try {
      fetch(endPoints?.getDiscoveryBrief, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const setDiscoveryData = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - setDiscoveryData`);
    try {
      fetch(endPoints?.setDiscoveryData, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const updateBookButton = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - updateBookButton`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.updateBookButton, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
          // dispatch({ type: 'SERVICES_SECTION_UPDATE' });
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const toggleServiceButton = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - toggleServiceButton`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.toggleServiceButton, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
          // dispatch({ type: 'SERVICES_SECTION_UPDATE' });
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const toggleBookButton = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - toggleBookButton`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.toggleBookButton, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
          // dispatch({ type: 'SERVICES_SECTION_UPDATE' });
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const deleteGalleryItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - deleteGalleryItem`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.deleteGalleryItem, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
          if (data?.success) {
            dispatch({ type: "GALLERY_SECTION_UPDATE" });
          }
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const deleteBookItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - deleteBookItem`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.deleteBookItem, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchServiceSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchServiceSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserServiceSection
          : endPoints?.getServiceSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchCustomSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchCustomSection`);
    try {
      const id = data?.id;
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserCustomSection+`?id=${id}`
          : endPoints?.getCustomSection+`?id=${id}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchVideoSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchVideoSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain ? endPoints?.getUserVideoSection : endPoints?.getVideoSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchBookSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchBookSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain ? endPoints?.getUserBookSection : endPoints?.getBookSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchBlogSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchBlogSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain ? endPoints?.getUserBlogSection : endPoints?.getBlogSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchCollabSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchCollabSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserCollabSection
          : endPoints?.getCollabSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchCouponSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchCouponSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserCouponSection
          : endPoints?.getCouponSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchExperienceSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchExperienceSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserExperienceSection
          : endPoints?.getExperienceSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const fetchMusicSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchMusicSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain ? endPoints?.getUserMusicSection : endPoints?.getMusicSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchContactSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchContactSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserContactSection
          : endPoints?.getContactSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchGallerySection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchGallerySection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserGallerySection
          : endPoints?.getGallerySection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchFooterSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchFooterSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserFooterSection
          : endPoints?.getFooterSection,
        {
          priority:'low',
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const addPricingItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - addPricingItem`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.addPricingItem, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const deletePricingItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - deletePricingItem`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.deletePricingItem, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const fetchPricingSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - fetchPricingSection`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain
          ? endPoints?.getUserPricingSection
          : endPoints?.getPricingSection,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        dispatch({ type: "LOADING_FALSE" });
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      dispatch({ type: "LOADING_FALSE" });
      reject(error);
      console.log("Error", error);
    }
  });

export const applyChanges = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - applyChanges`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.applyDraft, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          store?.dispatch({ type: "SET_DRAFTS", payload: null });
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const undoAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - undoAction`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.undoAction, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          store?.dispatch({ type: "SET_DRAFTS", payload: null });
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const redoAction = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - redoAction`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.redoAction, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          store?.dispatch({ type: "SET_DRAFTS", payload: null });
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const discardChanges = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - discardChanges`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.discardDraft, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          store?.dispatch({ type: "SET_DRAFTS", payload: null });
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getPackages = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getPackages`);
    try {
      fetch(endPoints?.getPackages, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getColorThemes = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getColorThemes`);
    try {
      fetch(endPoints?.getColorThemes, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const setSectionVariation = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - setSectionVariation`);
    const { id, section_name } = data;
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.setSectionVariation, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ id: id, section: section_name }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
// export const setServiceVariation = (data) => (dispatch) =>
//   new Promise((resolve, reject) =>
    // // mixpanel.track(`API hit - setServiceVariation`);
//     const {id} = data;
//     const token = store?.getState()?.auth?.user?.token;
//     try {
//       fetch(endPoints?.setServiceVariation, {
//         method: "POST",
//         mode: "cors",
//         headers: {
//           "Content-Type": "application/json",
//           'Accept': 'application/json',
//           'Authorization': 'Bearer ' + token
//         },
//         body:JSON.stringify({id})
//       }).then((res) => {
//         res.json().then((data) => {
//           resolve(data);
//         });
//         return;
//       });
//     } catch (error) {
//       reject(error);
//       console.log("Error", error);
//     }
//   })
export const getSectionVariations = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getSectionVariations`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      fetch(
        subDomain ?
        endPoints?.getUserSectionVariations:
        endPoints?.getSectionVariations, {
        priority:"high",
        method: "GET",
        mode: "cors",
        headers: {
          "Subdomain": subDomain,
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getThemes = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getThemes`);
    try {
      const token = store?.getState()?.auth?.user?.token;
      fetch(endPoints?.getThemes, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });


export const setTheme = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - setTheme`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.setTheme, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const themeSectionReorder = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - themeSectionReorder`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.themeSectionReorder, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          order: data,
        }),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const checkout = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - checkout`);
    const token = store?.getState()?.auth?.user?.token;
    console.log("data", data);
    try {
      fetch(endPoints?.checkout, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const contact_form = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - contact_form`);
    const token = store?.getState()?.auth?.user?.token;
    console.log("data", data);
    try {
      fetch(endPoints?.contact_form, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const switchSectionPositions = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - switchSectionPositions`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.switchSectionPositions, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
});
export const cloneSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - cloneSection`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.cloneSection, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
});
export const toggleSectionVisibility = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - toggleSectionVisibility`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.toggleSectionVisibility, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
});

export const contact_company = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - contact_company`);
    console.log("data", data);
    try {
      fetch(endPoints?.contact_company, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const setColorTheme = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - setColorTheme`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.setColorTheme, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getSocialLinks = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getSocialLinks`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.getSocialIcons, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const registerPackage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - registerPackage`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.registerPackage, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const changePlan = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - changePlan`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.changePlan, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const setSocialLinks = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - setSocialLinks`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.setSocialLink, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const removeSocialLinks = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - removeSocialLinks`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.removeSocialLink, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getUserSocialAccounts = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserSocialAccounts`);
    const token = store?.getState()?.auth?.user?.token;
    const subDomain = store?.getState()?.root?.subDomain;
    try {
      fetch(
        subDomain
          ? endPoints?.getUserSocialAccounts
          : endPoints?.getSocialAccounts,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Subdomain": subDomain,
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const VisitLink = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - VisitLink`);
    try {
      fetch(data, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        resolve(res?.status);
        res.json().then((data) => {});
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const addVideoItem = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - addVideoItem`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.addVideoItem, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

// export const deleteStats = (data) => (dispatch) =>
//   new Promise((resolve, reject) =>
//   // mixpanel.track(`API hit - deleteStats`);
//     const token = store?.getState()?.auth?.user?.token;
//     try {
//       fetch(endPoints?.deleteStats, {
//         method: "POST",
//         mode: "cors",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: "Bearer " + token,
//         },
//         body: JSON.stringify(data),
//       }).then((res) => {
//         res.json().then((data) => {
//           resolve(data);
//         });
//         return;
//       });
//     } catch (error) {
//       reject(error);
//       console.log("Error", error);
//     }
//   });

export const getStats = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getStats`);
    const token = store?.getState()?.auth?.user?.token;
    const subDomain = store?.getState()?.root?.subDomain;
    try {
      fetch(subDomain ? endPoints?.getUserStats : endPoints?.getStats, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
          "Subdomain": subDomain,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const addStats = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - addStats`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.addStats, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const setStats = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - setStats`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.addStats, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getUsersFormData = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUsersFormData`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints.fetchFormData, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const readMessage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - readMessage`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.readMessage, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const deleteUserMessage = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - deleteUserMessage`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints?.deleteUserMessage, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

export const getUserThemes = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserThemes`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints.getUserThemes, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
export const getPlanStatus = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getPlanStatus`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints.getPlanStatus, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

  // Activity APi
  export const updateActivity = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - updateActivity`);
    try {
      fetch(endPoints?.updateActivity, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

  export const unreadMessages = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - unreadMessages`);
    const token = store?.getState()?.auth?.user?.token;

    try {
      fetch(endPoints?.unreadMessages, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const userInvoiceDownload = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - unreadMessages`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      // axios({
      //     url: endPoints?.userInvoiceDownload + '/' + data.toString(),
      //     method: 'GET',
      //     responseType: 'blob', // important
      //       headers: {
      //         Authorization: "Bearer " + token,
      //       },
      // }).then((response) => {
      //     console.log(response.data.url)
      //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //     const link = document.createElement('a');
      //     link.href = url;
      //     link.setAttribute('download', 'Invoice.pdf'); //or any other extension
      //     document.body.appendChild(link);
      //     link.click();
      // });
      fetch(endPoints?.userInvoiceDownload + '/' + data.toString(), {
        method: "GET",
        mode: "cors",
        responseType: 'blob', // important
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then((response) => {
        response.blob()
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
              new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `FileName.pdf`,
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            return;
          });
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

  export const addPricingSection = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - addPricingSection`);
    const token = store?.getState()?.auth?.user?.token;

    try {
      fetch(endPoints?.addPricingSection, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const getSourceOfTraffic = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - sourceOfTraffic`);
    const token = store?.getState()?.auth?.user?.token;

    try {
      fetch(endPoints?.getSourceOfTraffic, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const toggleLinkHideStatus = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - toggleLinkHideStatus`);
    const token = store?.getState()?.auth?.user?.token;

    try {
      fetch(endPoints?.toggleLinkHideStatus, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

  export const getUserSteps = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - getUserSteps`);
    const token = store?.getState()?.auth?.user?.token;
    try {
      fetch(endPoints.getUserSteps, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });
  export const setMultiVariation = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    // mixpanel.track(`API hit - toggleLinkHideStatus`);
    const token = store?.getState()?.auth?.user?.token;

    try {
      fetch(endPoints?.setMultiVariation, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body:JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          resolve(data);
        });
        return;
      });
    } catch (error) {
      reject(error);
      console.log("Error", error);
    }
  });

  export const getRequest = (url, data=null)=>{
    return new Promise((resolve, reject) => {
      // mixpanel.track(`API hit - ${url}`);
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;
      let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      let q = '';
      if(subDomain){
        headers['Subdomain'] = subDomain;
      }else{
        headers['Authorization'] = "Bearer " + token;
      }
      if(data){
        let arr = []
        Object.keys(data).forEach((key)=>{
          arr.push(`${key}=${data[key]}`);
        })
        q = arr.join('&');
      }


      try {
        fetch(`${url}?${q}`, {
          method: "GET",
          mode: "cors",
          headers,
        }).then((res) => {
          res.json().then((data) => {
            resolve(data);
          });
          return;
        });
      } catch (error) {
        reject(error);
        console.log("Error", error);
      }
    });
  }


  export const postRequest = (url, data=null, enableMixpanel=true) =>{
    return new Promise((resolve, reject) => {
      if(enableMixpanel){
        // mixpanel.track(`API hit - ${url}`);
      }
      const token = store?.getState()?.auth?.user?.token;
      const subDomain = store?.getState()?.root?.subDomain;

      let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      if(token) headers['Authorization'] = "Bearer " + token;
      if(subDomain) headers['Subdomain'] = subDomain;

      try {
        fetch(url, {
          method: "POST",
          mode: "cors",
          headers,
          body:JSON.stringify(data),
        }).then((res) => {
          res.json().then((data) => {
            resolve(data);
          });
          return;
        });
      } catch (error) {
        reject(error);
        console.log("Error", error);
      }
    });
}